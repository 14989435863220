body.modal-opened {
  overflow: hidden;
  .modal {
    transform: scaleY(1); } }

.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $black;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 0 100%;
  transform: scaleY(0);
  overflow: hidden;

  .modal-title {
    font-weight: 100;
    span {
      font-weight: 900; } }

  .el-btn {
    width: 360px;
    max-width: 360px;
    @media (max-width: 991px) {
      width: 300px;
      max-width: 300px; }
    @media (max-width: 440px) {
      width: 290px;
      max-width: 290px; } }

  .modal_text_p {
    max-width: 330px;
    font-family: $got;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: white;
    margin-bottom: 40px; }
  .container {
    max-width: 990px;
    width: 100%;
    @media (max-width: 1060px) {
      max-width: 100%;
      padding: 0 50px; }
    @media (max-width: $screen-xs-max) {
      padding: 0 15px; } }
  .mod-title-section.mod-marg {
    margin-bottom: 30px;
    @media (max-width: $screen-sm-max) {
      margin-bottom: 30px; }
    @media (max-width: $screen-xs-max) {
      margin-block: 20px; } }
  .modal-content {
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
    padding: 110px 0 118px;
    display: flex;
    justify-content: center;
    color: white;
    @media (max-height: 680px) {
      display: block;
      padding: 60px 0 40px;
      overflow-y: auto;
      overflow-x: hidden; }
    @media (max-width: $screen-xs-max) {
      padding: 60px 0 40px;
      display: block;
      overflow-y: auto;
      overflow-x: hidden; }
    .close {
      top: 50px;
      right: 50px;
      @media (max-width: $screen-xs-max) {
        top: 22px;
        right: 20px; } }

    .btn-wrap {
      margin-top: 40px;
      // width: 100%
      @media (max-width: $screen-xs-max) {
        width: 290px;
        text-align: center;
        margin: 40px auto;
        display: block; } } }
  .copyright {
    position: absolute;
    @media (max-width: $screen-sm-max) {
      display: none; } }

  .close {
    top: 15px;
    .icon {
      color: white;
      transition: color 0.2s; }
    &:hover {
      .icon {
        color: $sunshine-yellow; } } }

  h2,
  .h2 {
    color: white; }
  .el-bg-text {
    color: #181818; }

  form {
    @extend %clear-fix;
    width: 100%;
    @media (max-width: $screen-sm-max) {
      margin-left: auto;
      margin-right: auto; }

    .el-textarea {
      height: 130px;
      @media (max-width: $screen-xs-max) {
        display: none; } }

    .el-text-i {
      width: 47%;
      display: inline-block; }

    .el-file-input .label {
      max-width: 190px;
      display: block; }

    .el-text-in {
      float:right !important {} }

    .col-left {
      float: left;
      width: 100%;
      max-width: 48%;
      @media (max-width: $screen-xs-max) {
        max-width: 100%; } }

    .col-right {
      float: right;
      width: 100%;
      max-width: 45%;
      opacity: 1;
      border-left: solid 1px rgba(255,255,255,0.2);
      padding-left: 65px;
      margin-top: 10px;
      padding-bottom: 50px;
      @media (max-width: 950px) {
        padding-left: 7%; }
      @media (max-width: 840px) {
        padding-left: 0;
        border-left: 0; }
      @media (max-width: $screen-xs-max) {
        max-width: 100%;
        display: none; } } }

  .success-message {
    opacity: 0;
    display: none;
    .el-bg-text {
      bottom: auto;
      top: 70px; } } }

.modal-list {
  @media(max-width: 736px) and (max-height: 414px) {
    display: flex;
    flex-wrap: wrap; }

  @media (max-width: $screen-xs-max) {
    padding: 20px 0; }
  li {
    font-size: 16px;
    line-height: 1.56;
    padding: 12px 0;
    display: flex;
    align-items: center;
    @media(max-width: 736px) and (max-height: 414px) {
      width: 48%; }
    span {
      padding: 0 20px; } } }

.file_download {
  padding-left: 60px;
  text-decoration: none;
  position: relative;
  display: block;
  margin: 20px 0 0;
  transition: 0.5s ease;
  @media (max-width: $screen-sm-max) {
    padding-left: 20px; }

  &:hover {
    text-decoration: none;
    transform: translateY(5px);
    opacity: 0.5; }
  span {
    padding: 0 20px; } }
