.mod-careers {
  .mod-title-section {
    max-width: 790px;
    width: 100%;
    margin-bottom: 30px;
    @media (max-width: $screen-sm-max) {
      max-width: 680px; } }

  .h3 {
    margin-bottom: 20px; }

  .list-vacancies {
    width: 100%;
    max-width: 100%;
    padding-right: 30px;
    li {
      width: 100%; }

    .content {
      display: none;
      position: relative;
      width: 100%;
      padding-left: 135px;
      padding-bottom: 50px;
      @media (max-width: $screen-xs-max) {
        padding-left: 55px; }

      .content-wrap {
        opacity: 0; }

      .content-text-column {
        column-count: 2;
        font-family: $got;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.6;
        letter-spacing: 0.2px;
        text-align: left;
        color: $black;
        column-gap: 50px;
        @media (max-width: $screen-xs-max) {
          column-count: 1;
          column-gap: 0;
          font-size: 12px;
          line-height: 1.5; } }

      .el-link {
        position: absolute;
        right: 0;
        bottom: -8px; } } } }

