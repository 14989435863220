// pages
//-----------------------------------------------------------------------------------
.h1, h1 {
  font-family: $got;
  font-size: 80px;
  font-weight: bold;
  line-height: 1.12;
  letter-spacing: -2px;
  text-align: left;
  color: $black;
  @media (max-width: $screen-sm-max) {
    font-size: 60px; }
  @media (max-width: $screen-xs-max) {
    font-size: 40px; } }

.h2, h2 {
  font-family: $got;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.50;
  text-align: left;
  color: $black;
  @media (max-width: $screen-sm-max) {
    font-size: 26px; }
  @media (max-width: $screen-xs-max) {
    font-size: 18px; } }

.h3, h3 {
  font-family: $got;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: $black;
  text-transform: uppercase;
  @media (max-width: $screen-sm-max) {
    font-size: 16px; }
  @media (max-width: $screen-xs-max) {
    font-size: 12px; } }

.home-text-bg {
  position: absolute;
  top: 50%;
  // transform: translate(-50%, -50%)
  margin-left: -370px;
  margin-top: -100px;
  left: 50%;
  font-family: $got;
  font-size: 200px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 4px;
  text-align: left;
  color: $text-bg;
  z-index: -1;
  pointer-events: none;

  @media (max-width: $screen-xs-max) {
    left: 50%;
    font-size: 110px;
    margin-left: 0;
    margin-top: -30px;
    transform: translate(-50%, -50%); } }

.page-body.mod-contact, .page-body.mod-expertise {
  @media (max-width: $screen-xs-max) {
    display: flex; } }
.page-body.mod-home-first {
  @media (max-width: $screen-xs-max) {
    display: flex; }
  .container-center {
    @media (max-width: $screen-xs-max) {
 } } }      //margin-top: -30px

.mod-home-first {
  .h1, h1 {
    margin-bottom: 20px;
    @media (max-width: $screen-sm-max) {
      margin-bottom: 10px; } }
  .h3, h3 {
    margin-bottom: 30px;
    @media (max-width: $screen-sm-max) {
      font-size: 15px;
      max-width: 440px; }
    @media (max-width: $screen-xs-max) {
      margin-bottom: 10px; } }

  .btn-wrap.js-anim-btn-who {
    margin-left: 10px;
    @media (max-width: 500px) {
      margin-left: 0; } }

  .btn-wrap.js-anim-btn-who {
    @media (max-width: 500px) {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
 } }      // display: block

  .text-mob-center {
    @media (max-width: $screen-xs-max) {
      text-align: center; } }
  .container-center {
    @media (max-width: $screen-xs-max) {
      text-align: center; } }
  .text-home {
    display: inline-block;
    width: 100%;
    max-width: 600px;
    font-family: $got;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: 0.2px;
    text-align: left;
    color: $black;
    margin-bottom: 40px;
    @media (max-width: $screen-sm-max) {
      max-width: 500px; }
    @media (max-width: 910px) {
      max-width: 440px; }
    @media (max-width: $screen-xs-max) {
      font-size: 12px;
      line-height: 1.6;
      margin-bottom: 30px; } }

  .el-right-menu__list {
    @extend %clear-fix;
    margin: -90px 0 0 0;
    @media (max-width: $screen-xs-max) {
      opacity: 1;
      top: auto;
      bottom: 20px;
      margin: 0 -60px 0 0;
      right: 50%; }
    .is-active .el-right-menu__link {
      @media (max-width: $screen-xs-max) {
        transform: translateX(0px);
        background: $black; } }
    .el-right-menu__line {
      @media (max-width: $screen-xs-max) {
        display: none; } }
    .el-right-menu__item {
      @media (max-width: $screen-xs-max) {
        display: inline-block;
        font-size: 0;
        float: left;
        padding: 5px; } }
    .el-right-menu__link {
      @media (max-width: $screen-xs-max) {
        display: inline-block;
        font-size: 0;
        border-radius: 100%;
        padding: 0;
        width: 10px;
        height: 10px;
        box-sizing: border-box;
        background: rgba(0,0,0,0.2); } } } }

.mod-expertise, .mod-policy {
  .container-center {
    @media (max-width: $screen-xs-max) {
      padding-top: 25px; }

    @media (max-width: $screen-xs-max) and (orientation: landscape) {
      padding-top: 40px; } }

  .el-right-menu__list {
    @extend %clear-fix;
    margin: -90px 0 0 0;
    @media (max-width: $screen-xs-max) {
      right: 15px; }
    @media (max-width: 640px) {
      opacity: 1;
      top: auto;
      bottom: 20px;
      margin: 0 -50px 0 0;
      right: 50%; }
    .is-active .el-right-menu__link {
      @media (max-width: 640px) {
        transform: translateX(0px);
        background: $black; } }
    .el-right-menu__line {
      @media (max-width: 640px) {
        display: none; } }
    .el-right-menu__item {
      @media (max-width: 640px) {
        display: inline-block;
        font-size: 0;
        float: left;
        padding: 5px; } }
    .el-right-menu__link {
      @media (max-width: 640px) {
        display: inline-block;
        font-size: 0;
        border-radius: 100%;
        padding: 0;
        width: 10px;
        height: 10px;
        box-sizing: border-box;
        background: rgba(0,0,0,0.2); } } }

  .morph-animation {
    position: fixed;
    z-index: -1;
    width: 2033px;
    height: 217px;
    pointer-events: none;
    left: 50%;
    top: 50%;
    margin-left: -245px;
    margin-top: -290px;
    @media (max-width: $screen-sm-max) {
      margin-top: -220px;
      height: 140px; }
    @media (max-width: $screen-xs-max) {
      height: 90px;
      margin-top: -190px; }
    @media (max-width: 400px) {
      height: 60px;
      margin-left: -100px;
      margin-top: -180px; }
    @media (max-width: $screen-xs-max) and (orientation: landscape) {
      margin-top: -110px; }
    svg {
      @media (max-width: $screen-sm-max) {
        height: 140px; }
      @media (max-width: $screen-xs-max) {
        height: 90px; }
      @media (max-width: 400px) {
        height: 60px; } } }

  .rectangle-yellow-wrap {
    position: absolute;
    width: 400px;
    height: 400px;
    left: 50%;
    margin-left: -200px;
    top: 0;
    z-index: 0;
    @media (max-width: $screen-sm-max) {
      width: 350px;
      height: 350px;
      margin-left: -175px; }
    @media (max-width: 415px) {
      width: 290px;
      height: 290px;
      margin-left: -145px; }

    .block-revealer__content {
      width: 100%;
      height: 100%; } }

  .rectangle-yellow {
    display: inline-block;
    pointer-events: none;
    width: 100%;
    height: 100%;
    background-color: $sunshine-yellow;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); }

  .group-lines {
    position: absolute;
    pointer-events: none;
    left: 50%;
    margin-left: -245px;
    top: -45px;
    @media (max-width: 540px) {
      display: none; }

    .block-revealer__content {
      width: 100%;
      height: 100%; }

    .line-1,
    .line-2,
    .line-3 {
      display: inline-block;
      position: absolute;
      .line-cont {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: $sunshine-yellow;
        @media (max-width: $screen-xs-max) {
          background-color: $black;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); } } }

    .line-1 {
      width: 10px;
      height: 230px;
      top: -15px;
      left: 0;
      @media (max-width: $screen-xs-max) {
        height: 120px;
        width: 4px;
        top: 100px;
        left: 20px; } }

    .line-2 {
      top: -100px;
      width: 1px;
      height: 125px;
      left: 22px;
      @media (max-width: $screen-xs-max) {
        top: 271px;
        left: 425px; } }

    .line-3 {
      top: -65px;
      width: 2px;
      height: 180px;
      left: 30px;
      @media (max-width: $screen-xs-max) {
        top: 85px; } }

    .line-4 {
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: -15px;
      width: 60px;
      height: 120px;
      left: 250px;
      @media (max-width: $screen-xs-max) {
        width: 3px;
        height: 120px;
        left: 410px;
        top: 305px; }

      .line-cont {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: $black;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); } } }

  .item-expertise {
    display: inline-block;
    width: 400px;
    min-height: 400px;
    text-align: left;
    @media (max-width: $screen-xs-max) {
      text-align: center;
      width: 100%;
      max-width: 350px;
      min-height: 350px; }
    @media (max-width: 540px) {
      min-height: 300px; }

    &.mod-design {
      .title-expertise {
        margin-left: -220px;
        @media (max-width: $screen-sm-max) {
          margin-left: -225px; }
        @media (max-width: $screen-xs-max) {
          margin-left: 0;
          width: 100%; } }
      .skill-expertise {
        @media (max-width: $screen-sm-max) {
          margin-left: 80px; }
        @media (max-width: $screen-xs-max) {
          margin-left: 0; } } }
    &.mod-frontend {
      display: none;
      .title-expertise {
        margin-left: -220px;
        @media (max-width: $screen-sm-max) {
          margin-left: -140px; }
        @media (max-width: $screen-xs-max) {
          margin-left: 0;
          width: 100%; } } }
    &.mod-backend {
      display: none;
      .title-expertise {
        margin-left: -200px;
        @media (max-width: $screen-sm-max) {
          margin-left: -140px; }
        @media (max-width: $screen-xs-max) {
          margin-left: 0;
          width: 100%; } }
      .text-expertise {
        max-width: 390px;
        @media (max-width: $screen-xs-max) {
          max-width: 300px; } } }
    &.mod-cms {
      display: none;
      .title-expertise {
        margin-left: -120px;
        @media (max-width: $screen-sm-max) {
          margin-left: -125px; }
        @media (max-width: $screen-xs-max) {
          margin-left: 0;
          width: 100%; } } }
    &.mod-mobile {
      display: none;
      .title-expertise {
        margin-left: -160px;
        @media (max-width: $screen-sm-max) {
          margin-left: -145px; }
        @media (max-width: $screen-xs-max) {
          margin-left: 0;
          width: 100%; } } } }

  .title-expertise {
    position: relative;
    z-index: 2;
    text-align: center;
    display: inline-block;
    margin-top: 70px;
    margin-left: -75px;
    @media (max-width: $screen-xs-max) {
      margin-top: -25px;
      margin-left: 0; }
    .name-expertise,
    .skill-expertise {
      text-align: center; } }

  .name-expertise {
    font-family: $got;
    font-size: 100px;
    font-weight: bold;
    line-height: 0.8;
    letter-spacing: 2px;
    color: $black;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
    text-transform: uppercase;
    margin-bottom: 20px;
    white-space: nowrap;
    @media (max-width: $screen-sm-max) {
      font-size: 80px; }
    @media (max-width: $screen-xs-max) {
      font-size: 50px;
      width: 100%; }
    @media (max-width: 400px) {
      font-size: 40px; }
    span {
      padding-top: 10px; } }
  .skill-expertise {
    font-family: $got;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: $black;
    margin-bottom: 30px;
    text-transform: uppercase;

    white-space: nowrap;
    @media (max-width: $screen-sm-max) {
      font-size: 16px; }
    @media (max-width: $screen-xs-max) {
      font-size: 14px;
      white-space: inherit; }
    @media (max-width: 540px) {
      max-width: 295px;
      display: inline-block; } } }

.text-expertise {
    position: relative;
    z-index: 2;
    font-family: $got;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.80;
    letter-spacing: 0.2px;
    text-align: left;
    color: $black;
    max-width: 370px;
    margin-left: -75px;
    @media (max-width: $screen-xs-max) {
      margin-left: 0;
      text-align: center;
      padding: 0 15px;
      font-size: 12px;
      max-width: 300px;
      line-height: 1.5;
      display: inline-block; } }
.animation-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0.3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  z-index: -1;

  canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: -1; } }

.mod-title-section {
  position: relative;
  margin-bottom: 60px;
  @media (max-width: $screen-sm-max) {
    margin-bottom: 30px; }
  @media (max-width: $screen-xs-max) {
    max-width: 400px; } }

.content-section {
  @extend %clear-fix;
  position: relative;
  width: 100%; }

.text-style-paragraph {
  font-family: $got;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.85;
  letter-spacing: 0.4px;
  text-align: left;
  color: $black;
  margin-left: 135px;
  margin-bottom: 30px;
  @media (max-width: $screen-sm-max) {
    margin-left: 35px; }
  @media (max-width: $screen-xs-max) {
    margin-left: 0px;
    font-size: 12px;
    line-height: 1.5; }
  &.mod-col-left {
    max-width: 340px;
    float: left;
    @media (max-width: 890px) {
      max-width: 100%; } } }

.text-style-blockquote {
  position: relative;
  width: 100%;
  font-family: $got;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: $black;
  margin-bottom: 30px;
  &:before {
    content: '‘‘';
    position: absolute;
    top: -15px;
    left: -60px;
    font-weight: bold;
    font-family: $got;
    font-size: 80px;
    color: $sunshine-yellow;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.04);
    line-height: 1;
    pointer-events: none; }
  &.mod-col-right {
    max-width: 390px;
    float: right;
    margin-left: 60px;
    @media (max-width: 1100px) {
      max-width: 350px; }
    @media (max-width: $screen-sm-max) {
      float: left;
      margin-left: 55px;
      font-size: 14px; }
    @media (max-width: 890px) {
      max-width: calc(100% - 60px); } } }

.mod-about {
  .list-services a.list-title {
    @media (max-width: $screen-xs-max) {
      line-height: 1.8; } }
  .btn-wrap {
    margin-bottom: 80px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 30px; } } }

.list-number {
  width: 100%;
  padding-left: 135px;
  margin-bottom: 100px;
  @media (max-width: $screen-sm-max) {
    margin-bottom: 30px;
    padding-left: 35px; }
  @media (max-width: $screen-xs-max) {
    padding-left: 0; }
  @extend %clear-fix;
  .el-number {
    float: left;
    width: 33.33%;
    @media (max-width: $screen-xs-max) {
      text-align: center; } }
  .number {
    display: inline-block;
    font-family: $got;
    font-size: 120px;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: black;
    //color: $sunshine-yellow
    //text-shadow: 0 5px 10px rgba(0, 0, 0, 0.04)
    @media (max-width: $screen-sm-max) {
      font-size: 100px; }
    @media (max-width: $screen-xs-max) {
      display: block;
      text-align: center; }
    @media (max-width: 480px) {
      font-size: 50px; } }

  .text-number {
    display: inline-block;
    padding-left: 5px;
    font-family: $got;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.23;
    letter-spacing: 0.4px;
    text-align: left;
    color: $black;
    @media (max-width: $screen-xs-max) {
      text-align: center;
      font-size: 12px; } } }

.list-services,
.list-vacancies {
  @extend %clear-fix;
  width: 100%;
  max-width: 640px;
  margin-bottom: 30px;
  li {
    float: left;
    width: 320px;
    &.is-open {
      a.list-title.is-ready {
        cursor: pointer;
        .number {
          color: $black; }
        &:before {
          transform: scaleX(1);
          @media (max-width: $screen-xs-max) {
            transform: scaleX(0); } } } } }

  a.list-title {
    position: relative;
    text-decoration: none;
    display: inline-block;
    //width: 100%
    font-family: $got;
    font-size: 16px;
    font-weight: bold;
    line-height: 3.75;
    letter-spacing: 0.5px;
    text-align: left;
    color: $black;
    transition: color 0.2s;
    padding-left: 135px;
    @media (max-width: $screen-xs-max) {
      padding-left: 55px; }
    &:hover,
    &:focus {
      text-decoration: none; }
    &:after {
      position: absolute;
      left: 92px;
      top: 50%;
      margin-top: -1px;
      pointer-events: none;
      content: '';
      display: inline-block;
      width: 30px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15);
      @media (max-width: $screen-xs-max) {
        left: 33px;
        width: 10px; } }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      content: '';
      display: inline-block;
      width: 3px;
      height: 100%;
      background-color: $sunshine-yellow;
      transition: transform 0.2s;
      transform: scaleX(0); }

    &:hover {
      cursor: pointer;
      .number {
        color: $black; } } }

  .number {
    display: inline-block;
    position: absolute;
    left: 60px;
    color: rgba(0, 0, 0, 0.15);
    transition: color 0.2s;
    @media (max-width: $screen-xs-max) {
      left: 0; } } }

.list-services {
  a.list-title:hover:before {
    transform: scaleX(1);
    @media (max-width: $screen-sm-max) {
      transform: scaleX(0); } } }

.home-item {
  @media (max-width: $screen-xs-max) {
    text-align: left; }
  &.mod-design,
  &.mod-web-development,
  &.mod-application,
  &.mod-ecommerce,
  &.mod-startups {
    display: none; } }


.list-clients {
  @extend %clear-fix;
  width: 100%;
  float: left;
  border-top: 1px solid #f2f2f2;
  margin-top: 40px;
  @media (max-width: $screen-xs-max) {
    // border: none !important
    border-left: 1px solid #f2f2f2;
    margin-top: 0; }
  li {
    width: 16.66%;
    float: left;
    height: 140px;
    line-height: 140px;
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #f2f2f2;
    @media (max-width: $screen-sm-max) {
      width: 33.33%; }
    @media (max-width: $screen-xs-max) {
      // border: none !important
      height: 80px;
      line-height: 80px;
      border-right: 1px solid #f2f2f2; }
    @media (max-width: 560px) {
      width: 50%; }
    &:nth-child(2n-1) {
      border-right: 1px solid #f2f2f2;
      @media (max-width: $screen-sm-max) {
        border-right: none; }
      @media (max-width: $screen-xs-max) {
        border-right: 1px solid #f2f2f2; } }


    &:hover img {
      opacity: 1; }

    img {
      opacity: 1;
      transition: opacity .4s ease-in-out;
      vertical-align: middle;
      max-width: 115px;
      @media (max-width: 560px) {
        max-width: 100px;
        max-height: 38px; } } } }



.mod-home-first {
  .container.container-center {
    position: relative;
    @media (max-width: $screen-xs-max) {
      position: static; } } }


.play-button {
  position: absolute;
  top: 50%;
  z-index: 9;
  right: 12.5%;
  width: 6.25rem;
  height: 5rem;
  font-size: 1rem;
  cursor: pointer;
  @media (max-width: $screen-md-max) {
    margin-top: -50px; }
  @media (max-width: 1080px) {
    right: 20%; }
  @media (max-width: $screen-sm-max) {
    right: 24%; }
  @media (max-width: $screen-xs-max) {
    right: auto;
    top: auto;
    left: 10px;
    bottom: 25px; }
  @media (max-width: 414px) {
    margin-top: 0;
    height: 3rem;
    left: 0px;
    bottom: 20px; }
  .icon-play {
    margin-top: -0.3rem;
    margin-left: -3px;
    font-size: 22px; }
  > {
    .circle {
      position: relative;
      width: 2.125em;
      height: 2.125em;
      margin: auto;

      > {
        .js-icon-white {
          position: absolute;
          z-index: 5;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 0.75em;
          height: 0.875em;
          margin-top: -0.4375em;
          margin-left: -0.375em;
          display: none;
          margin-left: -0.29em;
          .icon-play {
            fill: $sunshine-yellow; } }
        .black {
          z-index: 4;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 62px;
          height: 62px;
          margin-top: -31px;
          margin-left: -31px;
          opacity: 0.9;

          .js-icon-black {
            position: absolute;
            z-index: 3;
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.75em;
            height: 0.875em;
            margin-top: -0.4375em;
            margin-left: -0.375em;
            margin-left: -0.29em;
            .icon-play {
              fill: $black; } } }

        .yellow {
          position: absolute;
          z-index: 3;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 48px;
          height: 48px;
          margin-top: -24px;
          margin-left: -24px; }

        .border {
          position: absolute;
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 72px;
          height: 72px;
          margin-top: -36px;
          margin-left: -36px; }

        .white {
          position: absolute;
          z-index: 2;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 120px;
          height: 120px;
          margin-top: -60px;
          margin-left: -60px;
          opacity: 0.05; } } }

    .text {
      position: relative;
      margin-top: 20px;
      text-transform: uppercase;
      text-align: center;
      font-family: $got;
      font-size: 14px;
      font-weight: bold;
      line-height: 3.75;
      letter-spacing: 0.5px;
      color: $black;
      @media (max-width: $screen-xs-max) {
        font-size: 12px; }
      @media (max-width: 414px) {
        display: none; } } } }

.fullscreen-video {
  overflow: hidden;
  // z-index: 99
  position: absolute;
  left: 0;
  top: 0;
  // pointer-events: none
  width: 100%;
  cursor: default;
  height: 100%;
  background: white;


  iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }

.ytp-chrome-top {
  opacity: 0 !important; }

.video-background {
  .close {
    @media (max-width: $screen-sm-max) {
      top: 80px;
      z-index: 2;
      color: white;
      right: 30px; }
    @media (max-width: $screen-xs-max) {
      top: 20px;
      right: 20px; }
    .icon {
      @media (max-width: $screen-sm-max) {
        color: white; } } } }

.video-start .video-background {
  pointer-events: all;
  z-index: 99;
  cursor: default; }

.video-background {
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  pointer-events: none;
  transition: opacity 0.3s ease;
  @media (max-width: $screen-xs-max) {
    display: none; }
  .video-controls {
    @media (max-width: $screen-xs-max) {
      display: none; } } }
.video-foreground {
  &::before {
    @media (max-width: $screen-xs-max) {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 60px;
      z-index: 1;
      background-color: black; } } }
.video-foreground, .video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; }

@media (min-aspect-ratio: 16 / 9) {
  .video-foreground {
    height: 300%;
    top: -100%;
    @media (max-width: $screen-sm-max) {
      height: 100%;
      top: 0; } } }


@media (max-aspect-ratio: 16 / 9) {
  .video-foreground {
    width: 300%;
    left: -100%;
    @media (max-width: $screen-sm-max) {
      width: 100%;
      left: 0; } } }



.prices-page, .team-page, .case-detail {
  .header .lang {
    li {
      &.is-active {
        a {
          color: white; } }
      a {
        color: #949494;
        &:hover,
        &:focus {
          color: white; } } } } }
