.mod-team {
	background-color: $black;
	@media (max-width: 1229px) {
		padding: 80px 0 40px; }

	.photo-user-container {
		position: absolute;
		z-index: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		left: 0;
		top: 0;
		@media (max-width: $screen-xs-max) {
			pointer-events: all; }

		.photo-user-wrap {
			width: 1440px;
			max-height: 940px;
			height: 840px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			text-align: center;
			opacity: 0;
			margin-left: -80px;
			@media (max-height: 768px) {
				height: 760px; }
			@media (max-height: 660px) {
				height: 660px; }

			@media (min-width: 1460px) and (min-height: 768px) {
				height: 940px;
				margin-left: -220px; }

			@media (max-width: 860px) {
				margin-left: 0; }

			@media (max-width: $screen-xs-max) {
				margin-left: 15px;
				height: 70vh;
				top: -20px;
				transform: translate(-50%, 0); }

			img {
				height: 100%;
				@media (max-width: $screen-xs-max) {
					display: inline-block; } } } }

	.slick-list, .slick-track, .slick-slide, .slick-slide > div {
		height: 100% !important; }

	.slick-slide > div {
		justify-content: center;
		align-items: center;
		display: flex;
		padding: 5px; }

	.text-block-item {
		position: relative;
		z-index: 1;
		@media (max-width: $screen-xs-max) {
			position: absolute;
			bottom: 90px;
			left: 0px; } }

	.text-block {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		@media (max-width: 1300px) {
			left: -50px; }

		@media (max-width: 1100px) {
			left: 0; }

		@media (max-width: $screen-xs-max) {
			max-width: 100%;
			min-height: 35vh; }
		@media (max-width: 420px) {
			max-width: 100%;
			min-height: 25vh; }


		.slick-list {
			overflow: visible; }
		.user-name {
			font-family: $got;
			font-size: 100px;
			font-weight: bold;
			letter-spacing: 1.7px;
			color: #ffffff;
			margin-bottom: 15px;
			line-height: 1;
			margin-left: -6px;
			opacity: 0;
			@media (max-height: 768px) {
				font-size: 80px;
				margin-bottom: 15px; }
			@media (max-height: 660px) {
				margin-bottom: 5px; }
			@media (max-width: 1180px) {
				font-size: 80px; }
			@media (max-width: $screen-sm-max) {
				font-size: 60px; }
			@media (max-width: $screen-xs-max) {
				font-size: 40px;
				padding-left: 17px;
				padding-right: 17px; } }

		.position {
			font-family: $got;
			font-size: 28px;
			line-height: 44px;
			color: #ffffff;
			margin-bottom: 30px;
			text-transform: none;
			opacity: 0;
			@media (max-height: 768px) {
				font-size: 20px;
				line-height: 1.2;
				margin-bottom: 25px; }
			@media (max-height: 660px) {
				margin-bottom: 20px; }
			@media (max-width: 1180px) {
				font-size: 22px;
				line-height: 1.4; }
			@media (max-width: $screen-sm-max) {
				font-size: 20px;
				line-height: 1.4; }
			@media (max-width: $screen-xs-max) {
				font-size: 16px;
				padding-left: 12px;
				padding-right: 12px; }
			b {
				font-weight: 500; } }

		.user-text {
			font-family: $got;
			font-size: 14px;
			line-height: 1.8;
			letter-spacing: 0.2px;
			color: #ffffff;
			opacity: 0;
			@media (max-height: 766px) {
				line-height: 1.6; }
			@media (max-width: $screen-xs-max) {
				font-size: 12px;
				padding-left: 12px;
				padding-right: 12px; }
			b {
				font-weight: bold; } } } }


body {
	@media (max-width: $screen-xs-max) {
		overflow: hidden; }
	&.disable-container-center, &.case-detail, &.prices-page {
		@media (max-width: $screen-xs-max) {
			overflow: scroll; } } }
.team-page {
	overflow: hidden;
	.el-bg-text {
		color: rgba(0, 0, 0, 0.06);
		z-index: 0;
		top: 10%;
		left: auto;
		bottom: auto;
		right: 22%;
		width: auto; }

	.team-right-menu {
		display: none !important; }

	.number-wrap {
		position: fixed;
		right: 50px;
		top: 50%;
		color: #181818;
		display: inline-block;
		font-family: $got;
		font-size: 40px;
		font-weight: bold;
		line-height: 1.14;
		letter-spacing: -2px;
		transform: translateY(-50%);
		@media (max-width: 1229px) {
			right: auto;
			left: 50px; }
		@media (max-width: $screen-sm-max) {
			transform: translateY(0);
			transform: translateX(-50%);
			left: 50%;
			top: auto;
			bottom: 20px; }
		@media (max-width: $screen-xs-max) {
			transform: translateX(0);
			left: 30px;
			font-size: 30px;
			bottom: 26px; }

		.current-number {
			color: white;
			display: inline-block;
			min-width: 54px;
			text-align: center; }
		.all-number {
			font-size: 30px;
			@media (max-width: $screen-xs-max) {
				font-size: 20px; } } } }
