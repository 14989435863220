
.time_buttons_wrap {
    font-family: $got;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: $screen-sm-max) {
        flex-direction: column;
        justify-content: center; }

    .time_button_item {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 0px 20px #00000021;
        padding: 15px 20px 13px;
        display: flex;
        min-width: 270px;
        align-items: center;
        margin-top: 30px;
        max-width: 300px;

        .time_button_title {
            font-size: 13px;
            font-weight: normal;
            line-height: 1.85;
            letter-spacing: 1px;
            text-align: left;
            color: $black;
            text-transform: uppercase;
            margin-right: 20px;
            flex-grow: 1; }

        .time_button_value {
            font-size: 13px;
            font-weight: normal;
            line-height: 1.85;
            letter-spacing: 0.4px;
            text-align: left;
            color: $black;
            margin-right: 15px; }

        .time_button_icon {
            color: #c7c7c7;
            align-items: center;
            display: flex; } } }

.time_total {
    font-family: $got;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;

    .time_total_title {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.85;
        letter-spacing: 0.4px;
        text-align: left;
        color: $black;
        margin-right: 20px; }

    .time_total_value {
        font-size: 15px;
        font-weight: bold;
        line-height: 1.85;
        letter-spacing: 0.4px;
        text-align: left;
        color: $black;

        span {
            font-size: 13px;
            font-weight: normal; } } }
