.mod-policy {
  font-family: $got;
  .bgTitle {
    font-size: 150px;
    font-weight: bold;
    letter-spacing: 2.5px;
    color: rgba(0, 0, 0, 0.03);
    position: absolute; }

  .policy {
    position: relative;

    &_subname {
      padding: 30px 0; }

    &_titleBlock {
      position: relative; }

    &_mtitle {
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 0.4px;
      text-align: center;
      max-width: 460px;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      @media (max-width: $screen-xs-max) {
        margin-top: 20px;
        font-size: 40px; }
      @media(max-width: 485px) {
        font-size: 38px; }
      span {
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: 0.27px;
        display: block;
        font-weight: 100;
        text-align: left;
        padding-left: 10px;
        @media(max-width:485px) {
          text-align: center; } } }

    &_list {
      margin-right: 20px;
      padding-left: 20px;
      list-style-type: decimal;
      max-width: 300px;
      @media(max-width:880px) {
        max-width: 230px; }
      @media(max-width:550px) {
        max-width: 100%; }
      li {
        padding-left: 10px;
        opacity: 0.5;
        transition: 0.5s ease;
        &:hover {
          opacity: 1; }
        a {
          text-decoration: none;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 0;
          margin-bottom: 10px;
          display: block;
          letter-spacing: 0;
          color: #111;
          @media(max-width: 400px) {
            font-size: 14px; } }
        &.active {
          opacity: 1; } } }
    ul {
      list-style-type: disc; }

    &_title {
      font-family: $got;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.53;
      text-align: left;
      padding: 50px 0;
      color: $black;
      @media (max-width: $screen-sm-max) {
        font-size: 26px; }
      @media (max-width: $screen-xs-max) {
        font-size: 18px;
        padding: 80px 0 40px; }

      @media(max-width: 475px) {
        margin-top: 50px; }
      @media(max-width: 400px) {
        margin-top: 0;
        padding: 70px 0 20px;
        font-size: 16px; } }

    &_block {
      display: flex;
      justify-content: space-between;
      @media(max-width:550px) {
        flex-direction: column; } }

    &_lt {
      width: 340px;
      @media(max-width:550px) {
        width: 100%;
        display: none;
        padding-bottom: 50px; }
      @media(max-width:400px) {
        padding-bottom: 20px; } }

    &_rt {
      width: 70%;
      @media(max-width:550px) {
        width: 100%; } }

    &_part {
      font-family: $got;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.85;
      letter-spacing: 0.2px;
      color: $black;
      margin-bottom: 40px;
      @media (max-width: $screen-xs-max) {
        font-size: 12px;
        line-height: 1.6;
        margin-bottom: 30px; }
      a {
        font-weight: 900;
        text-decoration: none;
        transition: all 0.5s ease;
        &:hover {
          opacity: 0.5; } }
      ul {
        li {
          text-indent: 40px; } } }
    ol {
      list-style-type: decimal;
      padding-left: 20px;
      padding-top: 15px; }

    &_name {
      text-decoration: none;
      font-family: $got;
      font-size: 16px;
      font-weight: bold;
      line-height: 3.75;
      letter-spacing: 0.5px;
      color: $black;
      transition: color 0.2s; } }

  .rectangle-yellow-wrap {
    opacity: 1; }

  .rectangle-yellow-wrap {
    width: 300px;
    height: 300px;
    left: 0;
    right: 0;
    margin: 0;
    @media (max-width: $screen-xs-max) {
      display: none; }
    @media(max-width:485px) {
      left: 50%;
      transform: translate(-50%); } }

  .group-lines .line-4 {
    opacity: 1;
    @media(max-width:768px) {
      width: 50px;
      height: 100px;
      left: 250px;
      top: 30px; } }

  .item-expertise {
    margin-left: auto;
    position: relative;
    display: block;
    min-height: 300px;
    @media (max-width: $screen-xs-max) {
      display: none; }
    @media(max-width:485px) {
      margin: 0 auto; } } }
