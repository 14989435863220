.cookie {
  max-width: 290px;
  position: relative;
  display: block;
  z-index: 9;
  position: fixed;
  padding: 0;
  right: 18vw;
  width: inherit;
  top: 4vh;
  height: fit-content;
  will-change: transform;
  transform: scale(0);
  transition: all 0.5s ease;
  transition-delay: .45s;
  @media (max-width: $screen-lg) {
    right: 22vw;
    top: 7vh; }
  @media (max-width: $screen-md-max) {
    right: 22vw;
    top: 10vh; }
  @media (max-width: $screen-xs-max) {
    right: 19vw;
    top: 11vh; }
  @media (max-width: 480px) {
    right: auto;
    z-index: 100;
    bottom: 15px;
    top: auto;
    left: 15px;
    .cookie__block {
      max-width: 230px; } }
  .close {
    position: absolute;
    width: 30px;
    height: 30px;
    background: $sunshine-yellow;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    right: 0;
    top: 0;
    transform: translate(50%,-50%) rotate(180deg) scale(1);
    cursor: pointer;
    .icon {
      font-size: 15px; } }

  &__block {
    padding: 2em;
    width: inherit;
    left: inherit;
    border: none !important;
    border-radius: 6px;
    background: #000;
    color: #fff;
    height: fit-content;
    max-width: 290px;
    border-radius: 6px;
    z-index: 8;
    box-shadow: 15px 15px 30px rgba(0,0,0,.22);
    will-change: transform;
    @media(max-width:450px) {
      max-width: 250px;
      padding: 1em; } }

  &__title {
    display: flex;
    align-items: center;
    color: inherit;
    justify-content: space-between;
    h3 {
      font-weight: 600;
      color: inherit; }
    .icon {
      font-size: 22px;
      position: relative;
      top: -2px; } }

  &__txt {
    padding: 5px 0 15px;
    font-weight: 100;
    font-size: 12px;
    line-height: 16px;
    a {
      text-decoration: underline; } }

  &__btn {
    background-color: $sunshine-yellow;
    color: #000;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    outline: none;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transition: all .2s;
    &:hover {
      transform: translateY(5px);
      box-shadow: 0 6px 20px -10px rgba(255, 255, 50, 0.8); } } }

.team-page .cookie {
  .cookie__block {
    color: #000;
    background: #fff; } }
