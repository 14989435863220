// responsive text align
//-----------------------------------------------------------------------------------
.text {
  &-xs-left {
    text-align: left; }

  &-xs-right {
    text-align: right; }

  &-xs-center {
    text-align: center; }

  @media (min-width: $screen-sm-min) {
    &-sm-left {
      text-align: left; }

    &-sm-right {
      text-align: right; }

    &-sm-center {
      text-align: center; } }

  @media (min-width: $screen-md-min) {
    &-md-left {
      text-align: left; }

    &-md-right {
      text-align: right; }

    &-md-center {
      text-align: center; } }

  @media (min-width: $screen-lg-min) {
    &-lg-left {
      text-align: left; }

    &-lg-right {
      text-align: right; }

    &-lg-center {
      text-align: center; } } }
