// font default
//-----------------------------------------------------------------------------------
body, input, button, select, textarea {
  font-family: $default-font; }

// Common classes
//-----------------------------------------------------------------------------------
body {
  //height: 100%
  font-size: 16px;
  color: #111111; }

.clear {
  clear: both; }

%clear-fix:after {
  content: '';
  clear: both;
  display: table;
  width: 100%; }

// positioning
//-----------------------------------------------------------------------------------
.fl {
  float: left; }

.fr {
  float: right; }

// text align -- registration
//-----------------------------------------------------------------------------------
.text {
  &-left {
    text-align: left; }

  &-center {
    text-align: center; }

  &-right {
    text-align: right; } }

pre, code {
  font-family: monospace; }

// link
//-----------------------------------------------------------------------------------
a {
  color: inherit;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: underline; } }

img {
  position: relative;
  display: inline-block;
  border: none;
  vertical-align: top;
  max-width: 100%;
  height: auto;
  font: 300 12px/2 $default-font;
  text-align: center;
  &:after {
    content: "Broken Image of " attr(alt);
    display: block;
    color: rgb(100, 100, 100);
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff; } }

// form
//-----------------------------------------------------------------------------------
input {
  vertical-align: middle;
  margin: 0;
  padding: 0;

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0; } }

button,
a.button,
input[type='reset'],
input[type='submit'],
input[type='button'],
input[type='image'] {
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  text-decoration: none; }

// WebKit/Blink Browsers
//-----------------------------------------------------------------------------------
::selection {
  background: $color-selection; }


// vertical align middle
//-----------------------------------------------------------------------------------
%vafix {
  font-size: 0;
  &:before {
    display: inline-block;
    margin-right: -0.25em;
    height: 100%;
    content: '';
    vertical-align: middle; } }
