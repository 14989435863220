.page-wrapper.page-open.blogPage.article .mod-case-detail {
  padding-top: 0px!important; }
.blog-pg.mod-case-detail {
  padding: 0 0!important;

  .case-detail-first {
    position: relative;
    height: 345px;
    padding-top: 0;
    padding-bottom: 30px;
    display: flex;
    align-items: flex-end;

    h1 {
      text-align: left; } } }

.bl-tabs {
  display: flex;
  padding-top: 40px;

  &--tab {
    color: #fff;
    margin-right: 46px;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: .32px;
    padding-bottom: 11px;
    position: relative;

    &:hover:before {
      transition: width .3s;
      width: 95%; }

    &:before {
      position: absolute;
      content: '';
      transition: width .3s;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: #FFFF33; } } }

.bl-tabs--tab.active {
  color: #fff;

  &:before {
    transition: width .3s;
    width: 95%; } }

.blog-tabs-content-wr {
  padding: 0 160px; }

.blog-tabs-content-wr--item {
  display: none; }

.blog-tabs-content-wr--item.active {
  display: block; }


.blog-tabs-content-wr--item .row .col-md-4 {
      border-top: 1px solid #e1e1e1; }

.blog-tabs-content-wr--item .row .col-md-4:nth-child(1), .blog-tabs-content-wr--item .row .col-md-4:nth-child(2), .blog-tabs-content-wr--item .row .col-md-4:nth-child(3) {
        border-top: 0; }

.article-pre-wr {
  position: relative; }

.article-pre {
  margin: 80px 0;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:before {
    content: '';
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
    opacity: 0;
    transition: opacity .3s;
    z-index: -1; }

  &:hover:before {
    content: '';
    opacity: 1; }

  &--label {
    color: #000000;
    font-size: 16px;
    line-height: 25px;
    opacity: 0.300000011920929;
    padding-bottom: 15px;
    width: 100%; }

  &--title {
    font-family: $got;
    font-weight: bold;
    font-size: 35px;
    line-height: 56px;
    padding-bottom: 70px;
    display: block;

    &:hover {
      text-decoration: none; } }

  &--text {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 40px; }

  &--read {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: right;
    position: relative;
    padding-bottom: 13px;
    display: block;

    &:hover {
      text-decoration: none; }
    &:hover:after {
      transition: width .3s;
      width: 100%; }
    &:after {
      transition: width .3s;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: 24px;
      height: 3px;
      background: #FFFF33; } } }

.blogPage path.jet {
  fill: #fff; }

.blogPage path.jet.black {
  fill: #000; }

.page-wrapper.page-open.blogPage.article .js-anim-btn-menu .line.black {
  background: #000; }

.page-wrapper.page-open.blogPage.article .js-anim-btn-menu .title.black {
  color: #000; }

.blogPage .js-anim-email.black {
  color: #000; }

.blogPage .js-anim-email {
  color: #fff; }

//.blogPage .btn-menu
//  position: absolute
//  top: 397px

.blog-pg .case-detail-first h1 {
  margin-bottom: 0;
  font-size: 60px; }

// single blog article

.page-wrapper.page-open.blogPage.article {
  h1 {
    font-size: 60px;
    line-height: 70px;
    text-align: left;
    margin-bottom: 102px; }

  .case-detail-first {
    height: 700px;
    padding-top: 303px;
    position: relative; }

  .mod-case-detail {
    padding-top: 0;
    padding-bottom: 0px;
    background: #fff;
    width: 100vw;
    max-width: 100vw;
    margin: auto;
    transition: 1s;
    transform: scale(1) translateY(0px); }

  .mod-case-detail.active {
    transform: scale(0.84) translateY(200px); } }

.mod-case-detail.active+.article-footer {
 }  //margin-top: -200px

.article-author {
  .top {
    display: flex;

    .el {
      color: #FFFFFF;
      font-size: 18px;
      line-height: 16px;
      opacity: 0.6000000238418579;
      margin-right: 31px;
      font-weight: 500; } }

  .bottom {
    display: flex;
    margin-top: 40px;

    .item {
      display: flex;
      align-items: center;
      margin-right: 30px;

      img {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        margin-right: 20px; }

      .left {
        .name {
          color: #FFFFFF;
          font-size: 18px;
          line-height: 16px;
          margin-bottom: 10px;
          font-weight: 500; }
        .status {
          color: #FFFFFF;
          font-size: 16px;
          line-height: 15px;
          opacity: 0.6000000238418579;
          font-weight: normal; } } } } }

.page-wrapper.page-open.blogPage.article .article-content {
  padding: 80px 0 40px;

  h1 {
    margin: 60px 0 30px; }
  h2 {
    font-size: 32px;
    line-height: 48px;
    margin: 60px 0 30px; }
  h3 {
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    margin-bottom: 30px; }
  h4 {
    font-size: 24px; }
  p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px; }
  a {
    color: #3390ff;
    //&:hover
 }    //  text-decoration-color: #ffff33


  img {
    margin-bottom: 30px; }

  em {
    position: relative;
    padding-left: 15px;
    display: block;
    font-size: 18px;
    line-height: 28px;
    font-style: normal;
    margin: 60px 0 60px;


    &:before {
      position: absolute;
      content: '';
      width: 5px;
      height: 100%;
      background: #FFFF33;
      left: 0; } }
  nav {
    margin: 80px 0 60px;
    display: flex;
    justify-content: space-between;

    a {
      padding: 10px 30px;
      color: #000000;
      background: #F7F7F7;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      transition: .5s;

      &:hover {
        text-decoration: none;
 }        //box-shadow: inset 0 0 5px #ffff33
      &:active {
        background: #FFFF33;
        box-shadow: inset 0 0 100px #ffff33; } } }
  ul {
    padding-left: 40px;

    li {
      list-style-type: decimal;
      line-height: 28px; } }
  .call-to-action {
    display: flex;
    align-items: center;
    padding: 20px;
    border-left: 5px solid #ffff33;

    h4 {
      margin-bottom: 20px; }
    p {
      font-size: 16px;
      padding-right: 50px; } } }


.article-content-wr {
  position: relative; }


.article-menu.fix {
  position: fixed;
  top: 70px; }

.article-menu {
  position: absolute;
  right: 210px;
  top: 770px;
  z-index: 9;

  .el {
    color: #949494;
    font-size: 16px;
    line-height: 15px;
    padding: 10px 0;
    text-align: right;
    font-weight: 500;
    display: block;
    transition: .5s;
    border-right: 0px solid #FFFF33;

    &:hover {
      text-decoration: none;
      color: #111;
      padding-right: 10px;
      border-right: 2px solid #FFFF33; } }

  .el.active {
    color: #111;
    padding-right: 10px;
    border-right: 2px solid #FFFF33; } }

.article-footer {
  padding: 10px 0 80px;
  transition: 1s;
  background-color: #F7F7F7;
  position: relative;
  z-index: 1;

  .container {
    max-width: 830px; }

  .title {
    font-size: 25px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 30px; }

  .articles {
    display: flex;
    justify-content: space-between;

    .articles-article {
      width: 308px;

      .title {
        display: block;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
        &:hover {
          text-decoration-color: #318efd; } }

      .text {
        font-size: 12px;
        line-height: 22px;
        font-weight: normal;
        margin-bottom: 30px; }

      .bott {
        display: flex;
        justify-content: space-between;

        .date {
          color: #000000;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 12px;
          opacity: 0.5;
          font-weight: normal; }

        .read {
          color: #000;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 17px;
          text-align: right;
          position: relative;
          padding-bottom: 13px;
          display: block;
          font-weight: bold;

          &:hover {
            text-decoration: none; }
          &:hover:after {
            transition: width .3s;
            width: 100%; }
          &:after {
            transition: width .3s;
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            width: 24px;
            height: 3px;
            background: #FFFF33; } } } } } }


.article-wrr {
  //background: #FFF
  position: relative;
  z-index: 1; }

.case-detail-first .bg-img {
  z-index: 0; }

.lines {
  z-index: 0; }

.page-wrapper.page-open.blogPage.article {
  font-family: $got;
  background-color: #F7F7F7;
  overflow: hidden;

  .social {
    display: none; }
  .social.active {
    display: flex; }
  .js-anim-btn-menu {
    .line {
      background: #fff; }
    .title {
      color: #fff; } } }

.blog-sl {
  overflow: hidden;
  height: 100vh;

  .case-detail-first {
    padding-top: 264px;

    h1 {
      text-align: left;
      font-size: 60px;
      line-height: 70px;
      margin-bottom: 39px; } } }

body.team-page .blog-sl .lines {
  border-color: #000;

  &:before {
    border-color: #000; } }


// blog sl page

.article-tag {
  color: #FFF;
  width: 100%;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 28px;
  opacity: 0.699999988079071;
  margin-bottom: 10px;
  text-transform: uppercase; }

.blog-slider {
  position: relative;
  z-index: 4;
  max-width: 671px; }

.blog-slider-slide-wr {}

.blog-slider-slide {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  h1 {
    width: 100%; }

  .text {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    padding-left: 148px;
    margin-bottom: 41px; }

  .read-more {
    color: #FFF;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: right;
    position: relative;
    padding-bottom: 13px;
    display: block;

    &:hover {
      text-decoration: none; }
    &:hover:after {
      transition: width .3s;
      width: 100%; }
    &:after {
      transition: width .3s;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: 24px;
      height: 3px;
      background: #FFFF33; } } }

.page-wrapper .blog-slider button {
  bottom: -190px;
  top: initial;
  border: 1.5px solid #FFFFFF;
  width: 53px;
  height: 53px;
  background: none;

  &:hover {
    background-color: #FFFF33;
    border: 1.5px solid #FFFF33; }

  &:before {
    content: 'Tips for becoming real UX Expert. Let’s do it!';
    position: absolute;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 28px;
    width: 202px;
    text-align: left; }

  &:hover:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 118px;
    background: #fff; } }

.page-wrapper .blog-slider button.slick-prev {
  left: 145px;

  &:before {
    right: -225px;
    top: 0; }

  &:hover:before {
    right: -140px;
    top: initial; } }

.page-wrapper .blog-slider button.slick-next {
  background: url('../img/content/right-arrow.png') center no-repeat;

  &:before {
    left: -205px;
    top: 0; }

  &:hover:before {
    left: -140px;
    top: initial; } }


/// responsive

@media (max-width: 1900px) {
  .article-menu {
    display: none; } }
@media (max-width: 1500px) {
  .article-menu.fix {
    top: 200px; }
  .article-content {
    padding: 80px 100px 40px 0; } }
@media (max-width: 1300px) {
  .article-pre {
    margin: 60px 0;
    padding: 0 5px; }
  .article-pre--title {
    margin-bottom: 40px;
    padding-bottom: 0px; }
  .article-menu {
    display: none; } }

@media (max-width: 1230px) {
  .blogPage path.jet {
    fill: #000; }
  .blogPage .js-anim-email {
    color: #000; } }
@media (max-width: 1080px) {
  .blog-tabs-content-wr {
    padding: 0 10px; }
  .blog-pg .lines {
    width: calc((100% - 10px)*.3333333); }
  .justBlog #header .lines {
    width: 100%; }
  .justBlog #header .lines:before {
    width: calc((100% - 10px)*.3333333); } }
@media (max-width: 1000px) {
  .blog-pg .lines {
    border-color: #fff; } }
@media (max-width: 780px) {
  .article-pre {
    margin: 50px 0; }
  .article-pre--title {
    font-size: 30px;
    line-height: 48px; }
  .article-pre--text {
    padding-bottom: 25px; }
  .blog-tabs-content-wr--item.active .row {
      display: flex;
      flex-wrap: wrap;

      .col-md-4:nth-child(1n) {
        border-right: 1px solid #e1e1e1; }
      .col-md-4:nth-child(2n) {
        border-right: 0px solid #e1e1e1; } }
  .blog-tabs-content-wr--item .row .col-md-4 {
    border-top: 0px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1; }
  .page-wrapper.page-open.blogPage.article h1 {
    font-size: 50px; }
  .page-wrapper.page-open.blogPage.article .case-detail-first {
    height: auto;
    padding: 200px 0 60px; }
  .page-wrapper.page-open.blogPage.article .article-content p {
    margin-bottom: 20px; }
  .page-wrapper.page-open.blogPage.article .article-content {
    padding: 30px 0 30px; } }

@media (max-width: 750px) {
  .page-wrapper.page-open.blogPage.article .article-content .call-to-action {
    border-left: 0; }
  .page-wrapper a.article-pre--title {
    line-height: 48px;
    width: 100%;
    font-size: 28px; }

  .blog-tabs-content-wr--item.active .row .col-md-4:nth-child(1n) {
    border-right: 0px solid #e1e1e1; }

  .blog-pg.mod-case-detail .case-detail-first {
    height: auto;
    padding-top: 160px;
    padding-bottom: 25px; }

  .blog-pg.mod-case-detail .case-detail-first h1 {
    text-align: left;
    margin-bottom: -110px; }
  .bl-tabs {
    padding-top: 40px;
    text-align: right;
    flex-direction: column;
    align-items: flex-end; }
  .bl-tabs--tab {
    width: fit-content;
    margin-right: 0;
    margin-bottom: 7px; }
  .bl-tabs--tab:before {
    transition: width 1s; }
  .bl-tabs--tab.active:before {
    width: 120%;
    transition: width .3s;
    right: 0;
    left: inherit; }
  .page-wrapper.page-open.blogPage.article h1 {
    font-size: 40px;
    margin-bottom: 50px; }
  .page-wrapper.page-open.blogPage.article .case-detail-first {
    padding: 130px 0 40px; }
  .article-author .top .el {
    font-size: 16px; }
  .article-author .bottom .item img {
    margin-right: 15px; }
  .article-author .bottom .item {
    margin-right: 20px; }
  .article-author .bottom .item .left .status {
    font-size: 14px; }
  .article-author .bottom .item img {
    height: 40px;
    width: 40px; }
  .page-wrapper.page-open.blogPage.article .article-content h1 ,.page-wrapper.page-open.blogPage.article .article-content h2 {
    margin: 30px 0 15px; }
  .page-wrapper.page-open.blogPage.article .article-content a {
    font-size: 16px; }
  .page-wrapper.page-open.blogPage.article .article-content h2 {
    font-size: 28px; }
  .page-wrapper.page-open.blogPage.article .article-content h3 {
    font-size: 22px; }
  .page-wrapper.page-open.blogPage.article .article-content h4 {
    font-size: 18px; }
  .page-wrapper.page-open.blogPage.article .article-content em {
    margin: 30px 0 30px; }
  .page-wrapper.page-open.blogPage.article .article-content ul {
    padding-left: 30px; }
  .page-wrapper.page-open.blogPage.article .article-content nav {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0; }
  .page-wrapper.page-open.blogPage.article .article-content nav a {
    width: 47%;
    margin-bottom: 15px;
    font-size: 14px; }
  .page-wrapper.page-open.blogPage.article .article-content .call-to-action {
    padding: 0;
    flex-direction: column;
    text-align: center; }

  .page-wrapper.page-open.blogPage.article .article-content .call-to-action p {
    padding-right: 0; }
  .article-footer .articles {
    flex-direction: column; }
  .article-footer .articles .articles-article {
    width: 100%;
    margin-bottom: 30px; }
  .page-wrapper.page-open.blogPage.article .mod-case-detail.active {
    transform: scale(0.84) translateY(250px); }
  .article-author .bottom .item:nth-child(2n) {
    margin-right: 0; } }

@media (max-width: 350px) {
  .page-wrapper.page-open.blogPage.article h1 {
    font-size: 34px;
    line-height: 52px; }
  .article-author .bottom .item img {
    height: 30px;
    width: 30px; }
  .page-wrapper.page-open.blogPage.article .article-content h3 {
    font-size: 18px; }
  .article-footer {
    padding: 10px 0 60px; }
  .page-wrapper.page-open.blogPage.article .mod-case-detail.active {
    transform: scale(0.84) translateY(300px); }
  .article-author .top .el {
    margin-right: 15px; } }







.test-mod {
  flex-direction: column; }
