.list-awards {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f2f2f2;
  margin-top: 40px;
  float: left;
  margin-bottom: 100px;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 40px;
    margin-top: 0;
    border-left: 1px solid #f2f2f2; }
  @media (max-width: 520px) {
    flex-direction: column; }

  &.mod-recognitions {
    flex-wrap: wrap;
    .item-awards {
      flex-basis: 100%;
      min-width: 100%;
      .ico-wrap {
        height: 280px;
        flex-direction: column;
        border-bottom: none;
        img {
          max-width: 160px;
          max-height: 160px;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%); } } }

    .item-awards-wrap {
      flex-basis: 33.333%;
      min-width: 33.333%;
      @media (max-width: $screen-xs-max) {
        flex-basis: 50%;
        min-width: 50%; }
      @media (max-width: 520px) {
        flex-basis: 100%;
        min-width: 100%; }
      &.mod-number {
        @media (max-width: $screen-xs-max) {
          display: flex; } } } }

  .item-awards-wrap {
    flex-basis: 33.333%;
    min-width: 33.333%;
    @media (max-width: $screen-xs-max) {
      flex-basis: 50%;
      min-width: 50%; }
    @media (max-width: 520px) {
      flex-basis: 100%;
      min-width: 100%; }
    &.mod-number {
      position: relative;
      height: 280px;
      border-bottom: 1px solid #f2f2f2;
      justify-content: center;
      align-items: center;
      display: flex;
      @media (max-width: $screen-xs-max) {
        display: none; } }

    &.mod-two {
      display: flex;
      flex-direction: row; } }

  .item-awards {
    flex-basis: 50%;
    min-width: 50%;
    @media (max-width: $screen-xs-max) {
      border-right: 1px solid #f2f2f2; }
    &:not(:last-child) {
      border-right: 1px solid #f2f2f2; }


    .ico-wrap {
      height: 140px;
      border-bottom: 1px solid #f2f2f2;
      justify-content: center;
      align-items: center;
      display: flex;
      .item__title__awards {
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.4;
        letter-spacing: 0.4px;
        text-transform: capitalize;
        margin: 10px 0 0 0;
        max-width: 220px; }
      img {
        max-width: 75px;
        max-height: 75px;
        &.mod-3 {
          max-width: 80px; }
        &.mod-4 {
          max-width: 60px;
          max-height: 60px; } } }

    .description-wrap {
      height: 140px;
      border-bottom: 1px solid #f2f2f2;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 20px;

      .description__num {
        font-size: 40px;
        font-family: $got;
        color: $black;
        font-weight: normal;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        display: block; }

      p {
        display: inline-block;
        padding-left: 5px;
        font-family: $got;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.23;
        letter-spacing: 0.4px;
        text-align: center;
        color: $black;
        @media (max-width: $screen-xs-max) {
          text-align: center;
          font-size: 12px; } } } }

  .number-awards {
    position: absolute;
    font-size: 220px;
    font-weight: bold;
    font-family: $got;
    color: black;
    //color: $sunshine-yellow
    //text-shadow: 0 5px 10px rgba(0, 0, 0, 0.04)
    letter-spacing: -2px;
    padding-top: 30px;
    @media (max-width: $screen-sm-max) {
      font-size: 170px; } } }


.testimonials-wrap {
  width: 100%;
  float: left;
  margin-bottom: 100px;
  text-align: center;
  padding: 0 40px;
  @media (max-width: $screen-xs-max) {
    margin-bottom: 40px; }
  .slick-list, .slick-track, .slick-slide, .slick-slide > div {
    height: 100% !important; }

  .slick-slide > div {
    justify-content: center;
    align-items: center;
    display: flex; }

  .slick-arrow {
    width: 20px;
    height: 20px;
    border-top: 1px solid #949494;
    transition: all 0.2s;
    margin-top: -13px;
    color: transparent;
    &:hover {
      border-color: $black; } }

  .slick-prev {
    left: 20px;
    border-left: 1px solid #949494;
    transform: rotate(-45deg);
    @media (max-width: $screen-xs-max) {
      left: 0; } }

  .slick-next {
    right: 20px;
    border-right: 1px solid #949494;
    transform: rotate(45deg);
    @media (max-width: $screen-xs-max) {
      right: 0; } }

  .testimonial-item {
    width: 100%;
    max-width: 540px;
    text-align: center;
    display: inline-block; }

  .text {
    font-family: $got;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: 0.4px;
    color: $black;
    margin-bottom: 30px;
    @media (max-width: $screen-xs-max) {
      font-size: 12px;
      line-height: 1.5; } }

  .name {
    text-decoration: none;
    display: block;
    width: 100%;
    font-family: $got;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.85;
    letter-spacing: 0.5px;
    color: $black;
    transition: color 0.2s; }

  .author-position {
    text-decoration: none;
    display: block;
    width: 100%;
    font-family: $got;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: 0.4px;
    color: $black;
    transition: color 0.2s; } }

.video-showreel {
  width: 100%;
  position: relative;
  padding-bottom: 54%;
  background-color: white;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 100px;
  @media (max-width: $screen-sm-max) {
    margin-bottom: 30px; }

  iframe {
    border: 1px solid #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }


//founders

.founder-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px auto 100px;
  float: left;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 40px;
    margin-top: 0; }

  @media (max-width: 690px) {
    flex-direction: column; }

  .h2, h2 {
    color: white;
    margin: 20px;
    opacity: 0;
    transition: opacity 1s; }

  .icon {
    opacity: 0;
    transition: opacity 1s;
    stroke: #fff;
    width: 16px;
    height: 16px; }

  .icons {
    position: absolute;
    fill: #fff;
    color: #fff;
    height: 130px;
    bottom: 20px;
    left: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; } }

.border {
  height: 369px;
  width: 290px;
  background: transparent;
  border-radius: 2px;
  transition: border 0.2s;
  position: relative;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid rgba(#fff, 0.4); } }

.card {
  height: 380px;
  width: 300px;
  margin: 0 10px;
  background: grey;
  border-radius: 2px;
  transition: background 0.8s;
  overflow: hidden;
  background: black;
  box-shadow: 0 60px 50px -60px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 690px) {
    margin: 10px 0px; } }

.card0 {
  background: url('../img/team/1_1.png') center center no-repeat;
  background-size: 600px;

  &:hover {
    background: url('../img/team/1_1.png') -180px center no-repeat;
    background-size: 900px;

    .h2, h2 {
      opacity: 1; }

    .icon {
      opacity: 1; } } }

.card1 {
  background: url('../img/team/7.jpg') center center no-repeat;
  background-size: 600px;

  &:hover {
    background: url('../img/team/7.jpg') -180px center no-repeat;
    background-size: 900px;

    .h2, h2 {
      opacity: 1; }

    .icon {
      opacity: 1; } } }

.card2 {
  background: url('../img/team/11.jpg') center center no-repeat;
  background-size: 600px;

  &:hover {
    background: url('../img/team/11.jpg') -180px center no-repeat;
    background-size: 900px;

    .h2, h2 {
      opacity: 1; }

    .icon {
      opacity: 1; } } }
