.icon {
	display: inline-block;
	vertical-align: middle;
	width: 1em;
	height: 1em;
	fill: currentColor; }

.icon-at {
	width:(12/12)*1em {} }

.icon-at-bold {
	width:(12.5/12.5)*1em {} }

.icon-attach {
	width:(30/29.1)*1em {} }

.icon-close {
	width:(21/21)*1em {} }

.icon-color {
	width:(388/495)*1em {} }

.icon-cookie {
	width:(512/512)*1em {} }

.icon-fb {
	width:(104/208)*1em {} }

.icon-insta {
	width:(208/208)*1em {} }

.icon-lin {
	width:(208/197)*1em {} }

.icon-play {
	width:(713.3/841.9)*1em {} }

.icon-send {
	width:(448/448)*1em {} }

.icon-timer {
	width:(512/512)*1em {} }

.icon-tw {
	width:(208/172)*1em {} }

