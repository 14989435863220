$base64-png: 'image/png;base64';
$base64-jpg: 'image/jpeg;base64';
$base64-svg: 'image/svg+xml;base64';

// font var
//-----------------------------------------------------------------------------------
$default-font: Arial, 'Helvetica Neue', Helvetica, sans-serif;

@include font-face('GothamPro', '../fonts/GothamPro', 'GothamPro', normal);
@include font-face('GothamPro', '../fonts/GothamPro-Medium', 'GothamPro-Medium', 500);
@include font-face('GothamPro', '../fonts/GothamPro-Bold', 'GothamPro-Bold', bold);

$got: 'GothamPro', $default-font;

// selection color
//-----------------------------------------------------------------------------------
$color-selection: dodgerblue;

// color var
//-----------------------------------------------------------------------------------
$color-link: dodgerblue;
$sunshine-yellow: rgb(255, 255, 50);
$black: #000000;
$text-bg: rgba(0,0,0,0.03);
$warm-grey: #949494;
