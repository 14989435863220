.list-contacts {
  @extend %clear-fix;
  width: 100%;
  display: inline-block;
  padding-left: 135px;
  margin-bottom: 40px;
  @media (max-width: $screen-sm-max) {
    padding-left: 35px; }

  @media (max-width: $screen-xs-max) {
    margin-bottom: 40px; }
  @media (max-width: 480px) {
    padding-left: 0; }

  li {
    float: left;
    width: 50%;
    margin-bottom: 40px;
    @media (max-width: 480px) {
      width: 100%;
      float: none;
      margin-bottom: 30px; } }

  .title {
    font-family: $got;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    text-align: left;
    color: $warm-grey;
    margin-bottom: 16px;
    @media (max-width: 480px) {
      margin-bottom: 10px; } }

  .contacts-item, .email span {
    position: relative;
    font-family: $got;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: $black;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto; }

  .icon {
    font-size: 17px;
    stroke: $black;
    stroke-width: 0.5px;
    padding: 0;
    margin: -1px -2px 0; } }
