.page-404 {
  h3,
  .h3 {
    position: relative;
    z-index: 2;
    text-align: center;
    margin-bottom: 10px; }

  h1,
  .h1 {
    position: relative;
    z-index: 2;
    text-align: center;
    width: 100%;
    @media (max-width: $screen-xs-max) {
      font-size: 36px; }
    .block-revealer__content, .js-anim-h1 {
      display: inline-block;
      text-align: center; } }

  .btn-wrap {
    display: inline-block;
    position: relative;
    margin-top: 120px; }

  .el-btn {
    position: relative;
    z-index: 2; }
  .block-revealer__element {
    z-index: 2; }

  .error-text-bg {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    font-family: $got;
    font-size: 200px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 4px;
    text-align: left;
    z-index: 1;
    pointer-events: none;
    color: $text-bg;
    @media (max-width: $screen-xs-max) {
      font-size: 140px; } }
  canvas {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0; }
  .page-body {
    @media (max-width: $screen-xs-max) {
      display: flex; } } }
