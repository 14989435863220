html.ie-11,
html.ie-10,
html.ie-9,
html.ie, {
  .page-body {
    display: block;
    overflow: visible; }
  .services-section-image.content--layout {
    img {
      width: 100%; } } }


