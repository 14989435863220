.brief {
  max-width: 650px;
  margin: 20px auto;
  position: relative;
  font-family: $got;
  &_bg {
    left: 0; }
  &_block {
    width: 400px;
    height: 410px;
    margin: 0 auto;
    display: block;
    position: relative;
    left: 0;
    @media(max-width:450px) {
      width: 350px;
      margin-bottom: 100px; }
    @media(max-width:400px) {
      width: 95%;
      height: auto; }
    &_fieldset_i {
      position: relative;
      left: 0;
      &:after {
        content: "";
        background-color: #fff;
        display: block;
        position: absolute;
        bottom: -105px;
        right: -5%;
        width: 105%;
        height: 150px;
        transform: rotate(-11deg); } }
    .action-button {
      @media(max-width:400px) {
        bottom: 20px; } } }

  &_section {
    position: absolute;
    top: 50%;
    left: -65px;
    transform: translateY(-50%);
    background: #fff;
    padding: 25px 20px;
    width: 400px;
    height: 210px;
    box-shadow: 0px 8px 10px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    @media(max-width:550px) {
      left: 0; }
    @media(max-width:450px) {
      left: -15px;
      width: 370px;
      padding: 30px 5px; }
    @media(max-width:400px) {
      width: 95%; }
    @media(max-width:360px) {
      left: 0; } }

  &_titles {
    color: #111;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 20px; }
  &_links {
    display: flex;
    align-items: center;
    margin: 20px 5px;
    a {
      text-decoration: none;
      text-align: center; } }

  &_group {
    margin-right: 20px;
    text-align: center;
    position: relative;
    &.active {
      .brief_imgCover {
        background: $sunshine-yellow; }
      .brief_imgCover:before {
        content: "";
        background-image: url(../img/svg/check.svg);
        transform: scale(1);
        background-size: cover;
        width: 20px;
        display: block;
        height: 20px;
        position: absolute;
        right: -5px;
        top: -5px; } } }

  &_imgCover {
    padding: 25px 0;
    background: rgba(0, 0, 0, 0.03);
    display: block;
    width: 100px;
    height: 100px; }

  &_label {
    display: block;
    font-size: 12px;
    margin-top: 6px; } }
