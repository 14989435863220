.mod-cases {
  .mod-title-section {
    max-width: 660px;
    .el-bg-text {
      bottom: auto;
      top: 80px;
      @media (max-width: $screen-xs-max) {
        top: 20px; } } } }

.case-section {
  display: inline-block;
  width: 100%;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 60px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 40px; } }
  .text-case {
    position: absolute;
    left: 0;
    top: 35px;
    @media (max-width: $screen-xs-max) {
      position: static; }

    .type,
    .name a,
    .text,
    .el-link {
      position: relative;
      z-index: 1; }

    .type {
      font-family: $got;
      font-size: 11px;
      font-weight: normal;
      line-height: 2.0;
      text-align: left;
      color: $black;
      white-space: nowrap;
      margin-bottom: 10px; }

    .name {
      font-family: $got;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.33;
      text-align: left;
      color: $black;
      white-space: nowrap;
      margin-bottom: 30px;
      @media (max-width: $screen-xs-max) {
        margin-bottom: 15px;
        font-size: 24px; }
      a:hover {
        text-decoration: none; }

      .el-bg-text {
        bottom: auto;
        top: 55px; } }

    .text {
      font-family: $got;
      font-size: 11px;
      font-weight: normal;
      line-height: 2.0;
      letter-spacing: -0.2px;
      text-align: left;
      color: $black;
      margin-bottom: 40px;
      @media (max-width: $screen-xs-max) {
        display: none; } }

    .el-link {
      margin-left: 6px;
      @media (max-width: $screen-xs-max) {
        display: none; } } }

  .image-case {
    float: right;
    max-width: 820px;
    display: inline-block;
    margin: 40px 0;
    @media (max-width: 1030px) {
      max-width: 720px; }
    @media (max-width: 930px) {
      max-width: 620px; }
    @media (max-width: 830px) {
      max-width: 550px; }
    @media (max-width: $screen-xs-max) {
      max-width: 100%;
      margin: 0; }

    &:hover {
      text-decoration: none;
      .img-wrap img {
        transform: scale3d(1.1,1.1,1); } }


    .img-wrap {
      opacity: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: contain;
      div.block-revealer__content {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
        overflow: hidden; }

      img {
        background-color: $text-bg;
        transition: transform 1s; } } }


  .line-wrap {
    display: inline-block;
    position: absolute;
    left: 180px;
    top: 0;
    pointer-events: none;
    width: 140px;
    height: 480px;
    @media (max-width: 1030px) {
      left: 280px;
      width: 100px;
      height: 435px; }
    @media (max-width: 930px) {
      height: 385px; }
    @media (max-width: 830px) {
      height: 350px;
      left: 223px; }
    @media (max-width: $screen-xs-max) {
      display: none; }

    .block-revealer__content {
      width: 100%;
      height: 100%;
      display: inline-block; } }

  .line {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: $sunshine-yellow; } }

.case-detail-content {
  padding-top: 80px;
  padding-bottom: 20px;
  @media (max-width: $screen-sm-max) {
    padding-top: 60px; }
  @media (max-width: $screen-xs-max) {
    padding-bottom: 0; }
  .list-contacts {
    .email {
      color: $black; }
    .el-link-sm:before {
      background-color: $warm-grey; } }
  .mod-col-fluid {
    @media (max-width: $screen-sm-max) {
      padding-left: 40px; }
    @media (max-width: $screen-xs-max) {
      padding-left: 7px; } }

  .content-section p:last-child {
    margin-bottom: 0; } }

.img-case {
  width: 100%;
  margin: 60px 0;
  @media (max-width: $screen-sm-max) {
    margin: 30px 0; } }

.mod-case-detail {
  display: block;
  padding-top: 100vh; }

.case-detail-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 110px 0 118px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  //pointer-events: none
  .lines {
    position: absolute;
    border-color: $black;
    &:before,
    &:after {
      border-color: $black; } }

  .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -2;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: -1;
      background-color: rgba($black, 0.6); } }

  h1,
  .h1 {
    font-family: $got;
    font-size: 120px;
    font-weight: bold;
    line-height: 0.75;
    letter-spacing: -3px;
    text-align: center;
    color: white;
    margin-bottom: 30px;
    @media (max-width: $screen-sm-max) {
      font-size: 100px; }
    @media (max-width: $screen-xs-max) {
      font-size: 60px; }
    @media (max-width: 560px) {
      font-size: 40px; } }

  .text-style-paragraph {
    margin: 0 auto;
    max-width: 320px;
    color: white;
    text-align: center; } }

.animated .img-case {
  opacity: 1; }

.case-detail-section-tech {
  padding: 40px 0; }

.tech-icons {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0 0;
  filter: invert(1);
  img {
    max-width: 120px;
    height: auto;
    @media (max-width: 600px) {
      max-width: 100px; }
    @media (max-width: 600px) {
      max-width: 80px; } } }

.text-justify {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 120px;
  flex-wrap: wrap;
  @media(max-width: 991px) {
    padding: 0 80px; }
  @media(max-width: 768px) {
    padding: 0 30px; }
  @media(max-width: 450px) {
    padding: 0 10px; }

  .link-fill {
    opacity: 1;
    transition: all 0.5s ease;
    @media(max-width: 768px) {
      margin: 10px 0; }
    @media(max-width: 450px) {
      order: 1;
      margin: 0 auto 20px; }
    &:hover {
      transform: translateY(10px); } } }

.list-contacts.mod-m-t {
  margin-top: 30px; }

.page-wrapper .navBtn {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #111;
  transition: all 0.5s ease;
  position: relative;
  @media(max-width: 991px) {
    font-size: 20px; }
  @media(max-width: 450px) {
    padding-left: 20px;
    order: 2;
    width: 50%;
    font-size: 14px;
    margin: 10px 0 70px; }
  &:after {
    content: '';
    border-bottom: 1px solid #90a1b5;
    border-right: 1px solid #90a1b5;
    display: block;
    height: 15px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: -40px;
    top: 50%;
    transition: all 0.15s ease-in-out;
    width: 15px;
    transform: rotate(-45deg);
    transition: all 0.5s ease;
    @media(max-width: 768px) {
      right: -30px; }
    @media(max-width: 450px) {
      right: -10px;
      height: 10px;
      width: 10px;
      margin-top: -4px; } }
  &:hover {
    text-decoration: none;
    opacity: 0.5; }
  &_next {
    text-align: right;
    @media(max-width: 450px) {
      padding-right: 20px; } }
  &_prev {
    text-align: left;
    &:after {
      left: -40px;
      transform: rotate(135deg);
      margin-top: -7px;
      @media(max-width: 768px) {
        left: -30px; }
      @media(max-width: 450px) {
        left: -10px;
        margin-top: -5px; } } } }
