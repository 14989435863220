.el-right-menu__list {
  position: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
  right: 47px;
  top: 50%;
  z-index: 1;
  @media (max-width: $screen-xs-max) {
    z-index: 3; }
  .el-right-menu__item {
    margin: 0;
    display: block; }
  .el-right-menu__link {
    font-family: $got;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 10px;
    padding: 10px 3px 10px 3px;
    color: $warm-grey;
    display: block;
    cursor: pointer;
    text-align: right;
    transition: transform 0.3s, color 0.3s;
    &:hover {
      color: $black;
      text-decoration: none;
      outline: none; }
    &:focus {
      text-decoration: none;
      outline: none; } }
  .is-active .el-right-menu__link {
    color: $black;
    transition: transform 0.3s, color 0.3s;
    display: block;
    transform: translateX(-6px);
    pointer-events: none; }
  .el-right-menu__line {
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 20px;
    pointer-events: none;
    background: $sunshine-yellow;
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22); }
  .el-right-menu__item {
    &:nth-child(1).is-active ~ .el-right-menu__line {
      transform: translateY(4px); }
    &:nth-child(2).is-active ~ .el-right-menu__line {
      transform: translateY(34px); }
    &:nth-child(3).is-active ~ .el-right-menu__line {
      transform: translateY(64px); }
    &:nth-child(4).is-active ~ .el-right-menu__line {
      transform: translateY(94px); }
    &:nth-child(5).is-active ~ .el-right-menu__line {
      transform: translateY(124px); }
    &:nth-child(6).is-active ~ .el-right-menu__line {
      transform: translateY(155px); } } }

.general-menu-content {
  cursor: none !important; }

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  @media (max-width: $screen-xs-max) {
    display: none; } }

.cursor--small {
  width: 5px;
  height: 5px;
  left: -2.5px;
  top: -2.5px;
  border-radius: 50%;
  z-index: 11000;
  background: #000; }
.cursor--canvas {
  width: 100vw;
  height: 100vh;
  z-index: 12000; }
.video-start {
  .circle-cursor {
    &--outer, &--inner {
      z-index: 9; } } }
.circle-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%;
  @media (max-width: $screen-xs-max) {
    display: none; }
  &--outer {
    width: 30px;
    height: 30px;
    border: 1px solid #000;
    z-index: 12000;
    opacity: 0.2; }

  &--inner {
    width: 5px;
    height: 5px;
    left: -2.5px;
    top: -2.5px;
    z-index: 11000;
    background: #000; } }



.page-wrapper {
  cursor: none;
  @media (max-width: $screen-xs-max) {
    cursor: default; }
  .el-btn {
    @media (max-width: $screen-xs-max) {
      font-size: 14px; } }
  a {
    cursor: none;
    @media (max-width: $screen-xs-max) {
      cursor: default;
      letter-spacing: -0.5px;
      font-size: 11px; } }
  button {
    cursor: none;
    @media (max-width: $screen-xs-max) {
      cursor: default; } } }


.chat-widget .input-container {
  padding-top: 8px!important; }

.chat-widget .text-center.m-bottom-2 {
  display: none!important; }

#hubspot-messages-iframe-container {
  z-index: 99!important; }

