// footer
//-----------------------------------------------------------------------------------

.social {
  @extend %clear-fix;
  display: inline-block;
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 3;
  font-size: 0;
  transition: opacity 0.2s;
  @media (max-width: $screen-md-max) {
    display: none;
    bottom: auto;
    right: 235px;
    top: 50px; }
  @media (max-width: $screen-xs-max) {
    right: 15px;
    bottom: 12px;
    display: none; }

  &.hovered {
    li a {
      color: $warm-grey;
      &:hover {
        color: $black; } } }
  li {
    float: left;
    display: inline-block;
    &:not(:last-child) {
      margin-right: 20px; }
    a {
      display: inline-block;
      transition: color 0.2s; } } }

