@charset "UTF-8";
@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro.eot");
  src: url("../fonts/GothamPro.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro.woff") format("woff"), url("../fonts/GothamPro.ttf") format("truetype"), url("../fonts/GothamPro.svg#GothamPro") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro-Medium.eot");
  src: url("../fonts/GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Medium.woff") format("woff"), url("../fonts/GothamPro-Medium.ttf") format("truetype"), url("../fonts/GothamPro-Medium.svg#GothamPro-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "GothamPro";
  src: url("../fonts/GothamPro-Bold.eot");
  src: url("../fonts/GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Bold.woff") format("woff"), url("../fonts/GothamPro-Bold.ttf") format("truetype"), url("../fonts/GothamPro-Bold.svg#GothamPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

blockquote, q {
  quotes: none; }
  blockquote:before, blockquote:after, q:before, q:after {
    content: ''; }

input:focus, textarea:focus, a:focus, button:focus, select:focus {
  outline: 0; }

ins {
  text-decoration: none; }

del, s, strike {
  text-decoration: line-through; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

article, aside, details, figcaption, figure, fieldset, footer, header, hgroup, main, nav, section, summary {
  display: block;
  margin: 0; }

ul, ol {
  list-style: none; }

body, input, button, select, textarea {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif; }

body {
  font-size: 16px;
  color: #111111; }

.clear {
  clear: both; }

.container:after, .container-fluid:after, .row:after, .el-file-input:after, .header .lang:after, .social:after, .general-menu-content .lang:after, .modal form:after, .video-player-youtube .video-controls:after, .mod-home-first .el-right-menu__list:after, .mod-expertise .el-right-menu__list:after, .mod-policy .el-right-menu__list:after, .content-section:after, .list-number:after, .list-services:after,
.list-vacancies:after, .list-clients:after, .list-contacts:after, .services-section:after {
  content: '';
  clear: both;
  display: table;
  width: 100%; }

.fl {
  float: left; }

.fr {
  float: right; }

.text-left {
  text-align: left; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

pre, code {
  font-family: monospace; }

a {
  color: inherit;
  outline: none;
  text-decoration: none; }
  a:hover {
    text-decoration: underline; }

img {
  position: relative;
  display: inline-block;
  border: none;
  vertical-align: top;
  max-width: 100%;
  height: auto;
  font: 300 12px/2 Arial, "Helvetica Neue", Helvetica, sans-serif;
  text-align: center; }
  img:after {
    content: "Broken Image of " attr(alt);
    display: block;
    color: #646464;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff; }

input {
  vertical-align: middle;
  margin: 0;
  padding: 0; }
  input::-ms-clear, input::-ms-reveal {
    display: none;
    width: 0;
    height: 0; }

button,
a.button,
input[type='reset'],
input[type='submit'],
input[type='button'],
input[type='image'] {
  display: inline-block;
  width: auto;
  margin: 0;
  padding: 0;
  overflow: visible;
  background: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  text-decoration: none; }

::selection {
  background: dodgerblue; }

.no-flexbox body {
  min-height: 100%;
  display: block !important; }

.page-wrapper,
.footer {
  max-width: none;
  min-width: 0;
  width: 100%;
  margin: 0 auto; }
  .no-flexbox .page-wrapper, .no-flexbox
  .footer {
    width: auto; }

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%; }
  @media (max-width: 768px) {
    .page-wrapper {
      min-height: calc(100vh - 70px); } }
  .lte-ie-11 .page-wrapper,
  .lt-ie-11 .page-wrapper {
    min-height: 0; }
  .no-flexbox .page-wrapper {
    display: block !important;
    width: auto;
    min-height: 100%;
    height: auto !important;
    height: 100%; }

.page-body {
  flex: 1; }
  .page-body.flex-none {
    flex: none; }

.wrapper {
  position: relative; }

* {
  box-sizing: border-box; }
  *:before, *:after {
    box-sizing: border-box; }

.page-wrapper {
  cursor: none; }

.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  fill: currentColor; }

.icon-at {
  width: 1em; }

.icon-at-bold {
  width: 1em; }

.icon-attach {
  width: 1.03093em; }

.icon-close {
  width: 1em; }

.icon-color {
  width: 0.78384em; }

.icon-cookie {
  width: 1em; }

.icon-fb {
  width: 0.5em; }

.icon-insta {
  width: 1em; }

.icon-lin {
  width: 1.05584em; }

.icon-play {
  width: 0.84725em; }

.icon-send {
  width: 1em; }

.icon-timer {
  width: 1em; }

.icon-tw {
  width: 1.2093em; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px; }

.container {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .container {
      width: 750px; } }
  @media (min-width: 992px) {
    .container {
      width: 970px; } }
  @media (min-width: 1230px) {
    .container {
      width: 1200px; } }

.container-fluid {
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px; }

.row {
  margin-left: -15px;
  margin-right: -15px; }

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box; }

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left; }

.col-xs-1 {
  width: 8.33333%; }

.col-xs-2 {
  width: 16.66667%; }

.col-xs-3 {
  width: 25%; }

.col-xs-4 {
  width: 33.33333%; }

.col-xs-5 {
  width: 41.66667%; }

.col-xs-6 {
  width: 50%; }

.col-xs-7 {
  width: 58.33333%; }

.col-xs-8 {
  width: 66.66667%; }

.col-xs-9 {
  width: 75%; }

.col-xs-10 {
  width: 83.33333%; }

.col-xs-11 {
  width: 91.66667%; }

.col-xs-12 {
  width: 100%; }

.col-xs-offset-0 {
  margin-left: 0%; }

.col-xs-offset-1 {
  margin-left: 8.33333%; }

.col-xs-offset-2 {
  margin-left: 16.66667%; }

.col-xs-offset-3 {
  margin-left: 25%; }

.col-xs-offset-4 {
  margin-left: 33.33333%; }

.col-xs-offset-5 {
  margin-left: 41.66667%; }

.col-xs-offset-6 {
  margin-left: 50%; }

.col-xs-offset-7 {
  margin-left: 58.33333%; }

.col-xs-offset-8 {
  margin-left: 66.66667%; }

.col-xs-offset-9 {
  margin-left: 75%; }

.col-xs-offset-10 {
  margin-left: 83.33333%; }

.col-xs-offset-11 {
  margin-left: 91.66667%; }

.col-xs-offset-12 {
  margin-left: 100%; }

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left; }
  .col-sm-1 {
    width: 8.33333%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-12 {
    width: 100%; }
  .col-sm-offset-0 {
    margin-left: 0%; }
  .col-sm-offset-1 {
    margin-left: 8.33333%; }
  .col-sm-offset-2 {
    margin-left: 16.66667%; }
  .col-sm-offset-3 {
    margin-left: 25%; }
  .col-sm-offset-4 {
    margin-left: 33.33333%; }
  .col-sm-offset-5 {
    margin-left: 41.66667%; }
  .col-sm-offset-6 {
    margin-left: 50%; }
  .col-sm-offset-7 {
    margin-left: 58.33333%; }
  .col-sm-offset-8 {
    margin-left: 66.66667%; }
  .col-sm-offset-9 {
    margin-left: 75%; }
  .col-sm-offset-10 {
    margin-left: 83.33333%; }
  .col-sm-offset-11 {
    margin-left: 91.66667%; }
  .col-sm-offset-12 {
    margin-left: 100%; } }

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left; }
  .col-md-1 {
    width: 8.33333%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-3 {
    width: 25%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-6 {
    width: 50%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-9 {
    width: 75%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-12 {
    width: 100%; }
  .col-md-offset-0 {
    margin-left: 0%; }
  .col-md-offset-1 {
    margin-left: 8.33333%; }
  .col-md-offset-2 {
    margin-left: 16.66667%; }
  .col-md-offset-3 {
    margin-left: 25%; }
  .col-md-offset-4 {
    margin-left: 33.33333%; }
  .col-md-offset-5 {
    margin-left: 41.66667%; }
  .col-md-offset-6 {
    margin-left: 50%; }
  .col-md-offset-7 {
    margin-left: 58.33333%; }
  .col-md-offset-8 {
    margin-left: 66.66667%; }
  .col-md-offset-9 {
    margin-left: 75%; }
  .col-md-offset-10 {
    margin-left: 83.33333%; }
  .col-md-offset-11 {
    margin-left: 91.66667%; }
  .col-md-offset-12 {
    margin-left: 100%; } }

@media (min-width: 1230px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left; }
  .col-lg-1 {
    width: 8.33333%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-12 {
    width: 100%; }
  .col-lg-offset-0 {
    margin-left: 0%; }
  .col-lg-offset-1 {
    margin-left: 8.33333%; }
  .col-lg-offset-2 {
    margin-left: 16.66667%; }
  .col-lg-offset-3 {
    margin-left: 25%; }
  .col-lg-offset-4 {
    margin-left: 33.33333%; }
  .col-lg-offset-5 {
    margin-left: 41.66667%; }
  .col-lg-offset-6 {
    margin-left: 50%; }
  .col-lg-offset-7 {
    margin-left: 58.33333%; }
  .col-lg-offset-8 {
    margin-left: 66.66667%; }
  .col-lg-offset-9 {
    margin-left: 75%; }
  .col-lg-offset-10 {
    margin-left: 83.33333%; }
  .col-lg-offset-11 {
    margin-left: 91.66667%; }
  .col-lg-offset-12 {
    margin-left: 100%; } }

.text-xs-left {
  text-align: left; }

.text-xs-right {
  text-align: right; }

.text-xs-center {
  text-align: center; }

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left; }
  .text-sm-right {
    text-align: right; }
  .text-sm-center {
    text-align: center; } }

@media (min-width: 992px) {
  .text-md-left {
    text-align: left; }
  .text-md-right {
    text-align: right; }
  .text-md-center {
    text-align: center; } }

@media (min-width: 1230px) {
  .text-lg-left {
    text-align: left; }
  .text-lg-right {
    text-align: right; }
  .text-lg-center {
    text-align: center; } }

body h1::selection, body h2::selection, body h3::selection, body h4::selection, body h5::selection, body h6::selection, body p::selection, body p span::selection, body h1 div::selection, body h2 div::selection, body h3 div::selection, body h4 div::selection, body h5 div::selection, body h6 div::selection, body a::selection, body a span::selection, body b::selection, body button::selection, body blockquote::selection, body .number::selection, body .text-number::selection, body .el-bg-text::selection, body .block-revealer__content::selection, body .block-revealer__content span::selection, body .type::selection, body .title::selection, body .modal-title::selection, body img::selection, body .brief_titles::selection, body .brief_label::selection, body .form_title::selection, body .form_title span::selection, body .form_label::selection, body .form_subtitle::selection, body .labe-file::selection, body .progressbar_item::selection, body .contacts-item::selection, body .form_titleLoad::selection, body .prices_featureTitle::selection, body .prices-page .prices_list li::selection {
  background: #dbdbdb; }

body h1::-moz-selection, body h2::-moz-selection, body h3::-moz-selection, body h4::-moz-selection, body h5::-moz-selection, body h6::-moz-selection, body p::-moz-selection, body p span::-moz-selection, body h1 div::-moz-selection, body h2 div::-moz-selection, body h3 div::-moz-selection, body h4 div::-moz-selection, body h5 div::-moz-selection, body h6 div::-moz-selection, body a::-moz-selection, body a span::-moz-selection, body b::-moz-selection, body button::-moz-selection, body blockquote::-moz-selection, body .number::-moz-selection, body .text-number::-moz-selection, body .el-bg-text::-moz-selection, body .block-revealer__content::-moz-selection, body .block-revealer__content span::-moz-selection, body .type::-moz-selection, body .title::-moz-selection, body .modal-title::-moz-selection, body img::-moz-selection, body .brief_titles::-moz-selection, body .brief_label::-moz-selection, body .form_title::-moz-selection, body .form_title span::-moz-selection, body .form_label::-moz-selection, body .form_subtitle::-moz-selection, body .labe-file::-moz-selection, body .progressbar_item::-moz-selection, body .contacts-item::-moz-selection, body .form_titleLoad::-moz-selection, body .prices_featureTitle::-moz-selection, body .prices-page .prices_list li::-moz-selection {
  background: #dbdbdb; }

::selection {
  background: #dbdbdb; }

::-moz-selection {
  background: #dbdbdb; }

.page-body {
  padding: 110px 0 118px;
  display: flex;
  justify-content: center; }
  @media (max-width: 1229px) {
    .page-body {
      padding: 110px 0 40px; } }
  @media (max-width: 767px) {
    .page-body {
      display: block;
      padding: 72px 0 34px; } }
  .page-body .container {
    max-width: 990px;
    width: 100%; }
    @media (max-width: 1060px) {
      .page-body .container {
        max-width: 100%;
        padding: 0 50px; } }
    @media (max-width: 767px) {
      .page-body .container {
        padding: 0 15px; } }

.container-center {
  align-self: center; }

.lines {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  width: 960px;
  height: 100%;
  margin: 0 auto;
  pointer-events: none;
  border-right: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2; }
  @media (max-width: 1060px) {
    .lines {
      width: calc(100% - 100px);
      margin: 0 50px; } }
  @media (max-width: 767px) {
    .lines {
      display: none; } }
  .lines:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 320px;
    height: 100%;
    margin: 0 auto;
    content: "";
    pointer-events: none;
    border-right: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2; }
    @media (max-width: 1060px) {
      .lines:before {
        width: calc(100% / 3); } }

@media (max-width: 767px) {
  .mob-br {
    display: none; } }

.el-link {
  position: relative;
  display: inline-block;
  transition: transform 0.5s, color 0.5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transform: translate3d(-0.5em, 0, 0);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 4.29;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000; }
  .el-link::before {
    position: absolute;
    z-index: 0;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 3px;
    content: "";
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transform: scaleX(0.4);
    transform-origin: 100% 100%;
    background: #ffff32; }
  .el-link:hover, .el-link:focus {
    transform: translate3d(0, 0, 0);
    color: #111;
    text-decoration: none; }
  .el-link:hover:before {
    transition-timing-function: ease;
    transform: scaleX(1); }

.el-link-sm {
  position: relative;
  display: inline-block;
  transition: transform 0.5s, color 0.5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  text-decoration: none;
  color: #000000;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 500; }
  .el-link-sm::before {
    position: absolute;
    z-index: 0;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transform: scaleX(0);
    transform-origin: 100% 50%;
    background: #949494; }
  .el-link-sm:hover, .el-link-sm:focus {
    text-decoration: none;
    color: #000000; }
  .el-link-sm:hover:before {
    transition-timing-function: ease;
    transform: scaleX(1);
    transform-origin: 0 50%; }

.page-open .btn-menu:before, .page-open .btn-menu:after {
  transform: scaleX(0);
  transform-origin: 0% 50%;
  transition: transform 0.2s; }
  @media (max-width: 1229px) {
    .page-open .btn-menu:before, .page-open .btn-menu:after {
      transform: scaleX(1); } }

.page-open .btn-menu:hover:before, .page-open .btn-menu:hover:after {
  transform: scaleX(1); }

.page-open .btn-menu:hover .menu-number {
  color: #ffff32; }

.page-open .btn-menu:before, .page-open .btn-menu:after, .page-open .btn-menu .line {
  background: #000000; }
  @media (max-width: 1229px) {
    .page-open .btn-menu:before, .page-open .btn-menu:after, .page-open .btn-menu .line {
      background: #000000; } }

.page-open .btn-menu .line {
  left: -25px;
  width: 85px; }
  @media (max-width: 1229px) {
    .page-open .btn-menu .line {
      left: auto;
      width: 25px; } }

.page-open .btn-menu .title-page {
  visibility: visible; }

.page-open .btn-menu .title {
  opacity: 1;
  visibility: visible; }

.btn-menu {
  position: fixed;
  top: 50%;
  left: 25px;
  display: inline-block;
  text-decoration: none;
  width: 25px;
  height: 13px;
  margin-top: -10px;
  z-index: 3; }
  .btn-menu:hover {
    cursor: pointer; }
    @media (min-width: 1230px) {
      .btn-menu:hover .line {
        left: -25px;
        width: 85px; } }
    .btn-menu:hover:before, .btn-menu:hover:after, .btn-menu:hover .line {
      background: #949494; }
    @media (min-width: 1230px) {
      .btn-menu:hover .title-page {
        visibility: visible; } }
    @media (min-width: 1230px) {
      .btn-menu:hover .title {
        transform: translateX(15px);
        opacity: 1;
        visibility: visible; } }
  @media (max-width: 1229px) {
    .btn-menu {
      top: 58px;
      right: 50px;
      left: auto;
      transform: translateY(0); } }
  @media (max-width: 767px) {
    .btn-menu {
      top: 35px;
      right: 20px; } }
  .btn-menu:before, .btn-menu:after {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 25px;
    height: 1px;
    content: "";
    background: #000000; }
    @media (max-width: 1229px) {
      .btn-menu:before, .btn-menu:after {
        right: 0;
        left: auto; } }
  .btn-menu:before {
    top: 0; }
  .btn-menu:after {
    bottom: 0; }
  .btn-menu .line {
    position: absolute;
    top: 6px;
    left: 0;
    display: inline-block;
    width: 25px;
    height: 1px;
    margin-top: 0px;
    background: #000000;
    transition: all 0.2s; }
    @media (max-width: 1229px) {
      .btn-menu .line {
        right: 0;
        left: auto;
        top: 6px;
        margin-top: 0; } }

.title-page {
  display: none; }
  @media (min-width: 1230px) {
    .title-page {
      position: absolute;
      display: inline-block;
      left: 25px;
      top: 50%;
      transform: translateY(-50%);
      visibility: hidden;
      z-index: -1; } }
  .title-page .title {
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    position: absolute;
    display: inline-block;
    top: 20px;
    transition: transform 0.3s, opacity 0.2s;
    opacity: 0;
    visibility: hidden; }
  .title-page .menu-number {
    display: inline-block;
    pointer-events: none;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 70px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: -2px;
    color: rgba(0, 0, 0, 0.03);
    padding-left: 15px;
    padding-top: 10px;
    transition: color 0.2s; }

.el-btn {
  display: inline-block;
  text-transform: uppercase;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-weight: bold;
  width: 280px;
  text-align: center;
  background-color: #ffff32;
  color: #000000;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 10px 35px -15px rgba(255, 255, 50, 0.8);
  transition: all .2s;
  text-decoration: none; }
  @media (max-width: 991px) {
    .el-btn {
      font-size: 14px;
      height: 50px;
      line-height: 50px;
      max-width: 220px; } }
  .el-btn:hover {
    text-decoration: none; }
  .el-btn.mod-hover:hover {
    text-decoration: none;
    transform: translateY(5px);
    box-shadow: 0 6px 20px -10px rgba(255, 255, 50, 0.8); }
  .el-btn.mod-who-we-are {
    background-color: #f7f7f7;
    box-shadow: 0 10px 35px -15px rgba(247, 247, 247, 0.8); }
    .el-btn.mod-who-we-are:hover {
      box-shadow: 0 6px 20px -10px rgba(247, 247, 247, 0.8);
      text-decoration: none;
      transform: translateY(5px); }

.close {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 21px;
  height: 21px;
  display: inline-block;
  z-index: 1; }
  @media (max-width: 767px) {
    .close {
      transform: translateY(0);
      top: 22px;
      right: 20px; } }
  .close:hover {
    cursor: pointer; }
  .close .icon {
    font-size: 21px;
    color: #000000;
    pointer-events: none; }

.el-bg-text {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 150px;
  font-weight: bold;
  letter-spacing: 2.5px;
  text-align: left;
  color: rgba(0, 0, 0, 0.03);
  position: absolute;
  z-index: -1;
  bottom: -140px;
  left: 85px;
  line-height: 1;
  white-space: nowrap;
  pointer-events: none;
  width: 100%; }
  @media (max-width: 991px) {
    .el-bg-text {
      font-size: 100px; } }
  @media (max-width: 767px) {
    .el-bg-text {
      bottom: -90px;
      left: 60px;
      font-size: 70px; } }

.mod-col-fluid {
  float: left;
  width: 100%;
  padding-left: 141px; }

.el-input {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  vertical-align: top;
  height: 60px; }
  .el-input:not(:last-child) {
    margin-bottom: 12px; }
  @media (max-width: 991px) {
    .el-input {
      margin: 0;
      width: 100%; } }
  .el-input.error .graphic {
    stroke: red; }
  .el-input.error .error {
    position: absolute;
    bottom: 4px;
    font-size: 10px;
    right: 0;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    text-align: right;
    color: red; }
  .el-input .graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
    transition: transform 0.7s, stroke 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    pointer-events: none;
    stroke: rgba(255, 255, 255, 0.2); }

.el-input__field {
  position: relative;
  display: block;
  float: right;
  width: 100%;
  margin-bottom: 10px;
  padding: 26px 0 8px;
  border: none;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  color: white; }
  .el-input__field:focus {
    outline: none; }

.el-input__label {
  position: absolute;
  top: 20px;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  user-select: none;
  transition: transform 0.2s 0.15s, color 1s;
  transition-timing-function: ease-out;
  transform-origin: 0 0;
  text-align: left;
  pointer-events: none;
  -khtml-user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  color: white; }

.el-input__label-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0; }

.el-input__field:focus + .el-input__label, .el-input--filled .el-input__label {
  transform: translate3d(0, -20px, 0) scale3d(0.75, 0.75, 1);
  color: #949494; }

.el-input__field:focus ~ .graphic, .el-input--filled .graphic {
  transform: translate3d(-66.6%, 0, 0);
  stroke: white; }

.el-textarea {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  vertical-align: top;
  height: 204px; }
  .el-textarea:not(:last-child) {
    margin-bottom: 12px; }
  @media (max-width: 991px) {
    .el-textarea {
      margin: 0;
      width: 100%;
      height: 130px; } }
  @media (max-width: 767px) {
    .el-textarea {
      margin-top: 12px; } }
  .el-textarea.error .graphic {
    stroke: red; }
  .el-textarea.error .error {
    position: absolute;
    bottom: 4px;
    font-size: 10px;
    right: 0;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    text-align: right;
    color: red; }
  .el-textarea .graphic {
    position: absolute;
    bottom: 0;
    left: 0;
    fill: none;
    transition: transform 0.7s, stroke 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    pointer-events: none;
    stroke: rgba(255, 255, 255, 0.2); }

.el-textarea__field {
  position: relative;
  display: block;
  float: right;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 22px;
  padding: 0 0 8px;
  border: none;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: white;
  resize: none;
  height: calc(100% - 40px);
  overflow: hidden; }
  .el-textarea__field:focus {
    outline: none; }

.el-textarea__label {
  position: absolute;
  top: 20px;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  user-select: none;
  transition: transform 0.2s 0.15s, color 1s;
  transition-timing-function: ease-out;
  transform-origin: 0 0;
  text-align: left;
  pointer-events: none;
  -khtml-user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  color: white; }

.el-textarea__label-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0; }

.el-textarea__field:focus + .el-textarea__label, .el-textarea--filled .el-textarea__label {
  transform: translate3d(0, -20px, 0) scale3d(0.75, 0.75, 1);
  color: #949494; }

.el-textarea__field:focus ~ .graphic, .el-textarea--filled .graphic {
  transform: translate3d(-66.6%, 0, 0);
  stroke: white; }

.el-file-input {
  display: inline-block;
  text-align: left;
  background: transparent;
  padding: 15px;
  width: 100%;
  position: relative; }
  @media (max-width: 767px) {
    .el-file-input {
      padding: 10px; } }
  .el-file-input.error .error {
    position: absolute;
    bottom: -5px;
    font-size: 10px;
    right: 10px;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    text-align: right;
    color: red; }
  .el-file-input > [type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer; }
  .el-file-input .close {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s; }
  .el-file-input .button {
    float: left;
    display: inline-block; }
  .el-file-input:hover > .button {
    color: white; }
  .el-file-input .icon {
    color: white;
    font-size: 30px;
    transition: color 0.2s; }
  .el-file-input > .label {
    float: left;
    margin-left: 18px;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 2px;
    text-align: left;
    color: white;
    width: calc(100% - 50px);
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 8px; }
    @media (max-width: 767px) {
      .el-file-input > .label {
        display: none; } }
  .el-file-input.mod-chosen .close {
    opacity: 1;
    visibility: visible; }
  .el-file-input.mod-chosen .icon {
    color: #ffff32; }
  .el-file-input.mod-chosen > .label {
    font-size: 14px; }

.mod-home-first .mouse, .mod-expertise .mouse {
  position: fixed;
  z-index: 1;
  pointer-events: none; }
  @media (max-width: 768px) {
    .mod-home-first .mouse, .mod-expertise .mouse {
      display: none; } }
  .mod-home-first .mouse svg .line, .mod-expertise .mouse svg .line {
    stroke: #000000; }
  .mod-home-first .mouse svg path, .mod-expertise .mouse svg path {
    fill: #000000; }

.mouse {
  display: inline-block;
  width: 20px;
  height: 30px;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto; }
  @media (max-width: 1200px) {
    .mouse {
      bottom: 30px; } }
  @media (max-width: 768px) {
    .mouse {
      pointer-events: none; } }
  .mouse:hover {
    text-decoration: none; }
    .mouse:hover svg .line {
      stroke: #ffff32; }
    .mouse:hover svg path {
      fill: #ffff32; }
  .mouse svg .line {
    stroke: #FFFFFF;
    stroke-width: 0.4;
    animation: mouse 2s infinite;
    transform: translateY(0); }
  .mouse svg path {
    fill: #FFFFFF;
    transition: all 0.2s; }

@keyframes mouse {
  0% {
    transform: translateY(0); }
  50% {
    transform: translateY(5px); }
  75% {
    transform: translateY(0px); } }

.progress-button.state-loading .progress-inner {
  animation: progress 3s forwards; }

@keyframes progress {
  0% {
    width: 0; }
  100% {
    width: 100%; } }

.el-bg-text-h1-hide {
  position: absolute;
  left: 0;
  top: 0;
  color: rgba(0, 0, 0, 0);
  pointer-events: none; }

@media (max-width: 1229px) {
  .header {
    position: fixed;
    background: white;
    left: 0;
    top: 0;
    width: 100%;
    height: 85px;
    z-index: 4; }
    .header:before {
      display: none;
      height: 10px;
      content: '';
      position: absolute;
      top: 84px;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0)); } }

@media (max-width: 767px) {
  .header {
    height: 64px; }
    .header:before {
      display: block;
      height: 10px;
      top: 63px; } }

@media (max-width: 767px) {
  .header .email {
    display: none; } }

.header .lines {
  display: none;
  position: absolute; }
  @media (max-width: 1229px) {
    .header .lines {
      display: block; } }
  @media (max-width: 767px) {
    .header .lines {
      display: none; } }

.header .lang {
  display: inline-block;
  position: fixed;
  z-index: 1;
  font-size: 0;
  top: 50px;
  right: 210px; }
  @media (max-width: 1229px) {
    .header .lang {
      right: 260px; } }
  @media (max-width: 767px) {
    .header .lang {
      top: 27px;
      right: 70px; } }
  .header .lang li {
    float: left; }
    .header .lang li:not(:last-child) {
      margin-right: 10px; }
    .header .lang li.is-active a {
      color: #000000; }
    .header .lang li a {
      color: #949494;
      transition: all 0.2s;
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.2px;
      text-transform: uppercase; }
      .header .lang li a:hover, .header .lang li a:focus {
        text-decoration: none;
        color: #000000; }

.logo {
  display: inline-block;
  position: fixed;
  top: 33px;
  left: 50px;
  width: 90px;
  height: 45px;
  z-index: 3; }
  @media (max-width: 767px) {
    .logo {
      width: 70px;
      height: 35px;
      top: 15px;
      left: 15px; } }
  .logo:hover {
    text-decoration: none; }
  @media (max-width: 767px) {
    .logo svg {
      width: 70px;
      height: 35px; } }
  .logo svg .bg {
    fill: #ffff32; }
  .logo svg .jet {
    fill: #000000; }
  .logo svg .up {
    fill: #000000; }

.email {
  position: fixed;
  top: 50px;
  right: 50px;
  font-size: 0;
  z-index: 3; }
  @media (max-width: 1229px) {
    .email {
      right: 105px; } }
  .email span {
    display: inline-block;
    font-size: 12px; }
  .email .icon {
    display: inline-block;
    font-size: 16px;
    margin: -7px 1px 0 0;
    padding: 0 1px;
    stroke: #000;
    stroke-width: 0.1px; }

.social {
  display: inline-block;
  position: fixed;
  bottom: 50px;
  left: 50px;
  z-index: 3;
  font-size: 0;
  transition: opacity 0.2s; }
  @media (max-width: 1229px) {
    .social {
      display: none;
      bottom: auto;
      right: 235px;
      top: 50px; } }
  @media (max-width: 767px) {
    .social {
      right: 15px;
      bottom: 12px;
      display: none; } }
  .social.hovered li a {
    color: #949494; }
    .social.hovered li a:hover {
      color: #000000; }
  .social li {
    float: left;
    display: inline-block; }
    .social li:not(:last-child) {
      margin-right: 20px; }
    .social li a {
      display: inline-block;
      transition: color 0.2s; }

.el-right-menu__list {
  position: fixed;
  margin: 0;
  padding: 0;
  list-style: none;
  right: 47px;
  top: 50%;
  z-index: 1; }
  @media (max-width: 767px) {
    .el-right-menu__list {
      z-index: 3; } }
  .el-right-menu__list .el-right-menu__item {
    margin: 0;
    display: block; }
  .el-right-menu__list .el-right-menu__link {
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.2px;
    line-height: 10px;
    padding: 10px 3px 10px 3px;
    color: #949494;
    display: block;
    cursor: pointer;
    text-align: right;
    transition: transform 0.3s, color 0.3s; }
    .el-right-menu__list .el-right-menu__link:hover {
      color: #000000;
      text-decoration: none;
      outline: none; }
    .el-right-menu__list .el-right-menu__link:focus {
      text-decoration: none;
      outline: none; }
  .el-right-menu__list .is-active .el-right-menu__link {
    color: #000000;
    transition: transform 0.3s, color 0.3s;
    display: block;
    transform: translateX(-6px);
    pointer-events: none; }
  .el-right-menu__list .el-right-menu__line {
    position: absolute;
    top: 0;
    right: 0;
    width: 3px;
    height: 20px;
    pointer-events: none;
    background: #ffff32;
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22); }
  .el-right-menu__list .el-right-menu__item:nth-child(1).is-active ~ .el-right-menu__line {
    transform: translateY(4px); }
  .el-right-menu__list .el-right-menu__item:nth-child(2).is-active ~ .el-right-menu__line {
    transform: translateY(34px); }
  .el-right-menu__list .el-right-menu__item:nth-child(3).is-active ~ .el-right-menu__line {
    transform: translateY(64px); }
  .el-right-menu__list .el-right-menu__item:nth-child(4).is-active ~ .el-right-menu__line {
    transform: translateY(94px); }
  .el-right-menu__list .el-right-menu__item:nth-child(5).is-active ~ .el-right-menu__line {
    transform: translateY(124px); }
  .el-right-menu__list .el-right-menu__item:nth-child(6).is-active ~ .el-right-menu__line {
    transform: translateY(155px); }

.general-menu-content {
  cursor: none !important; }

.cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none; }
  @media (max-width: 767px) {
    .cursor {
      display: none; } }

.cursor--small {
  width: 5px;
  height: 5px;
  left: -2.5px;
  top: -2.5px;
  border-radius: 50%;
  z-index: 11000;
  background: #000; }

.cursor--canvas {
  width: 100vw;
  height: 100vh;
  z-index: 12000; }

.video-start .circle-cursor--outer, .video-start .circle-cursor--inner {
  z-index: 9; }

.circle-cursor {
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  border-radius: 50%; }
  @media (max-width: 767px) {
    .circle-cursor {
      display: none; } }
  .circle-cursor--outer {
    width: 30px;
    height: 30px;
    border: 1px solid #000;
    z-index: 12000;
    opacity: 0.2; }
  .circle-cursor--inner {
    width: 5px;
    height: 5px;
    left: -2.5px;
    top: -2.5px;
    z-index: 11000;
    background: #000; }

.page-wrapper {
  cursor: none; }
  @media (max-width: 767px) {
    .page-wrapper {
      cursor: default; } }
  @media (max-width: 767px) {
    .page-wrapper .el-btn {
      font-size: 14px; } }
  .page-wrapper a {
    cursor: none; }
    @media (max-width: 767px) {
      .page-wrapper a {
        cursor: default;
        letter-spacing: -0.5px;
        font-size: 11px; } }
  .page-wrapper button {
    cursor: none; }
    @media (max-width: 767px) {
      .page-wrapper button {
        cursor: default; } }

.chat-widget .input-container {
  padding-top: 8px !important; }

.chat-widget .text-center.m-bottom-2 {
  display: none !important; }

#hubspot-messages-iframe-container {
  z-index: 99 !important; }

body.general-menu-opened {
  overflow: hidden; }
  body.general-menu-opened .general-menu {
    transform: scaleX(1); }

.general-menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background: transparent;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 0% 50% 0px;
  transform: scaleX(0); }
  .general-menu:hover {
    cursor: pointer; }

.general-menu-wrap {
  height: 100%;
  width: 100%;
  background-color: #000000;
  position: relative;
  overflow: hidden; }
  @media (max-width: 1229px) {
    .general-menu-wrap {
      width: 100%; } }

.general-menu-content {
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .general-menu-content:hover {
    cursor: default; }
  .general-menu-content:before {
    content: 'JetUp';
    pointer-events: none;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 200px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 4px;
    text-align: right;
    color: #181818;
    position: absolute;
    width: 960px;
    left: 0;
    right: 0;
    top: 180px;
    margin: 0 auto;
    z-index: 0; }
    @media (max-width: 767px) {
      .general-menu-content:before {
        width: 768px;
        font-size: 100px;
        text-align: left;
        left: 130px; } }
    @media (max-width: 480px) {
      .general-menu-content:before {
        left: 25px;
        font-size: 88px;
        bottom: 90px;
        top: auto; } }
  .general-menu-content .social {
    position: absolute;
    z-index: 101;
    right: auto;
    left: 50px;
    bottom: 50px;
    min-width: 240px;
    top: auto; }
    @media (max-width: 1229px) {
      .general-menu-content .social {
        display: block; } }
    @media (max-width: 767px) {
      .general-menu-content .social {
        right: auto;
        left: 50%;
        display: block;
        bottom: 30px;
        transform: translateX(-50%); } }
    @media (max-width: 767px) {
      .general-menu-content .social li:not(:last-child) {
        margin-right: 15px; } }
    .general-menu-content .social li a {
      color: white; }
    .general-menu-content .social.hovered li a {
      color: #949494; }
      .general-menu-content .social.hovered li a:hover {
        color: white; }
  .general-menu-content .close {
    top: 50%;
    margin-top: -10px; }
    @media (max-width: 1229px) {
      .general-menu-content .close {
        top: 55px;
        right: 50px;
        left: auto;
        transform: translateY(0); } }
    @media (max-width: 767px) {
      .general-menu-content .close {
        top: 32px;
        right: 20px; } }
    .general-menu-content .close .icon {
      color: white;
      transition: color 0.2s; }
    .general-menu-content .close:hover .icon {
      color: #ffff32; }
  .general-menu-content .lines {
    position: absolute;
    z-index: 0;
    opacity: 0.1;
    border-color: #f2f2f2; }
  .general-menu-content .sound {
    position: absolute;
    right: 40px;
    bottom: 40px;
    left: auto;
    padding: 10px; }
    .general-menu-content .sound:before, .general-menu-content .sound:after {
      display: none; }
    .general-menu-content .sound .line {
      background: white; }
  .general-menu-content .copyright {
    position: absolute;
    color: white;
    opacity: 0.5;
    transition: opacity 0.2s; }
    .general-menu-content .copyright:hover {
      opacity: 1; }
  .general-menu-content .el-link-sm:before {
    background-color: #ffff32; }
  .general-menu-content .email {
    position: absolute;
    color: white; }
    @media (max-width: 1229px) {
      .general-menu-content .email {
        right: 105px; } }
    @media (max-width: 767px) {
      .general-menu-content .email {
        right: 75px;
        top: 28px; } }
    .general-menu-content .email:before {
      background-color: #ffff32; }
    .general-menu-content .email .icon {
      stroke: white;
      stroke-width: 0.3px; }
      @media (max-width: 767px) {
        .general-menu-content .email .icon {
          margin: -3px -2px 0; } }
  .general-menu-content .lang {
    display: inline-block;
    position: fixed;
    z-index: 1;
    font-size: 0;
    top: 50px;
    right: 210px; }
    @media (max-width: 1229px) {
      .general-menu-content .lang {
        right: 260px; } }
    @media (max-width: 767px) {
      .general-menu-content .lang {
        top: 28px;
        left: 20px;
        right: auto; } }
    .general-menu-content .lang li {
      float: left; }
      .general-menu-content .lang li:not(:last-child) {
        margin-right: 10px; }
      .general-menu-content .lang li.is-active a {
        opacity: 1; }
      .general-menu-content .lang li a {
        color: white;
        opacity: 0.8;
        transition: all 0.2s;
        font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        text-transform: uppercase; }
        .general-menu-content .lang li a:hover, .general-menu-content .lang li a:focus {
          opacity: 1;
          text-decoration: none;
          color: #ffff32; }
  .general-menu-content .el-right-menu__list {
    position: absolute;
    width: 960px;
    left: 0;
    right: 0;
    margin: 100px auto 0;
    top: 0; }
    @media (max-width: 1060px) {
      .general-menu-content .el-right-menu__list {
        width: calc(100% - 130px);
        margin: 100px 80px 0 50px; } }
    @media (max-width: 767px) {
      .general-menu-content .el-right-menu__list {
        margin: 70px 20px 0 20px; } }
    .general-menu-content .el-right-menu__list.hovered .el-right-menu__link {
      color: #949494; }
      .general-menu-content .el-right-menu__list.hovered .el-right-menu__link:hover {
        color: white; }
    .general-menu-content .el-right-menu__list .el-right-menu__line {
      right: auto;
      left: 0;
      height: 60px;
      width: 5px;
      background: #ffff32;
      opacity: 0;
      visibility: hidden; }
      @media (max-width: 767px) {
        .general-menu-content .el-right-menu__list .el-right-menu__line {
          height: 40px; } }
    .general-menu-content .el-right-menu__list .el-right-menu__link {
      text-align: left;
      padding: 0 60px;
      font-size: 30px;
      line-height: 60px;
      color: white;
      display: inline-block; }
      @media (max-width: 767px) {
        .general-menu-content .el-right-menu__list .el-right-menu__link {
          font-size: 18px;
          line-height: 40px;
          padding: 0 20px 0 40px; } }
    .general-menu-content .el-right-menu__list .el-right-menu__item--current .el-right-menu__link {
      transform: translateX(0);
      pointer-events: all; }
    .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(1).el-right-menu__item--current ~ .el-right-menu__line {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible; }
    .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(2).el-right-menu__item--current ~ .el-right-menu__line {
      transform: translateY(60px);
      opacity: 1;
      visibility: visible; }
      @media (max-width: 767px) {
        .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(2).el-right-menu__item--current ~ .el-right-menu__line {
          transform: translateY(40px); } }
    .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(3).el-right-menu__item--current ~ .el-right-menu__line {
      transform: translateY(120px);
      opacity: 1;
      visibility: visible; }
      @media (max-width: 767px) {
        .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(3).el-right-menu__item--current ~ .el-right-menu__line {
          transform: translateY(80px); } }
    .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(4).el-right-menu__item--current ~ .el-right-menu__line {
      transform: translateY(180px);
      opacity: 1;
      visibility: visible; }
      @media (max-width: 767px) {
        .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(4).el-right-menu__item--current ~ .el-right-menu__line {
          transform: translateY(120px); } }
    .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(5).el-right-menu__item--current ~ .el-right-menu__line {
      transform: translateY(240px);
      opacity: 1;
      visibility: visible; }
      @media (max-width: 767px) {
        .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(5).el-right-menu__item--current ~ .el-right-menu__line {
          transform: translateY(160px); } }
    .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(6).el-right-menu__item--current ~ .el-right-menu__line {
      transform: translateY(300px);
      opacity: 1;
      visibility: visible; }
      @media (max-width: 767px) {
        .general-menu-content .el-right-menu__list .el-right-menu__item:nth-child(6).el-right-menu__item--current ~ .el-right-menu__line {
          transform: translateY(200px); } }

body.disable-container-center.general-menu-opened .social.hovered li a, body.case-detail.general-menu-opened .social.hovered li a {
  color: #949494 !important; }
  body.disable-container-center.general-menu-opened .social.hovered li a:hover, body.case-detail.general-menu-opened .social.hovered li a:hover {
    color: white !important; }

body.disable-container-center.general-menu-opened .social li a, body.case-detail.general-menu-opened .social li a {
  color: white !important; }
  body.disable-container-center.general-menu-opened .social li a:before, body.case-detail.general-menu-opened .social li a:before {
    background-color: #ffff32 !important; }

body.disable-container-center .page-body, body.case-detail .page-body {
  display: block; }

body.disable-container-center .copyright, body.case-detail .copyright {
  opacity: 0 !important;
  transition: opacity 0.2s; }
  @media (max-width: 1229px) {
    body.disable-container-center .copyright, body.case-detail .copyright {
      display: none; } }

body.disable-container-center.end-contend .copyright, body.case-detail.end-contend .copyright {
  opacity: 1 !important; }

body.disable-container-center.end-contend.start-scroll .copyright, body.case-detail.end-contend.start-scroll .copyright {
  opacity: 1 !important;
  transition: opacity 0.2s; }

body.disable-container-center.end-contend.start-scroll .footer .sound:before, body.case-detail.end-contend.start-scroll .footer .sound:before {
  display: inline-block; }

body.disable-container-center.end-contend.start-scroll .footer .sound:after,
body.disable-container-center.end-contend.start-scroll .footer .social, body.case-detail.end-contend.start-scroll .footer .sound:after,
body.case-detail.end-contend.start-scroll .footer .social {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s; }

body.disable-container-center.start-scroll .copyright, body.case-detail.start-scroll .copyright {
  opacity: 0;
  transition: opacity 0.2s; }

body.disable-container-center.start-scroll .footer .sound:before, body.case-detail.start-scroll .footer .sound:before {
  display: none; }

body.disable-container-center.start-scroll .footer .sound:after,
body.disable-container-center.start-scroll .footer .social, body.case-detail.start-scroll .footer .sound:after,
body.case-detail.start-scroll .footer .social {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s; }
  @media (max-width: 1229px) {
    body.disable-container-center.start-scroll .footer .sound:after,
    body.disable-container-center.start-scroll .footer .social, body.case-detail.start-scroll .footer .sound:after,
    body.case-detail.start-scroll .footer .social {
      opacity: 1;
      visibility: visible; } }

@media (max-width: 1229px) {
  body.case-detail .header {
    position: relative;
    background: none;
    height: auto; }
    body.case-detail .header:before {
      display: none; } }

body.case-detail .header .el-link-sm:before {
  background-color: #ffff32; }

body.case-detail .logo svg .jet {
  fill: white; }

body.case-detail .header .email {
  color: white; }
  body.case-detail .header .email:before {
    background-color: #ffff32; }
  body.case-detail .header .email .icon {
    stroke: white;
    stroke-width: 0.5px; }

body.case-detail .social.hovered li a {
  color: #949494; }
  body.case-detail .social.hovered li a:hover {
    color: white; }

body.case-detail .social li a {
  color: white; }
  body.case-detail .social li a:before {
    background-color: #ffff32; }

body.case-detail .sound:after {
  color: #949494; }

body.case-detail .sound .line {
  background: white; }

body.case-detail .sound:hover:after {
  color: white; }

body.case-detail .sound:before {
  background-color: #ffff32; }

body.case-detail .btn-menu:before, body.case-detail .btn-menu:after, body.case-detail .btn-menu .line {
  background: white; }

body.case-detail .title-page .title {
  color: white; }

body.case-detail.menu-black .btn-menu:before, body.case-detail.menu-black .btn-menu:after, body.case-detail.menu-black .btn-menu .line {
  background: #000000; }

body.case-detail.menu-black .btn-menu:hover:before, body.case-detail.menu-black .btn-menu:hover:after, body.case-detail.menu-black .btn-menu:hover .line {
  background: #949494; }

body.case-detail.menu-black .title-page .title {
  color: #000000; }

@media (max-width: 1229px) {
  body.case-detail.entered-viewport .header {
    position: fixed;
    background: white;
    height: 64px; }
    body.case-detail.entered-viewport .header:before {
      display: block;
      height: 10px;
      top: 63px; } }

body.case-detail.entered-viewport .header .lang li.is-active a {
  color: #000000; }

body.case-detail.entered-viewport .header .lang li a {
  color: #949494; }
  body.case-detail.entered-viewport .header .lang li a:hover, body.case-detail.entered-viewport .header .lang li a:focus {
    color: #000000; }

body.case-detail.entered-viewport .logo svg .jet {
  fill: #000000; }

body.case-detail.entered-viewport .header .email {
  color: #000000; }
  body.case-detail.entered-viewport .header .email:before {
    background-color: #949494; }
  body.case-detail.entered-viewport .header .email .icon {
    stroke: #000000;
    stroke-width: 0px; }

body.case-detail.entered-viewport .social.hovered li a {
  color: #949494; }
  body.case-detail.entered-viewport .social.hovered li a:hover {
    color: #000000; }

body.case-detail.entered-viewport .social li a {
  color: #000000; }
  body.case-detail.entered-viewport .social li a:before {
    background-color: #949494; }

body.case-detail.entered-viewport .btn-menu:before, body.case-detail.entered-viewport .btn-menu:after, body.case-detail.entered-viewport .btn-menu .line {
  background: #000000; }

body.case-detail.entered-viewport .btn-menu:hover:before, body.case-detail.entered-viewport .btn-menu:hover:after, body.case-detail.entered-viewport .btn-menu:hover .line {
  background: #949494; }

body.case-detail.entered-viewport .title-page .title {
  color: #000000; }

body.case-detail.entered-viewport .sound:hover:after {
  color: #000000; }

body.case-detail.entered-viewport .sound:before {
  background-color: #949494; }

body.case-detail.entered-viewport .sound .line {
  background: #000000; }

body.team-page {
  background-color: #000000; }
  @media (max-width: 768px) {
    body.team-page .page-wrapper {
      min-height: 100vh; } }
  body.team-page .team-right-menu {
    font-size: 0;
    transform: translateY(-50%); }
    @media (max-width: 767px) {
      body.team-page .team-right-menu {
        transform: translateY(0);
        top: auto;
        right: auto;
        left: 50%;
        bottom: 25px;
        position: absolute;
        transform: translateX(-50%);
        min-width: 111px; } }
    body.team-page .team-right-menu li {
      text-align: center; }
      @media (max-width: 767px) {
        body.team-page .team-right-menu li {
          float: left;
          padding: 5px; } }
      body.team-page .team-right-menu li:not(:last-child) {
        margin-bottom: 9px; }
      body.team-page .team-right-menu li:hover {
        cursor: pointer; }
        body.team-page .team-right-menu li:hover a {
          background-color: #ffff32; }
      body.team-page .team-right-menu li.slick-active {
        margin-bottom: 10px; }
        @media (max-width: 767px) {
          body.team-page .team-right-menu li.slick-active {
            margin-bottom: 0; } }
        body.team-page .team-right-menu li.slick-active a {
          width: 34px;
          height: 34px;
          border: 1px solid #ffff32;
          box-sizing: border-box; }
          @media (max-width: 767px) {
            body.team-page .team-right-menu li.slick-active a {
              background: white;
              border-color: white;
              width: 10px;
              height: 10px;
              border-radius: 100%; } }
          body.team-page .team-right-menu li.slick-active a img {
            opacity: 1; }
            @media (max-width: 767px) {
              body.team-page .team-right-menu li.slick-active a img {
                display: none; } }
      body.team-page .team-right-menu li a {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: #191919;
        transition: background 0.2s; }
        @media (max-width: 767px) {
          body.team-page .team-right-menu li a {
            border-radius: 100%; } }
        body.team-page .team-right-menu li a img {
          opacity: 0;
          -o-object-fit: cover;
          object-fit: cover;
          height: 100%;
          width: 100%;
          -webkit-box-flex: 0;
          -ms-flex-positive: 0;
          flex-grow: 0; }
  body.team-page .lines {
    position: absolute;
    z-index: 1;
    opacity: 0.1;
    border-color: #f2f2f2; }
  @media (max-width: 1229px) {
    body.team-page .header {
      position: relative;
      background: none;
      height: auto; }
      body.team-page .header:before {
        display: none; } }
  body.team-page .logo svg .jet {
    fill: white; }
  body.team-page .header .el-link-sm:before {
    background-color: #ffff32; }
  body.team-page .header .email {
    color: white; }
    body.team-page .header .email:before {
      background-color: #ffff32; }
    body.team-page .header .email .icon {
      stroke: white;
      stroke-width: 0.5px; }
  body.team-page .social.hovered li a {
    color: #949494; }
    body.team-page .social.hovered li a:hover {
      color: white; }
  body.team-page .social li a {
    color: white; }
    body.team-page .social li a:before {
      background-color: #ffff32; }
  body.team-page .btn-menu:before, body.team-page .btn-menu:after, body.team-page .btn-menu .line {
    background: white; }
  body.team-page .title-page .title {
    color: white; }
  body.team-page .title-page .menu-number {
    color: #181818; }
  body.team-page .page-open .btn-menu:hover .menu-number {
    color: #282828; }
  body.team-page .mouse {
    pointer-events: none;
    z-index: 1;
    position: fixed; }
    @media (max-width: 991px) {
      body.team-page .mouse {
        display: none; } }

.no-scroll {
  overflow: hidden; }
  @media (max-width: 767px) {
    .no-scroll {
      height: 100%; } }

body.modal-opened {
  overflow: hidden; }
  body.modal-opened .modal {
    transform: scaleY(1); }

.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #000000;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 0 100%;
  transform: scaleY(0);
  overflow: hidden; }
  .modal .modal-title {
    font-weight: 100; }
    .modal .modal-title span {
      font-weight: 900; }
  .modal .el-btn {
    width: 360px;
    max-width: 360px; }
    @media (max-width: 991px) {
      .modal .el-btn {
        width: 300px;
        max-width: 300px; } }
    @media (max-width: 440px) {
      .modal .el-btn {
        width: 290px;
        max-width: 290px; } }
  .modal .modal_text_p {
    max-width: 330px;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: white;
    margin-bottom: 40px; }
  .modal .container {
    max-width: 990px;
    width: 100%; }
    @media (max-width: 1060px) {
      .modal .container {
        max-width: 100%;
        padding: 0 50px; } }
    @media (max-width: 767px) {
      .modal .container {
        padding: 0 15px; } }
  .modal .mod-title-section.mod-marg {
    margin-bottom: 30px; }
    @media (max-width: 991px) {
      .modal .mod-title-section.mod-marg {
        margin-bottom: 30px; } }
    @media (max-width: 767px) {
      .modal .mod-title-section.mod-marg {
        margin-block: 20px; } }
  .modal .modal-content {
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
    padding: 110px 0 118px;
    display: flex;
    justify-content: center;
    color: white; }
    @media (max-height: 680px) {
      .modal .modal-content {
        display: block;
        padding: 60px 0 40px;
        overflow-y: auto;
        overflow-x: hidden; } }
    @media (max-width: 767px) {
      .modal .modal-content {
        padding: 60px 0 40px;
        display: block;
        overflow-y: auto;
        overflow-x: hidden; } }
    .modal .modal-content .close {
      top: 50px;
      right: 50px; }
      @media (max-width: 767px) {
        .modal .modal-content .close {
          top: 22px;
          right: 20px; } }
    .modal .modal-content .btn-wrap {
      margin-top: 40px; }
      @media (max-width: 767px) {
        .modal .modal-content .btn-wrap {
          width: 290px;
          text-align: center;
          margin: 40px auto;
          display: block; } }
  .modal .copyright {
    position: absolute; }
    @media (max-width: 991px) {
      .modal .copyright {
        display: none; } }
  .modal .close {
    top: 15px; }
    .modal .close .icon {
      color: white;
      transition: color 0.2s; }
    .modal .close:hover .icon {
      color: #ffff32; }
  .modal h2,
  .modal .h2 {
    color: white; }
  .modal .el-bg-text {
    color: #181818; }
  .modal form {
    width: 100%; }
    @media (max-width: 991px) {
      .modal form {
        margin-left: auto;
        margin-right: auto; } }
    .modal form .el-textarea {
      height: 130px; }
      @media (max-width: 767px) {
        .modal form .el-textarea {
          display: none; } }
    .modal form .el-text-i {
      width: 47%;
      display: inline-block; }
    .modal form .el-file-input .label {
      max-width: 190px;
      display: block; }
    .modal form .el-text-in {
      float: right !important; }
    .modal form .col-left {
      float: left;
      width: 100%;
      max-width: 48%; }
      @media (max-width: 767px) {
        .modal form .col-left {
          max-width: 100%; } }
    .modal form .col-right {
      float: right;
      width: 100%;
      max-width: 45%;
      opacity: 1;
      border-left: solid 1px rgba(255, 255, 255, 0.2);
      padding-left: 65px;
      margin-top: 10px;
      padding-bottom: 50px; }
      @media (max-width: 950px) {
        .modal form .col-right {
          padding-left: 7%; } }
      @media (max-width: 840px) {
        .modal form .col-right {
          padding-left: 0;
          border-left: 0; } }
      @media (max-width: 767px) {
        .modal form .col-right {
          max-width: 100%;
          display: none; } }
  .modal .success-message {
    opacity: 0;
    display: none; }
    .modal .success-message .el-bg-text {
      bottom: auto;
      top: 70px; }

@media (max-width: 736px) and (max-height: 414px) {
  .modal-list {
    display: flex;
    flex-wrap: wrap; } }

@media (max-width: 767px) {
  .modal-list {
    padding: 20px 0; } }

.modal-list li {
  font-size: 16px;
  line-height: 1.56;
  padding: 12px 0;
  display: flex;
  align-items: center; }
  @media (max-width: 736px) and (max-height: 414px) {
    .modal-list li {
      width: 48%; } }
  .modal-list li span {
    padding: 0 20px; }

.file_download {
  padding-left: 60px;
  text-decoration: none;
  position: relative;
  display: block;
  margin: 20px 0 0;
  transition: 0.5s ease; }
  @media (max-width: 991px) {
    .file_download {
      padding-left: 20px; } }
  .file_download:hover {
    text-decoration: none;
    transform: translateY(5px);
    opacity: 0.5; }
  .file_download span {
    padding: 0 20px; }

.revealerElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: 0 50% 0; }

.block-revealer {
  display: inline-block; }

.block-revealer__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0; }

.btn-wrap {
  display: inline-block; }

.js-animate .js-anim-sound,
.js-animate .js-anim-copyright,
.js-animate .js-anim-social {
  opacity: 0; }

.js-anim-email,
.js-anim-btn-menu,
.js-anim-r-menu,
.js-anim-h3,
.js-anim-title-section,
.js-anim-logo,
.js-anim-h1,
.js-anim-btn,
.js-anim-modal-btn,
.js-anim-title-modal,
.js-anim-modal-items,
.js-anim-modal-copyr,
.skill-expertise,
.text-expertise,
.line-1,
.line-2,
.line-3,
.line-4,
.rectangle-yellow-wrap,
.js-anim-title,
.js-el-right-menu__list,
.js-anim-text,
.img-wrap,
.line-wrap,
.js-anim-link,
.js-anim-title-services,
.js-anim-text-services,
.js-anim-title-cases,
.js-type,
.js-anim-title-about,
.text-number,
.js-anim-title-case,
.js-anim-text-case,
.img-case,
.js-anim-title-case-detail,
.js-anim-link-case-detail,
.js-anim-text-case-detail,
.js-anim-text-home,
.js-anim-title-clients,
.js-anim-text-clients,
.list-clients li,
.list-clients,
.mouse,
.js-anim-btn-who,
.js-about-title-showreel,
.video-showreel,
.js-about-title-awards,
.js-about-title-medal,
.js-about-text-medal,
.js-about-list-medal,
.js-about-text-awards,
.js-testimonials-slider,
.js-about-title-reviews,
.js-anim-mouse,
.js-anim-h2,
.js-slider-team-photo,
.js-anim-photo-user,
.play-button,
.lang {
  opacity: 0; }

.js-anim-items, .js-number, .js-number-1, .js-number-2, .js-about-list-awards {
  opacity: 0;
  position: relative;
  left: -15px; }

.video-start .js-anim-logo, .video-start .js-anim-email, .video-start .js-anim-btn-menu, .video-start #awwwards, .video-start .js-anim-social, .video-start .cookie, .video-start .js-anim-mouse, .video-start .js-anim-r-menu, .video-start .js-play-button, .video-start .home-item, .video-start .home-text-bg {
  transition: opacity 0.3s ease; }

html.ie-11 .page-body,
html.ie-10 .page-body,
html.ie-9 .page-body,
html.ie .page-body {
  display: block;
  overflow: visible; }

html.ie-11 .services-section-image.content--layout img,
html.ie-10 .services-section-image.content--layout img,
html.ie-9 .services-section-image.content--layout img,
html.ie .services-section-image.content--layout img {
  width: 100%; }

.cookie {
  max-width: 290px;
  position: relative;
  display: block;
  z-index: 9;
  position: fixed;
  padding: 0;
  right: 18vw;
  width: inherit;
  top: 4vh;
  height: fit-content;
  will-change: transform;
  transform: scale(0);
  transition: all 0.5s ease;
  transition-delay: .45s; }
  @media (max-width: 1230px) {
    .cookie {
      right: 22vw;
      top: 7vh; } }
  @media (max-width: 1229px) {
    .cookie {
      right: 22vw;
      top: 10vh; } }
  @media (max-width: 767px) {
    .cookie {
      right: 19vw;
      top: 11vh; } }
  @media (max-width: 480px) {
    .cookie {
      right: auto;
      z-index: 100;
      bottom: 15px;
      top: auto;
      left: 15px; }
      .cookie .cookie__block {
        max-width: 230px; } }
  .cookie .close {
    position: absolute;
    width: 30px;
    height: 30px;
    background: #ffff32;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    right: 0;
    top: 0;
    transform: translate(50%, -50%) rotate(180deg) scale(1);
    cursor: pointer; }
    .cookie .close .icon {
      font-size: 15px; }
  .cookie__block {
    padding: 2em;
    width: inherit;
    left: inherit;
    border: none !important;
    border-radius: 6px;
    background: #000;
    color: #fff;
    height: fit-content;
    max-width: 290px;
    border-radius: 6px;
    z-index: 8;
    box-shadow: 15px 15px 30px rgba(0, 0, 0, 0.22);
    will-change: transform; }
    @media (max-width: 450px) {
      .cookie__block {
        max-width: 250px;
        padding: 1em; } }
  .cookie__title {
    display: flex;
    align-items: center;
    color: inherit;
    justify-content: space-between; }
    .cookie__title h3 {
      font-weight: 600;
      color: inherit; }
    .cookie__title .icon {
      font-size: 22px;
      position: relative;
      top: -2px; }
  .cookie__txt {
    padding: 5px 0 15px;
    font-weight: 100;
    font-size: 12px;
    line-height: 16px; }
    .cookie__txt a {
      text-decoration: underline; }
  .cookie__btn {
    background-color: #ffff32;
    color: #000;
    border-radius: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 800;
    outline: none;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    transition: all .2s; }
    .cookie__btn:hover {
      transform: translateY(5px);
      box-shadow: 0 6px 20px -10px rgba(255, 255, 50, 0.8); }

.team-page .cookie .cookie__block {
  color: #000;
  background: #fff; }

.full .video-player-youtube {
  width: 100vw;
  height: 100vh; }
  .full .video-player-youtube .video-youtube-wrap {
    top: 50%;
    transform: translateY(-50%); }

.video-player-youtube {
  position: relative;
  display: inline-block;
  font-size: 0;
  background-color: black;
  width: 100%;
  transition: all 0.2s; }
  .video-player-youtube.play .icon-play {
    display: none; }
  .video-player-youtube.play .icon-pause {
    display: inline-block; }
  .video-player-youtube.start .video-youtube-wrap .video-placeholder {
    opacity: 1; }
  .video-player-youtube .video-controls {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    z-index: 1;
    position: fixed;
    height: 60px;
    z-index: 1;
    color: #000000;
    box-sizing: border-box;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 19px; }
    @media (max-width: 767px) {
      .video-player-youtube .video-controls {
        height: 40px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px; } }
  .video-player-youtube .video-youtube-wrap {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; }
    .video-player-youtube .video-youtube-wrap .video-placeholder {
      opacity: 0; }
  .video-player-youtube .video-placeholder {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-player-youtube .progress-bar {
    position: absolute;
    left: 0;
    bottom: 60px;
    outline: none;
    padding: 0;
    width: 100%;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #ffff32), color-stop(100%, #ffff32));
    background-image: -webkit-linear-gradient(#ffff32, #ffff32);
    background-image: -moz-linear-gradient(#ffff32, #ffff32);
    background-image: -o-linear-gradient(#ffff32, #ffff32);
    background-image: linear-gradient(#ffff32, #ffff32);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    border-radius: 0;
    cursor: pointer;
    -webkit-appearance: none;
    transition: all 0.1s; }
    @media (max-width: 767px) {
      .video-player-youtube .progress-bar {
        bottom: 40px; } }
    .video-player-youtube .progress-bar:hover {
      height: 8px; }
    .video-player-youtube .progress-bar::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
      -webkit-appearance: none; }
    .video-player-youtube .progress-bar::-moz-range-track {
      box-shadow: none;
      border: none;
      background: transparent; }
    .video-player-youtube .progress-bar::-moz-focus-outer {
      border: 0; }
    .video-player-youtube .progress-bar::-webkit-slider-thumb {
      width: 0px;
      height: 0px;
      border: 0;
      background: #fff;
      border-radius: 0;
      box-shadow: none;
      -webkit-appearance: none; }
      @media (max-width: 767px) {
        .video-player-youtube .progress-bar::-webkit-slider-thumb {
          width: 16px;
          height: 16px;
          border-radius: 100%; } }
    .video-player-youtube .progress-bar::-moz-range-thumb {
      width: 0px;
      height: 0px;
      border: 0;
      background: #fff;
      border-radius: 0;
      box-shadow: none; }
      @media (max-width: 767px) {
        .video-player-youtube .progress-bar::-moz-range-thumb {
          width: 16px;
          height: 16px;
          border-radius: 100%; } }
  .video-player-youtube .video-scrubber, .video-player-youtube .video-progress {
    cursor: pointer;
    transition: all 0.1s; }
  .video-player-youtube .video-scrubber {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    cursor: pointer;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.1); }
    @media (max-width: 991px) {
      .video-player-youtube .video-scrubber {
        background-color: rgba(255, 255, 255, 0.3); } }
    @media (max-width: 767px) {
      .video-player-youtube .video-scrubber {
        height: 2px; } }
    .video-player-youtube .video-scrubber:hover {
      height: 8px; }
      .video-player-youtube .video-scrubber:hover .video-progress:after {
        top: -5px; }
    @media (max-width: 767px) {
      .video-player-youtube .video-scrubber {
        bottom: 40px; } }
  .video-player-youtube .video-progress {
    width: 0;
    height: 100%;
    background-color: #000000;
    cursor: pointer; }
    @media (max-width: 991px) {
      .video-player-youtube .video-progress {
        background-color: white; } }
  .video-player-youtube .video-time {
    display: inline-block;
    color: #000000;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase;
    float: right; }
    @media (max-width: 991px) {
      .video-player-youtube .video-time {
        color: white; } }
    .video-player-youtube .video-time span {
      display: inline-block;
      padding: 0 4px;
      min-width: 34px; }
  .video-player-youtube .video-element {
    max-width: 100%;
    width: 100%; }
  .video-player-youtube .icon-mute {
    transform: translateY(-3px);
    padding: 5px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 0;
    color: transparent;
    background: url(../img/svg/sound.svg) no-repeat center/16px;
    cursor: pointer; }
    @media (max-width: 991px) {
      .video-player-youtube .icon-mute {
        background: url(../img/svg/sound-w.svg) no-repeat center/16px; } }
    .video-player-youtube .icon-mute.muted {
      background: url(../img/svg/volume-off.svg) no-repeat center/16px; }
      @media (max-width: 991px) {
        .video-player-youtube .icon-mute.muted {
          background: url(../img/svg/volume-off-w.svg) no-repeat center/16px; } }
  .video-player-youtube .icon-play {
    transform: translateY(-3px);
    margin: 0 15px;
    padding: 5px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: inline-block;
    background: url(../img/svg/play-btn.svg) no-repeat center/24px;
    cursor: pointer; }
    @media (max-width: 991px) {
      .video-player-youtube .icon-play {
        background: url(../img/svg/play-btn-w.svg) no-repeat center/24px; } }
  .video-player-youtube .icon-pause {
    transform: translateY(-3px);
    margin: 0 15px;
    padding: 5px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: none;
    background: url(../img/svg/pause-btn.svg) no-repeat center/12px;
    cursor: pointer; }
    @media (max-width: 991px) {
      .video-player-youtube .icon-pause {
        background: url(../img/svg/pause-btn-w.svg) no-repeat center/12px; } }
  .video-player-youtube .volume {
    transform: translateY(-3px);
    position: relative;
    display: inline-block;
    margin: 0 15px;
    width: 100px;
    height: 2px;
    cursor: pointer;
    vertical-align: middle;
    background-color: rgba(0, 0, 0, 0.1); }
    @media (max-width: 991px) {
      .video-player-youtube .volume {
        background-color: rgba(255, 255, 255, 0.3); } }
    @media (max-width: 767px) {
      .video-player-youtube .volume {
        width: 60px; } }
    .video-player-youtube .volume .volume-bar {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      display: block;
      cursor: pointer;
      width: 52%;
      height: 100%;
      background-color: #000000; }
      @media (max-width: 991px) {
        .video-player-youtube .volume .volume-bar {
          background-color: white; } }
      .video-player-youtube .volume .volume-bar:after {
        display: inline-block;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #000000;
        cursor: pointer;
        right: -5px;
        top: -5px;
        position: absolute;
        z-index: 10; }
        @media (max-width: 991px) {
          .video-player-youtube .volume .volume-bar:after {
            background-color: white; } }

.time_buttons_wrap {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .time_buttons_wrap {
      flex-direction: column;
      justify-content: center; } }
  .time_buttons_wrap .time_button_item {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 20px #00000021;
    padding: 15px 20px 13px;
    display: flex;
    min-width: 270px;
    align-items: center;
    margin-top: 30px;
    max-width: 300px; }
    .time_buttons_wrap .time_button_item .time_button_title {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.85;
      letter-spacing: 1px;
      text-align: left;
      color: #000000;
      text-transform: uppercase;
      margin-right: 20px;
      flex-grow: 1; }
    .time_buttons_wrap .time_button_item .time_button_value {
      font-size: 13px;
      font-weight: normal;
      line-height: 1.85;
      letter-spacing: 0.4px;
      text-align: left;
      color: #000000;
      margin-right: 15px; }
    .time_buttons_wrap .time_button_item .time_button_icon {
      color: #c7c7c7;
      align-items: center;
      display: flex; }

.time_total {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px; }
  .time_total .time_total_title {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: 0.4px;
    text-align: left;
    color: #000000;
    margin-right: 20px; }
  .time_total .time_total_value {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.85;
    letter-spacing: 0.4px;
    text-align: left;
    color: #000000; }
    .time_total .time_total_value span {
      font-size: 13px;
      font-weight: normal; }

.phone-slider-wr {
  position: relative;
  width: 100%;
  overflow: hidden; }

.phone-slider-wr .border-ph {
  z-index: 8;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;
  margin: 0 auto;
  position: absolute;
  width: 305px;
  height: 635px;
  background: url("../../../../assets/img/content/cases/test/Iphone-Mockup_.png") no-repeat center;
  background-size: contain;
  pointer-events: none; }
  @media (max-width: 459px) {
    .phone-slider-wr .border-ph {
      width: 80% !important; } }

.phone-slider .slick-slide {
  margin: 20px 0;
  cursor: pointer; }
  .phone-slider .slick-slide > div {
    padding: 0 8px 4px;
    box-sizing: border-box; }
  .phone-slider .slick-slide .phone-slide {
    height: 100%;
    border-radius: 25px;
    overflow: hidden;
    transform: scale(0.9, 0.9) translate(0px, 0px);
    box-shadow: 0px 0px 20px #00000021;
    transition: all 0.2s; }
    @media (max-width: 459px) {
      .phone-slider .slick-slide .phone-slide {
        transform: scale(0.75, 0.75) translate(0px, 2px); } }

.phone-slider .slick-slide.slick-current.slick-active.slick-center {
  margin: 20px 0; }
  .phone-slider .slick-slide.slick-current.slick-active.slick-center .phone-slide {
    border-radius: 35px;
    transform: scale(1, 1) translate(0px, 3px); }
    @media (max-width: 1300px) {
      .phone-slider .slick-slide.slick-current.slick-active.slick-center .phone-slide {
        border-radius: 30px; } }
    @media (max-width: 1100px) {
      .phone-slider .slick-slide.slick-current.slick-active.slick-center .phone-slide {
        border-radius: 20px; } }
    @media (max-width: 991px) {
      .phone-slider .slick-slide.slick-current.slick-active.slick-center .phone-slide {
        border-radius: 30px; } }
    @media (max-width: 767px) {
      .phone-slider .slick-slide.slick-current.slick-active.slick-center .phone-slide {
        border-radius: 20px; } }
    @media (max-width: 459px) {
      .phone-slider .slick-slide.slick-current.slick-active.slick-center .phone-slide {
        border-radius: 42px;
        transform: scale(0.79, 0.79) translate(0px, 7px); } }

.phone-slider .slick-slide img {
  width: 100%; }

.h1, h1 {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 80px;
  font-weight: bold;
  line-height: 1.12;
  letter-spacing: -2px;
  text-align: left;
  color: #000000; }
  @media (max-width: 991px) {
    .h1, h1 {
      font-size: 60px; } }
  @media (max-width: 767px) {
    .h1, h1 {
      font-size: 40px; } }

.h2, h2 {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.50;
  text-align: left;
  color: #000000; }
  @media (max-width: 991px) {
    .h2, h2 {
      font-size: 26px; } }
  @media (max-width: 767px) {
    .h2, h2 {
      font-size: 18px; } }

.h3, h3 {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
  text-transform: uppercase; }
  @media (max-width: 991px) {
    .h3, h3 {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .h3, h3 {
      font-size: 12px; } }

.home-text-bg {
  position: absolute;
  top: 50%;
  margin-left: -370px;
  margin-top: -100px;
  left: 50%;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 200px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 4px;
  text-align: left;
  color: rgba(0, 0, 0, 0.03);
  z-index: -1;
  pointer-events: none; }
  @media (max-width: 767px) {
    .home-text-bg {
      left: 50%;
      font-size: 110px;
      margin-left: 0;
      margin-top: -30px;
      transform: translate(-50%, -50%); } }

@media (max-width: 767px) {
  .page-body.mod-contact, .page-body.mod-expertise {
    display: flex; } }

@media (max-width: 767px) {
  .page-body.mod-home-first {
    display: flex; } }

.mod-home-first .h1, .mod-home-first h1 {
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .mod-home-first .h1, .mod-home-first h1 {
      margin-bottom: 10px; } }

.mod-home-first .h3, .mod-home-first h3 {
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .mod-home-first .h3, .mod-home-first h3 {
      font-size: 15px;
      max-width: 440px; } }
  @media (max-width: 767px) {
    .mod-home-first .h3, .mod-home-first h3 {
      margin-bottom: 10px; } }

.mod-home-first .btn-wrap.js-anim-btn-who {
  margin-left: 10px; }
  @media (max-width: 500px) {
    .mod-home-first .btn-wrap.js-anim-btn-who {
      margin-left: 0; } }

@media (max-width: 500px) {
  .mod-home-first .btn-wrap.js-anim-btn-who {
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto; } }

@media (max-width: 767px) {
  .mod-home-first .text-mob-center {
    text-align: center; } }

@media (max-width: 767px) {
  .mod-home-first .container-center {
    text-align: center; } }

.mod-home-first .text-home {
  display: inline-block;
  width: 100%;
  max-width: 600px;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.85;
  letter-spacing: 0.2px;
  text-align: left;
  color: #000000;
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .mod-home-first .text-home {
      max-width: 500px; } }
  @media (max-width: 910px) {
    .mod-home-first .text-home {
      max-width: 440px; } }
  @media (max-width: 767px) {
    .mod-home-first .text-home {
      font-size: 12px;
      line-height: 1.6;
      margin-bottom: 30px; } }

.mod-home-first .el-right-menu__list {
  margin: -90px 0 0 0; }
  @media (max-width: 767px) {
    .mod-home-first .el-right-menu__list {
      opacity: 1;
      top: auto;
      bottom: 20px;
      margin: 0 -60px 0 0;
      right: 50%; } }
  @media (max-width: 767px) {
    .mod-home-first .el-right-menu__list .is-active .el-right-menu__link {
      transform: translateX(0px);
      background: #000000; } }
  @media (max-width: 767px) {
    .mod-home-first .el-right-menu__list .el-right-menu__line {
      display: none; } }
  @media (max-width: 767px) {
    .mod-home-first .el-right-menu__list .el-right-menu__item {
      display: inline-block;
      font-size: 0;
      float: left;
      padding: 5px; } }
  @media (max-width: 767px) {
    .mod-home-first .el-right-menu__list .el-right-menu__link {
      display: inline-block;
      font-size: 0;
      border-radius: 100%;
      padding: 0;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.2); } }

@media (max-width: 767px) {
  .mod-expertise .container-center, .mod-policy .container-center {
    padding-top: 25px; } }

@media (max-width: 767px) and (orientation: landscape) {
  .mod-expertise .container-center, .mod-policy .container-center {
    padding-top: 40px; } }

.mod-expertise .el-right-menu__list, .mod-policy .el-right-menu__list {
  margin: -90px 0 0 0; }
  @media (max-width: 767px) {
    .mod-expertise .el-right-menu__list, .mod-policy .el-right-menu__list {
      right: 15px; } }
  @media (max-width: 640px) {
    .mod-expertise .el-right-menu__list, .mod-policy .el-right-menu__list {
      opacity: 1;
      top: auto;
      bottom: 20px;
      margin: 0 -50px 0 0;
      right: 50%; } }
  @media (max-width: 640px) {
    .mod-expertise .el-right-menu__list .is-active .el-right-menu__link, .mod-policy .el-right-menu__list .is-active .el-right-menu__link {
      transform: translateX(0px);
      background: #000000; } }
  @media (max-width: 640px) {
    .mod-expertise .el-right-menu__list .el-right-menu__line, .mod-policy .el-right-menu__list .el-right-menu__line {
      display: none; } }
  @media (max-width: 640px) {
    .mod-expertise .el-right-menu__list .el-right-menu__item, .mod-policy .el-right-menu__list .el-right-menu__item {
      display: inline-block;
      font-size: 0;
      float: left;
      padding: 5px; } }
  @media (max-width: 640px) {
    .mod-expertise .el-right-menu__list .el-right-menu__link, .mod-policy .el-right-menu__list .el-right-menu__link {
      display: inline-block;
      font-size: 0;
      border-radius: 100%;
      padding: 0;
      width: 10px;
      height: 10px;
      box-sizing: border-box;
      background: rgba(0, 0, 0, 0.2); } }

.mod-expertise .morph-animation, .mod-policy .morph-animation {
  position: fixed;
  z-index: -1;
  width: 2033px;
  height: 217px;
  pointer-events: none;
  left: 50%;
  top: 50%;
  margin-left: -245px;
  margin-top: -290px; }
  @media (max-width: 991px) {
    .mod-expertise .morph-animation, .mod-policy .morph-animation {
      margin-top: -220px;
      height: 140px; } }
  @media (max-width: 767px) {
    .mod-expertise .morph-animation, .mod-policy .morph-animation {
      height: 90px;
      margin-top: -190px; } }
  @media (max-width: 400px) {
    .mod-expertise .morph-animation, .mod-policy .morph-animation {
      height: 60px;
      margin-left: -100px;
      margin-top: -180px; } }
  @media (max-width: 767px) and (orientation: landscape) {
    .mod-expertise .morph-animation, .mod-policy .morph-animation {
      margin-top: -110px; } }
  @media (max-width: 991px) {
    .mod-expertise .morph-animation svg, .mod-policy .morph-animation svg {
      height: 140px; } }
  @media (max-width: 767px) {
    .mod-expertise .morph-animation svg, .mod-policy .morph-animation svg {
      height: 90px; } }
  @media (max-width: 400px) {
    .mod-expertise .morph-animation svg, .mod-policy .morph-animation svg {
      height: 60px; } }

.mod-expertise .rectangle-yellow-wrap, .mod-policy .rectangle-yellow-wrap {
  position: absolute;
  width: 400px;
  height: 400px;
  left: 50%;
  margin-left: -200px;
  top: 0;
  z-index: 0; }
  @media (max-width: 991px) {
    .mod-expertise .rectangle-yellow-wrap, .mod-policy .rectangle-yellow-wrap {
      width: 350px;
      height: 350px;
      margin-left: -175px; } }
  @media (max-width: 415px) {
    .mod-expertise .rectangle-yellow-wrap, .mod-policy .rectangle-yellow-wrap {
      width: 290px;
      height: 290px;
      margin-left: -145px; } }
  .mod-expertise .rectangle-yellow-wrap .block-revealer__content, .mod-policy .rectangle-yellow-wrap .block-revealer__content {
    width: 100%;
    height: 100%; }

.mod-expertise .rectangle-yellow, .mod-policy .rectangle-yellow {
  display: inline-block;
  pointer-events: none;
  width: 100%;
  height: 100%;
  background-color: #ffff32;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); }

.mod-expertise .group-lines, .mod-policy .group-lines {
  position: absolute;
  pointer-events: none;
  left: 50%;
  margin-left: -245px;
  top: -45px; }
  @media (max-width: 540px) {
    .mod-expertise .group-lines, .mod-policy .group-lines {
      display: none; } }
  .mod-expertise .group-lines .block-revealer__content, .mod-policy .group-lines .block-revealer__content {
    width: 100%;
    height: 100%; }
  .mod-expertise .group-lines .line-1,
  .mod-expertise .group-lines .line-2,
  .mod-expertise .group-lines .line-3, .mod-policy .group-lines .line-1,
  .mod-policy .group-lines .line-2,
  .mod-policy .group-lines .line-3 {
    display: inline-block;
    position: absolute; }
    .mod-expertise .group-lines .line-1 .line-cont,
    .mod-expertise .group-lines .line-2 .line-cont,
    .mod-expertise .group-lines .line-3 .line-cont, .mod-policy .group-lines .line-1 .line-cont,
    .mod-policy .group-lines .line-2 .line-cont,
    .mod-policy .group-lines .line-3 .line-cont {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: #ffff32; }
      @media (max-width: 767px) {
        .mod-expertise .group-lines .line-1 .line-cont,
        .mod-expertise .group-lines .line-2 .line-cont,
        .mod-expertise .group-lines .line-3 .line-cont, .mod-policy .group-lines .line-1 .line-cont,
        .mod-policy .group-lines .line-2 .line-cont,
        .mod-policy .group-lines .line-3 .line-cont {
          background-color: #000000;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); } }
  .mod-expertise .group-lines .line-1, .mod-policy .group-lines .line-1 {
    width: 10px;
    height: 230px;
    top: -15px;
    left: 0; }
    @media (max-width: 767px) {
      .mod-expertise .group-lines .line-1, .mod-policy .group-lines .line-1 {
        height: 120px;
        width: 4px;
        top: 100px;
        left: 20px; } }
  .mod-expertise .group-lines .line-2, .mod-policy .group-lines .line-2 {
    top: -100px;
    width: 1px;
    height: 125px;
    left: 22px; }
    @media (max-width: 767px) {
      .mod-expertise .group-lines .line-2, .mod-policy .group-lines .line-2 {
        top: 271px;
        left: 425px; } }
  .mod-expertise .group-lines .line-3, .mod-policy .group-lines .line-3 {
    top: -65px;
    width: 2px;
    height: 180px;
    left: 30px; }
    @media (max-width: 767px) {
      .mod-expertise .group-lines .line-3, .mod-policy .group-lines .line-3 {
        top: 85px; } }
  .mod-expertise .group-lines .line-4, .mod-policy .group-lines .line-4 {
    display: inline-block;
    position: absolute;
    z-index: 1;
    top: -15px;
    width: 60px;
    height: 120px;
    left: 250px; }
    @media (max-width: 767px) {
      .mod-expertise .group-lines .line-4, .mod-policy .group-lines .line-4 {
        width: 3px;
        height: 120px;
        left: 410px;
        top: 305px; } }
    .mod-expertise .group-lines .line-4 .line-cont, .mod-policy .group-lines .line-4 .line-cont {
      display: inline-block;
      width: 100%;
      height: 100%;
      background-color: #000000;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); }

.mod-expertise .item-expertise, .mod-policy .item-expertise {
  display: inline-block;
  width: 400px;
  min-height: 400px;
  text-align: left; }
  @media (max-width: 767px) {
    .mod-expertise .item-expertise, .mod-policy .item-expertise {
      text-align: center;
      width: 100%;
      max-width: 350px;
      min-height: 350px; } }
  @media (max-width: 540px) {
    .mod-expertise .item-expertise, .mod-policy .item-expertise {
      min-height: 300px; } }
  .mod-expertise .item-expertise.mod-design .title-expertise, .mod-policy .item-expertise.mod-design .title-expertise {
    margin-left: -220px; }
    @media (max-width: 991px) {
      .mod-expertise .item-expertise.mod-design .title-expertise, .mod-policy .item-expertise.mod-design .title-expertise {
        margin-left: -225px; } }
    @media (max-width: 767px) {
      .mod-expertise .item-expertise.mod-design .title-expertise, .mod-policy .item-expertise.mod-design .title-expertise {
        margin-left: 0;
        width: 100%; } }
  @media (max-width: 991px) {
    .mod-expertise .item-expertise.mod-design .skill-expertise, .mod-policy .item-expertise.mod-design .skill-expertise {
      margin-left: 80px; } }
  @media (max-width: 767px) {
    .mod-expertise .item-expertise.mod-design .skill-expertise, .mod-policy .item-expertise.mod-design .skill-expertise {
      margin-left: 0; } }
  .mod-expertise .item-expertise.mod-frontend, .mod-policy .item-expertise.mod-frontend {
    display: none; }
    .mod-expertise .item-expertise.mod-frontend .title-expertise, .mod-policy .item-expertise.mod-frontend .title-expertise {
      margin-left: -220px; }
      @media (max-width: 991px) {
        .mod-expertise .item-expertise.mod-frontend .title-expertise, .mod-policy .item-expertise.mod-frontend .title-expertise {
          margin-left: -140px; } }
      @media (max-width: 767px) {
        .mod-expertise .item-expertise.mod-frontend .title-expertise, .mod-policy .item-expertise.mod-frontend .title-expertise {
          margin-left: 0;
          width: 100%; } }
  .mod-expertise .item-expertise.mod-backend, .mod-policy .item-expertise.mod-backend {
    display: none; }
    .mod-expertise .item-expertise.mod-backend .title-expertise, .mod-policy .item-expertise.mod-backend .title-expertise {
      margin-left: -200px; }
      @media (max-width: 991px) {
        .mod-expertise .item-expertise.mod-backend .title-expertise, .mod-policy .item-expertise.mod-backend .title-expertise {
          margin-left: -140px; } }
      @media (max-width: 767px) {
        .mod-expertise .item-expertise.mod-backend .title-expertise, .mod-policy .item-expertise.mod-backend .title-expertise {
          margin-left: 0;
          width: 100%; } }
    .mod-expertise .item-expertise.mod-backend .text-expertise, .mod-policy .item-expertise.mod-backend .text-expertise {
      max-width: 390px; }
      @media (max-width: 767px) {
        .mod-expertise .item-expertise.mod-backend .text-expertise, .mod-policy .item-expertise.mod-backend .text-expertise {
          max-width: 300px; } }
  .mod-expertise .item-expertise.mod-cms, .mod-policy .item-expertise.mod-cms {
    display: none; }
    .mod-expertise .item-expertise.mod-cms .title-expertise, .mod-policy .item-expertise.mod-cms .title-expertise {
      margin-left: -120px; }
      @media (max-width: 991px) {
        .mod-expertise .item-expertise.mod-cms .title-expertise, .mod-policy .item-expertise.mod-cms .title-expertise {
          margin-left: -125px; } }
      @media (max-width: 767px) {
        .mod-expertise .item-expertise.mod-cms .title-expertise, .mod-policy .item-expertise.mod-cms .title-expertise {
          margin-left: 0;
          width: 100%; } }
  .mod-expertise .item-expertise.mod-mobile, .mod-policy .item-expertise.mod-mobile {
    display: none; }
    .mod-expertise .item-expertise.mod-mobile .title-expertise, .mod-policy .item-expertise.mod-mobile .title-expertise {
      margin-left: -160px; }
      @media (max-width: 991px) {
        .mod-expertise .item-expertise.mod-mobile .title-expertise, .mod-policy .item-expertise.mod-mobile .title-expertise {
          margin-left: -145px; } }
      @media (max-width: 767px) {
        .mod-expertise .item-expertise.mod-mobile .title-expertise, .mod-policy .item-expertise.mod-mobile .title-expertise {
          margin-left: 0;
          width: 100%; } }

.mod-expertise .title-expertise, .mod-policy .title-expertise {
  position: relative;
  z-index: 2;
  text-align: center;
  display: inline-block;
  margin-top: 70px;
  margin-left: -75px; }
  @media (max-width: 767px) {
    .mod-expertise .title-expertise, .mod-policy .title-expertise {
      margin-top: -25px;
      margin-left: 0; } }
  .mod-expertise .title-expertise .name-expertise,
  .mod-expertise .title-expertise .skill-expertise, .mod-policy .title-expertise .name-expertise,
  .mod-policy .title-expertise .skill-expertise {
    text-align: center; }

.mod-expertise .name-expertise, .mod-policy .name-expertise {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 100px;
  font-weight: bold;
  line-height: 0.8;
  letter-spacing: 2px;
  color: #000000;
  text-shadow: 0 5px 10px rgba(0, 0, 0, 0.06);
  text-transform: uppercase;
  margin-bottom: 20px;
  white-space: nowrap; }
  @media (max-width: 991px) {
    .mod-expertise .name-expertise, .mod-policy .name-expertise {
      font-size: 80px; } }
  @media (max-width: 767px) {
    .mod-expertise .name-expertise, .mod-policy .name-expertise {
      font-size: 50px;
      width: 100%; } }
  @media (max-width: 400px) {
    .mod-expertise .name-expertise, .mod-policy .name-expertise {
      font-size: 40px; } }
  .mod-expertise .name-expertise span, .mod-policy .name-expertise span {
    padding-top: 10px; }

.mod-expertise .skill-expertise, .mod-policy .skill-expertise {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #000000;
  margin-bottom: 30px;
  text-transform: uppercase;
  white-space: nowrap; }
  @media (max-width: 991px) {
    .mod-expertise .skill-expertise, .mod-policy .skill-expertise {
      font-size: 16px; } }
  @media (max-width: 767px) {
    .mod-expertise .skill-expertise, .mod-policy .skill-expertise {
      font-size: 14px;
      white-space: inherit; } }
  @media (max-width: 540px) {
    .mod-expertise .skill-expertise, .mod-policy .skill-expertise {
      max-width: 295px;
      display: inline-block; } }

.text-expertise {
  position: relative;
  z-index: 2;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.80;
  letter-spacing: 0.2px;
  text-align: left;
  color: #000000;
  max-width: 370px;
  margin-left: -75px; }
  @media (max-width: 767px) {
    .text-expertise {
      margin-left: 0;
      text-align: center;
      padding: 0 15px;
      font-size: 12px;
      max-width: 300px;
      line-height: 1.5;
      display: inline-block; } }

.animation-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  opacity: 0.3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  z-index: -1; }
  .animation-wrapper canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    z-index: -1; }

.mod-title-section {
  position: relative;
  margin-bottom: 60px; }
  @media (max-width: 991px) {
    .mod-title-section {
      margin-bottom: 30px; } }
  @media (max-width: 767px) {
    .mod-title-section {
      max-width: 400px; } }

.content-section {
  position: relative;
  width: 100%; }

.text-style-paragraph {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.85;
  letter-spacing: 0.4px;
  text-align: left;
  color: #000000;
  margin-left: 135px;
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .text-style-paragraph {
      margin-left: 35px; } }
  @media (max-width: 767px) {
    .text-style-paragraph {
      margin-left: 0px;
      font-size: 12px;
      line-height: 1.5; } }
  .text-style-paragraph.mod-col-left {
    max-width: 340px;
    float: left; }
    @media (max-width: 890px) {
      .text-style-paragraph.mod-col-left {
        max-width: 100%; } }

.text-style-blockquote {
  position: relative;
  width: 100%;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: #000000;
  margin-bottom: 30px; }
  .text-style-blockquote:before {
    content: '‘‘';
    position: absolute;
    top: -15px;
    left: -60px;
    font-weight: bold;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 80px;
    color: #ffff32;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.04);
    line-height: 1;
    pointer-events: none; }
  .text-style-blockquote.mod-col-right {
    max-width: 390px;
    float: right;
    margin-left: 60px; }
    @media (max-width: 1100px) {
      .text-style-blockquote.mod-col-right {
        max-width: 350px; } }
    @media (max-width: 991px) {
      .text-style-blockquote.mod-col-right {
        float: left;
        margin-left: 55px;
        font-size: 14px; } }
    @media (max-width: 890px) {
      .text-style-blockquote.mod-col-right {
        max-width: calc(100% - 60px); } }

@media (max-width: 767px) {
  .mod-about .list-services a.list-title {
    line-height: 1.8; } }

.mod-about .btn-wrap {
  margin-bottom: 80px; }
  @media (max-width: 767px) {
    .mod-about .btn-wrap {
      margin-bottom: 30px; } }

.list-number {
  width: 100%;
  padding-left: 135px;
  margin-bottom: 100px; }
  @media (max-width: 991px) {
    .list-number {
      margin-bottom: 30px;
      padding-left: 35px; } }
  @media (max-width: 767px) {
    .list-number {
      padding-left: 0; } }
  .list-number .el-number {
    float: left;
    width: 33.33%; }
    @media (max-width: 767px) {
      .list-number .el-number {
        text-align: center; } }
  .list-number .number {
    display: inline-block;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 120px;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: black; }
    @media (max-width: 991px) {
      .list-number .number {
        font-size: 100px; } }
    @media (max-width: 767px) {
      .list-number .number {
        display: block;
        text-align: center; } }
    @media (max-width: 480px) {
      .list-number .number {
        font-size: 50px; } }
  .list-number .text-number {
    display: inline-block;
    padding-left: 5px;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.23;
    letter-spacing: 0.4px;
    text-align: left;
    color: #000000; }
    @media (max-width: 767px) {
      .list-number .text-number {
        text-align: center;
        font-size: 12px; } }

.list-services,
.list-vacancies {
  width: 100%;
  max-width: 640px;
  margin-bottom: 30px; }
  .list-services li,
  .list-vacancies li {
    float: left;
    width: 320px; }
    .list-services li.is-open a.list-title.is-ready,
    .list-vacancies li.is-open a.list-title.is-ready {
      cursor: pointer; }
      .list-services li.is-open a.list-title.is-ready .number,
      .list-vacancies li.is-open a.list-title.is-ready .number {
        color: #000000; }
      .list-services li.is-open a.list-title.is-ready:before,
      .list-vacancies li.is-open a.list-title.is-ready:before {
        transform: scaleX(1); }
        @media (max-width: 767px) {
          .list-services li.is-open a.list-title.is-ready:before,
          .list-vacancies li.is-open a.list-title.is-ready:before {
            transform: scaleX(0); } }
  .list-services a.list-title,
  .list-vacancies a.list-title {
    position: relative;
    text-decoration: none;
    display: inline-block;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 3.75;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000000;
    transition: color 0.2s;
    padding-left: 135px; }
    @media (max-width: 767px) {
      .list-services a.list-title,
      .list-vacancies a.list-title {
        padding-left: 55px; } }
    .list-services a.list-title:hover, .list-services a.list-title:focus,
    .list-vacancies a.list-title:hover,
    .list-vacancies a.list-title:focus {
      text-decoration: none; }
    .list-services a.list-title:after,
    .list-vacancies a.list-title:after {
      position: absolute;
      left: 92px;
      top: 50%;
      margin-top: -1px;
      pointer-events: none;
      content: '';
      display: inline-block;
      width: 30px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.15); }
      @media (max-width: 767px) {
        .list-services a.list-title:after,
        .list-vacancies a.list-title:after {
          left: 33px;
          width: 10px; } }
    .list-services a.list-title:before,
    .list-vacancies a.list-title:before {
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
      content: '';
      display: inline-block;
      width: 3px;
      height: 100%;
      background-color: #ffff32;
      transition: transform 0.2s;
      transform: scaleX(0); }
    .list-services a.list-title:hover,
    .list-vacancies a.list-title:hover {
      cursor: pointer; }
      .list-services a.list-title:hover .number,
      .list-vacancies a.list-title:hover .number {
        color: #000000; }
  .list-services .number,
  .list-vacancies .number {
    display: inline-block;
    position: absolute;
    left: 60px;
    color: rgba(0, 0, 0, 0.15);
    transition: color 0.2s; }
    @media (max-width: 767px) {
      .list-services .number,
      .list-vacancies .number {
        left: 0; } }

.list-services a.list-title:hover:before {
  transform: scaleX(1); }
  @media (max-width: 991px) {
    .list-services a.list-title:hover:before {
      transform: scaleX(0); } }

@media (max-width: 767px) {
  .home-item {
    text-align: left; } }

.home-item.mod-design, .home-item.mod-web-development, .home-item.mod-application, .home-item.mod-ecommerce, .home-item.mod-startups {
  display: none; }

.list-clients {
  width: 100%;
  float: left;
  border-top: 1px solid #f2f2f2;
  margin-top: 40px; }
  @media (max-width: 767px) {
    .list-clients {
      border-left: 1px solid #f2f2f2;
      margin-top: 0; } }
  .list-clients li {
    width: 16.66%;
    float: left;
    height: 140px;
    line-height: 140px;
    vertical-align: middle;
    text-align: center;
    border-bottom: 1px solid #f2f2f2; }
    @media (max-width: 991px) {
      .list-clients li {
        width: 33.33%; } }
    @media (max-width: 767px) {
      .list-clients li {
        height: 80px;
        line-height: 80px;
        border-right: 1px solid #f2f2f2; } }
    @media (max-width: 560px) {
      .list-clients li {
        width: 50%; } }
    .list-clients li:nth-child(2n-1) {
      border-right: 1px solid #f2f2f2; }
      @media (max-width: 991px) {
        .list-clients li:nth-child(2n-1) {
          border-right: none; } }
      @media (max-width: 767px) {
        .list-clients li:nth-child(2n-1) {
          border-right: 1px solid #f2f2f2; } }
    .list-clients li:hover img {
      opacity: 1; }
    .list-clients li img {
      opacity: 1;
      transition: opacity .4s ease-in-out;
      vertical-align: middle;
      max-width: 115px; }
      @media (max-width: 560px) {
        .list-clients li img {
          max-width: 100px;
          max-height: 38px; } }

.mod-home-first .container.container-center {
  position: relative; }
  @media (max-width: 767px) {
    .mod-home-first .container.container-center {
      position: static; } }

.play-button {
  position: absolute;
  top: 50%;
  z-index: 9;
  right: 12.5%;
  width: 6.25rem;
  height: 5rem;
  font-size: 1rem;
  cursor: pointer; }
  @media (max-width: 1229px) {
    .play-button {
      margin-top: -50px; } }
  @media (max-width: 1080px) {
    .play-button {
      right: 20%; } }
  @media (max-width: 991px) {
    .play-button {
      right: 24%; } }
  @media (max-width: 767px) {
    .play-button {
      right: auto;
      top: auto;
      left: 10px;
      bottom: 25px; } }
  @media (max-width: 414px) {
    .play-button {
      margin-top: 0;
      height: 3rem;
      left: 0px;
      bottom: 20px; } }
  .play-button .icon-play {
    margin-top: -0.3rem;
    margin-left: -3px;
    font-size: 22px; }
  .play-button > .circle {
    position: relative;
    width: 2.125em;
    height: 2.125em;
    margin: auto; }
    .play-button > .circle > .js-icon-white {
      position: absolute;
      z-index: 5;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 0.75em;
      height: 0.875em;
      margin-top: -0.4375em;
      margin-left: -0.375em;
      display: none;
      margin-left: -0.29em; }
      .play-button > .circle > .js-icon-white .icon-play {
        fill: #ffff32; }
    .play-button > .circle > .black {
      z-index: 4;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 62px;
      height: 62px;
      margin-top: -31px;
      margin-left: -31px;
      opacity: 0.9; }
      .play-button > .circle > .black .js-icon-black {
        position: absolute;
        z-index: 3;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0.75em;
        height: 0.875em;
        margin-top: -0.4375em;
        margin-left: -0.375em;
        margin-left: -0.29em; }
        .play-button > .circle > .black .js-icon-black .icon-play {
          fill: #000000; }
    .play-button > .circle > .yellow {
      position: absolute;
      z-index: 3;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 48px;
      height: 48px;
      margin-top: -24px;
      margin-left: -24px; }
    .play-button > .circle > .border {
      position: absolute;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 72px;
      height: 72px;
      margin-top: -36px;
      margin-left: -36px; }
    .play-button > .circle > .white {
      position: absolute;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 120px;
      height: 120px;
      margin-top: -60px;
      margin-left: -60px;
      opacity: 0.05; }
  .play-button > .text {
    position: relative;
    margin-top: 20px;
    text-transform: uppercase;
    text-align: center;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: bold;
    line-height: 3.75;
    letter-spacing: 0.5px;
    color: #000000; }
    @media (max-width: 767px) {
      .play-button > .text {
        font-size: 12px; } }
    @media (max-width: 414px) {
      .play-button > .text {
        display: none; } }

.fullscreen-video {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  cursor: default;
  height: 100%;
  background: white; }
  .fullscreen-video iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.ytp-chrome-top {
  opacity: 0 !important; }

@media (max-width: 991px) {
  .video-background .close {
    top: 80px;
    z-index: 2;
    color: white;
    right: 30px; } }

@media (max-width: 767px) {
  .video-background .close {
    top: 20px;
    right: 20px; } }

@media (max-width: 991px) {
  .video-background .close .icon {
    color: white; } }

.video-start .video-background {
  pointer-events: all;
  z-index: 99;
  cursor: default; }

.video-background {
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -2;
  pointer-events: none;
  transition: opacity 0.3s ease; }
  @media (max-width: 767px) {
    .video-background {
      display: none; } }
  @media (max-width: 767px) {
    .video-background .video-controls {
      display: none; } }

@media (max-width: 767px) {
  .video-foreground::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 1;
    background-color: black; } }

.video-foreground, .video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white; }

@media (min-aspect-ratio: 16 / 9) {
  .video-foreground {
    height: 300%;
    top: -100%; } }
  @media (min-aspect-ratio: 16 / 9) and (max-width: 991px) {
    .video-foreground {
      height: 100%;
      top: 0; } }

@media (max-aspect-ratio: 16 / 9) {
  .video-foreground {
    width: 300%;
    left: -100%; } }
  @media (max-aspect-ratio: 16 / 9) and (max-width: 991px) {
    .video-foreground {
      width: 100%;
      left: 0; } }

.prices-page .header .lang li.is-active a, .team-page .header .lang li.is-active a, .case-detail .header .lang li.is-active a {
  color: white; }

.prices-page .header .lang li a, .team-page .header .lang li a, .case-detail .header .lang li a {
  color: #949494; }
  .prices-page .header .lang li a:hover, .prices-page .header .lang li a:focus, .team-page .header .lang li a:hover, .team-page .header .lang li a:focus, .case-detail .header .lang li a:hover, .case-detail .header .lang li a:focus {
    color: white; }

.mod-careers .mod-title-section {
  max-width: 790px;
  width: 100%;
  margin-bottom: 30px; }
  @media (max-width: 991px) {
    .mod-careers .mod-title-section {
      max-width: 680px; } }

.mod-careers .h3 {
  margin-bottom: 20px; }

.mod-careers .list-vacancies {
  width: 100%;
  max-width: 100%;
  padding-right: 30px; }
  .mod-careers .list-vacancies li {
    width: 100%; }
  .mod-careers .list-vacancies .content {
    display: none;
    position: relative;
    width: 100%;
    padding-left: 135px;
    padding-bottom: 50px; }
    @media (max-width: 767px) {
      .mod-careers .list-vacancies .content {
        padding-left: 55px; } }
    .mod-careers .list-vacancies .content .content-wrap {
      opacity: 0; }
    .mod-careers .list-vacancies .content .content-text-column {
      column-count: 2;
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.6;
      letter-spacing: 0.2px;
      text-align: left;
      color: #000000;
      column-gap: 50px; }
      @media (max-width: 767px) {
        .mod-careers .list-vacancies .content .content-text-column {
          column-count: 1;
          column-gap: 0;
          font-size: 12px;
          line-height: 1.5; } }
    .mod-careers .list-vacancies .content .el-link {
      position: absolute;
      right: 0;
      bottom: -8px; }

.list-contacts {
  width: 100%;
  display: inline-block;
  padding-left: 135px;
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .list-contacts {
      padding-left: 35px; } }
  @media (max-width: 767px) {
    .list-contacts {
      margin-bottom: 40px; } }
  @media (max-width: 480px) {
    .list-contacts {
      padding-left: 0; } }
  .list-contacts li {
    float: left;
    width: 50%;
    margin-bottom: 40px; }
    @media (max-width: 480px) {
      .list-contacts li {
        width: 100%;
        float: none;
        margin-bottom: 30px; } }
  .list-contacts .title {
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1;
    text-align: left;
    color: #949494;
    margin-bottom: 16px; }
    @media (max-width: 480px) {
      .list-contacts .title {
        margin-bottom: 10px; } }
  .list-contacts .contacts-item, .list-contacts .email span {
    position: relative;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    text-align: left;
    color: #000000;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto; }
  .list-contacts .icon {
    font-size: 17px;
    stroke: #000000;
    stroke-width: 0.5px;
    padding: 0;
    margin: -1px -2px 0; }

.page-body {
  overflow: hidden; }

.mod-title-services {
  margin-bottom: 90px; }

@media (max-width: 900px) {
  .mod-services .text-style-paragraph.mod-col-left {
    font-size: 12px;
    line-height: 1.5; } }

.services-section {
  width: 100%; }
  .services-section:not(:last-child) {
    margin-bottom: 120px; }
    @media (max-width: 767px) {
      .services-section:not(:last-child) {
        margin-bottom: 90px; } }
  .services-section.mod-right .services-section-text {
    float: right; }
    @media (max-width: 600px) {
      .services-section.mod-right .services-section-text {
        float: none; } }
  .services-section.mod-right .services-section-image {
    float: left; }
    @media (max-width: 600px) {
      .services-section.mod-right .services-section-image {
        float: none; } }
  .services-section .services-section-text {
    float: left;
    width: calc(100% - 430px); }
    @media (max-width: 900px) {
      .services-section .services-section-text {
        width: calc(100% - 330px); } }
    @media (max-width: 600px) {
      .services-section .services-section-text {
        float: none;
        width: 100%;
        max-width: 400px; } }
    @media (max-width: 600px) {
      .services-section .services-section-text .el-bg-text {
        bottom: -10px; } }
  .services-section .content-section .el-link {
    margin-left: 141px; }
    @media (max-width: 991px) {
      .services-section .content-section .el-link {
        margin-left: 45px; } }
    @media (max-width: 767px) {
      .services-section .content-section .el-link {
        margin-left: 10px; } }
  .services-section .text-style-paragraph.mod-col-left {
    float: none;
    margin-bottom: 10px; }
  .services-section .services-section-image {
    float: right;
    width: 400px;
    position: relative;
    height: 400px;
    margin-top: 10px;
    object-fit: contain; }
    @media (max-width: 900px) {
      .services-section .services-section-image {
        width: 300px;
        height: 300px; } }
    @media (max-width: 767px) {
      .services-section .services-section-image {
        margin-top: 0; } }
    @media (max-width: 600px) {
      .services-section .services-section-image {
        float: none;
        height: auto;
        width: 100%;
        margin-bottom: 35px; } }
    @media (max-width: 600px) {
      .services-section .services-section-image .img-wrap {
        width: 100%;
        height: 220px; } }
    @media (max-width: 600px) {
      .services-section .services-section-image .img-wrap img {
        object-fit: cover;
        width: 100%;
        height: 220px; } }
    .services-section .services-section-image .line-wrap {
      position: absolute;
      left: 30px;
      top: -60px;
      z-index: 1;
      display: inline-block;
      pointer-events: none;
      width: 60px;
      height: 120px; }
      .services-section .services-section-image .line-wrap .block-revealer__content {
        width: 100%;
        height: 100%; }
      .services-section .services-section-image .line-wrap .line-cont {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: #ffff32;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); }

.mod-cases .mod-title-section {
  max-width: 660px; }
  .mod-cases .mod-title-section .el-bg-text {
    bottom: auto;
    top: 80px; }
    @media (max-width: 767px) {
      .mod-cases .mod-title-section .el-bg-text {
        top: 20px; } }

.case-section {
  display: inline-block;
  width: 100%;
  position: relative; }
  .case-section:not(:last-child) {
    margin-bottom: 60px; }
    @media (max-width: 767px) {
      .case-section:not(:last-child) {
        margin-bottom: 40px; } }
  .case-section .text-case {
    position: absolute;
    left: 0;
    top: 35px; }
    @media (max-width: 767px) {
      .case-section .text-case {
        position: static; } }
    .case-section .text-case .type,
    .case-section .text-case .name a,
    .case-section .text-case .text,
    .case-section .text-case .el-link {
      position: relative;
      z-index: 1; }
    .case-section .text-case .type {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 11px;
      font-weight: normal;
      line-height: 2.0;
      text-align: left;
      color: #000000;
      white-space: nowrap;
      margin-bottom: 10px; }
    .case-section .text-case .name {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.33;
      text-align: left;
      color: #000000;
      white-space: nowrap;
      margin-bottom: 30px; }
      @media (max-width: 767px) {
        .case-section .text-case .name {
          margin-bottom: 15px;
          font-size: 24px; } }
      .case-section .text-case .name a:hover {
        text-decoration: none; }
      .case-section .text-case .name .el-bg-text {
        bottom: auto;
        top: 55px; }
    .case-section .text-case .text {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 11px;
      font-weight: normal;
      line-height: 2.0;
      letter-spacing: -0.2px;
      text-align: left;
      color: #000000;
      margin-bottom: 40px; }
      @media (max-width: 767px) {
        .case-section .text-case .text {
          display: none; } }
    .case-section .text-case .el-link {
      margin-left: 6px; }
      @media (max-width: 767px) {
        .case-section .text-case .el-link {
          display: none; } }
  .case-section .image-case {
    float: right;
    max-width: 820px;
    display: inline-block;
    margin: 40px 0; }
    @media (max-width: 1030px) {
      .case-section .image-case {
        max-width: 720px; } }
    @media (max-width: 930px) {
      .case-section .image-case {
        max-width: 620px; } }
    @media (max-width: 830px) {
      .case-section .image-case {
        max-width: 550px; } }
    @media (max-width: 767px) {
      .case-section .image-case {
        max-width: 100%;
        margin: 0; } }
    .case-section .image-case:hover {
      text-decoration: none; }
      .case-section .image-case:hover .img-wrap img {
        transform: scale3d(1.1, 1.1, 1); }
    .case-section .image-case .img-wrap {
      opacity: 0;
      display: inline-block;
      width: 100%;
      height: 100%;
      position: relative;
      object-fit: contain; }
      .case-section .image-case .img-wrap div.block-revealer__content {
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
        overflow: hidden; }
      .case-section .image-case .img-wrap img {
        background-color: rgba(0, 0, 0, 0.03);
        transition: transform 1s; }
  .case-section .line-wrap {
    display: inline-block;
    position: absolute;
    left: 180px;
    top: 0;
    pointer-events: none;
    width: 140px;
    height: 480px; }
    @media (max-width: 1030px) {
      .case-section .line-wrap {
        left: 280px;
        width: 100px;
        height: 435px; } }
    @media (max-width: 930px) {
      .case-section .line-wrap {
        height: 385px; } }
    @media (max-width: 830px) {
      .case-section .line-wrap {
        height: 350px;
        left: 223px; } }
    @media (max-width: 767px) {
      .case-section .line-wrap {
        display: none; } }
    .case-section .line-wrap .block-revealer__content {
      width: 100%;
      height: 100%;
      display: inline-block; }
  .case-section .line {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-color: #ffff32; }

.case-detail-content {
  padding-top: 80px;
  padding-bottom: 20px; }
  @media (max-width: 991px) {
    .case-detail-content {
      padding-top: 60px; } }
  @media (max-width: 767px) {
    .case-detail-content {
      padding-bottom: 0; } }
  .case-detail-content .list-contacts .email {
    color: #000000; }
  .case-detail-content .list-contacts .el-link-sm:before {
    background-color: #949494; }
  @media (max-width: 991px) {
    .case-detail-content .mod-col-fluid {
      padding-left: 40px; } }
  @media (max-width: 767px) {
    .case-detail-content .mod-col-fluid {
      padding-left: 7px; } }
  .case-detail-content .content-section p:last-child {
    margin-bottom: 0; }

.img-case {
  width: 100%;
  margin: 60px 0; }
  @media (max-width: 991px) {
    .img-case {
      margin: 30px 0; } }

.mod-case-detail {
  display: block;
  padding-top: 100vh; }

.case-detail-first {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 110px 0 118px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center; }
  .case-detail-first .lines {
    position: absolute;
    border-color: #000000; }
    .case-detail-first .lines:before, .case-detail-first .lines:after {
      border-color: #000000; }
  .case-detail-first .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -2; }
    .case-detail-first .bg-img:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.6); }
  .case-detail-first h1,
  .case-detail-first .h1 {
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 120px;
    font-weight: bold;
    line-height: 0.75;
    letter-spacing: -3px;
    text-align: center;
    color: white;
    margin-bottom: 30px; }
    @media (max-width: 991px) {
      .case-detail-first h1,
      .case-detail-first .h1 {
        font-size: 100px; } }
    @media (max-width: 767px) {
      .case-detail-first h1,
      .case-detail-first .h1 {
        font-size: 60px; } }
    @media (max-width: 560px) {
      .case-detail-first h1,
      .case-detail-first .h1 {
        font-size: 40px; } }
  .case-detail-first .text-style-paragraph {
    margin: 0 auto;
    max-width: 320px;
    color: white;
    text-align: center; }

.animated .img-case {
  opacity: 1; }

.case-detail-section-tech {
  padding: 40px 0; }

.tech-icons {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0 0;
  filter: invert(1); }
  .tech-icons img {
    max-width: 120px;
    height: auto; }
    @media (max-width: 600px) {
      .tech-icons img {
        max-width: 100px; } }
    @media (max-width: 600px) {
      .tech-icons img {
        max-width: 80px; } }

.text-justify {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 120px;
  flex-wrap: wrap; }
  @media (max-width: 991px) {
    .text-justify {
      padding: 0 80px; } }
  @media (max-width: 768px) {
    .text-justify {
      padding: 0 30px; } }
  @media (max-width: 450px) {
    .text-justify {
      padding: 0 10px; } }
  .text-justify .link-fill {
    opacity: 1;
    transition: all 0.5s ease; }
    @media (max-width: 768px) {
      .text-justify .link-fill {
        margin: 10px 0; } }
    @media (max-width: 450px) {
      .text-justify .link-fill {
        order: 1;
        margin: 0 auto 20px; } }
    .text-justify .link-fill:hover {
      transform: translateY(10px); }

.list-contacts.mod-m-t {
  margin-top: 30px; }

.page-wrapper .navBtn {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.4px;
  color: #111;
  transition: all 0.5s ease;
  position: relative; }
  @media (max-width: 991px) {
    .page-wrapper .navBtn {
      font-size: 20px; } }
  @media (max-width: 450px) {
    .page-wrapper .navBtn {
      padding-left: 20px;
      order: 2;
      width: 50%;
      font-size: 14px;
      margin: 10px 0 70px; } }
  .page-wrapper .navBtn:after {
    content: '';
    border-bottom: 1px solid #90a1b5;
    border-right: 1px solid #90a1b5;
    display: block;
    height: 15px;
    margin-top: -5px;
    pointer-events: none;
    position: absolute;
    right: -40px;
    top: 50%;
    transition: all 0.15s ease-in-out;
    width: 15px;
    transform: rotate(-45deg);
    transition: all 0.5s ease; }
    @media (max-width: 768px) {
      .page-wrapper .navBtn:after {
        right: -30px; } }
    @media (max-width: 450px) {
      .page-wrapper .navBtn:after {
        right: -10px;
        height: 10px;
        width: 10px;
        margin-top: -4px; } }
  .page-wrapper .navBtn:hover {
    text-decoration: none;
    opacity: 0.5; }
  .page-wrapper .navBtn_next {
    text-align: right; }
    @media (max-width: 450px) {
      .page-wrapper .navBtn_next {
        padding-right: 20px; } }
  .page-wrapper .navBtn_prev {
    text-align: left; }
    .page-wrapper .navBtn_prev:after {
      left: -40px;
      transform: rotate(135deg);
      margin-top: -7px; }
      @media (max-width: 768px) {
        .page-wrapper .navBtn_prev:after {
          left: -30px; } }
      @media (max-width: 450px) {
        .page-wrapper .navBtn_prev:after {
          left: -10px;
          margin-top: -5px; } }

.page-404 h3,
.page-404 .h3 {
  position: relative;
  z-index: 2;
  text-align: center;
  margin-bottom: 10px; }

.page-404 h1,
.page-404 .h1 {
  position: relative;
  z-index: 2;
  text-align: center;
  width: 100%; }
  @media (max-width: 767px) {
    .page-404 h1,
    .page-404 .h1 {
      font-size: 36px; } }
  .page-404 h1 .block-revealer__content, .page-404 h1 .js-anim-h1,
  .page-404 .h1 .block-revealer__content,
  .page-404 .h1 .js-anim-h1 {
    display: inline-block;
    text-align: center; }

.page-404 .btn-wrap {
  display: inline-block;
  position: relative;
  margin-top: 120px; }

.page-404 .el-btn {
  position: relative;
  z-index: 2; }

.page-404 .block-revealer__element {
  z-index: 2; }

.page-404 .error-text-bg {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 200px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 4px;
  text-align: left;
  z-index: 1;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.03); }
  @media (max-width: 767px) {
    .page-404 .error-text-bg {
      font-size: 140px; } }

.page-404 canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0; }

@media (max-width: 767px) {
  .page-404 .page-body {
    display: flex; } }

.list-awards {
  width: 100%;
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f2f2f2;
  margin-top: 40px;
  float: left;
  margin-bottom: 100px; }
  @media (max-width: 767px) {
    .list-awards {
      margin-bottom: 40px;
      margin-top: 0;
      border-left: 1px solid #f2f2f2; } }
  @media (max-width: 520px) {
    .list-awards {
      flex-direction: column; } }
  .list-awards.mod-recognitions {
    flex-wrap: wrap; }
    .list-awards.mod-recognitions .item-awards {
      flex-basis: 100%;
      min-width: 100%; }
      .list-awards.mod-recognitions .item-awards .ico-wrap {
        height: 280px;
        flex-direction: column;
        border-bottom: none; }
        .list-awards.mod-recognitions .item-awards .ico-wrap img {
          max-width: 160px;
          max-height: 160px;
          -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
    .list-awards.mod-recognitions .item-awards-wrap {
      flex-basis: 33.333%;
      min-width: 33.333%; }
      @media (max-width: 767px) {
        .list-awards.mod-recognitions .item-awards-wrap {
          flex-basis: 50%;
          min-width: 50%; } }
      @media (max-width: 520px) {
        .list-awards.mod-recognitions .item-awards-wrap {
          flex-basis: 100%;
          min-width: 100%; } }
      @media (max-width: 767px) {
        .list-awards.mod-recognitions .item-awards-wrap.mod-number {
          display: flex; } }
  .list-awards .item-awards-wrap {
    flex-basis: 33.333%;
    min-width: 33.333%; }
    @media (max-width: 767px) {
      .list-awards .item-awards-wrap {
        flex-basis: 50%;
        min-width: 50%; } }
    @media (max-width: 520px) {
      .list-awards .item-awards-wrap {
        flex-basis: 100%;
        min-width: 100%; } }
    .list-awards .item-awards-wrap.mod-number {
      position: relative;
      height: 280px;
      border-bottom: 1px solid #f2f2f2;
      justify-content: center;
      align-items: center;
      display: flex; }
      @media (max-width: 767px) {
        .list-awards .item-awards-wrap.mod-number {
          display: none; } }
    .list-awards .item-awards-wrap.mod-two {
      display: flex;
      flex-direction: row; }
  .list-awards .item-awards {
    flex-basis: 50%;
    min-width: 50%; }
    @media (max-width: 767px) {
      .list-awards .item-awards {
        border-right: 1px solid #f2f2f2; } }
    .list-awards .item-awards:not(:last-child) {
      border-right: 1px solid #f2f2f2; }
    .list-awards .item-awards .ico-wrap {
      height: 140px;
      border-bottom: 1px solid #f2f2f2;
      justify-content: center;
      align-items: center;
      display: flex; }
      .list-awards .item-awards .ico-wrap .item__title__awards {
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        line-height: 1.4;
        letter-spacing: 0.4px;
        text-transform: capitalize;
        margin: 10px 0 0 0;
        max-width: 220px; }
      .list-awards .item-awards .ico-wrap img {
        max-width: 75px;
        max-height: 75px; }
        .list-awards .item-awards .ico-wrap img.mod-3 {
          max-width: 80px; }
        .list-awards .item-awards .ico-wrap img.mod-4 {
          max-width: 60px;
          max-height: 60px; }
    .list-awards .item-awards .description-wrap {
      height: 140px;
      border-bottom: 1px solid #f2f2f2;
      justify-content: flex-start;
      align-items: center;
      display: flex;
      flex-direction: column;
      padding-top: 20px; }
      .list-awards .item-awards .description-wrap .description__num {
        font-size: 40px;
        font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
        color: #000000;
        font-weight: normal;
        margin-bottom: 10px;
        margin-left: auto;
        margin-right: auto;
        display: block; }
      .list-awards .item-awards .description-wrap p {
        display: inline-block;
        padding-left: 5px;
        font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.23;
        letter-spacing: 0.4px;
        text-align: center;
        color: #000000; }
        @media (max-width: 767px) {
          .list-awards .item-awards .description-wrap p {
            text-align: center;
            font-size: 12px; } }
  .list-awards .number-awards {
    position: absolute;
    font-size: 220px;
    font-weight: bold;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    color: black;
    letter-spacing: -2px;
    padding-top: 30px; }
    @media (max-width: 991px) {
      .list-awards .number-awards {
        font-size: 170px; } }

.testimonials-wrap {
  width: 100%;
  float: left;
  margin-bottom: 100px;
  text-align: center;
  padding: 0 40px; }
  @media (max-width: 767px) {
    .testimonials-wrap {
      margin-bottom: 40px; } }
  .testimonials-wrap .slick-list, .testimonials-wrap .slick-track, .testimonials-wrap .slick-slide, .testimonials-wrap .slick-slide > div {
    height: 100% !important; }
  .testimonials-wrap .slick-slide > div {
    justify-content: center;
    align-items: center;
    display: flex; }
  .testimonials-wrap .slick-arrow {
    width: 20px;
    height: 20px;
    border-top: 1px solid #949494;
    transition: all 0.2s;
    margin-top: -13px;
    color: transparent; }
    .testimonials-wrap .slick-arrow:hover {
      border-color: #000000; }
  .testimonials-wrap .slick-prev {
    left: 20px;
    border-left: 1px solid #949494;
    transform: rotate(-45deg); }
    @media (max-width: 767px) {
      .testimonials-wrap .slick-prev {
        left: 0; } }
  .testimonials-wrap .slick-next {
    right: 20px;
    border-right: 1px solid #949494;
    transform: rotate(45deg); }
    @media (max-width: 767px) {
      .testimonials-wrap .slick-next {
        right: 0; } }
  .testimonials-wrap .testimonial-item {
    width: 100%;
    max-width: 540px;
    text-align: center;
    display: inline-block; }
  .testimonials-wrap .text {
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: 0.4px;
    color: #000000;
    margin-bottom: 30px; }
    @media (max-width: 767px) {
      .testimonials-wrap .text {
        font-size: 12px;
        line-height: 1.5; } }
  .testimonials-wrap .name {
    text-decoration: none;
    display: block;
    width: 100%;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.85;
    letter-spacing: 0.5px;
    color: #000000;
    transition: color 0.2s; }
  .testimonials-wrap .author-position {
    text-decoration: none;
    display: block;
    width: 100%;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 13px;
    font-weight: normal;
    line-height: 1.85;
    letter-spacing: 0.4px;
    color: #000000;
    transition: color 0.2s; }

.video-showreel {
  width: 100%;
  position: relative;
  padding-bottom: 54%;
  background-color: white;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 100px; }
  @media (max-width: 991px) {
    .video-showreel {
      margin-bottom: 30px; } }
  .video-showreel iframe {
    border: 1px solid #f2f2f2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.founder-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 40px auto 100px;
  float: left; }
  @media (max-width: 767px) {
    .founder-container {
      margin-bottom: 40px;
      margin-top: 0; } }
  @media (max-width: 690px) {
    .founder-container {
      flex-direction: column; } }
  .founder-container .h2, .founder-container h2 {
    color: white;
    margin: 20px;
    opacity: 0;
    transition: opacity 1s; }
  .founder-container .icon {
    opacity: 0;
    transition: opacity 1s;
    stroke: #fff;
    width: 16px;
    height: 16px; }
  .founder-container .icons {
    position: absolute;
    fill: #fff;
    color: #fff;
    height: 130px;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; }

.border {
  height: 369px;
  width: 290px;
  background: transparent;
  border-radius: 2px;
  transition: border 0.2s;
  position: relative;
  border: 1px solid transparent; }
  .border:hover {
    border: 1px solid rgba(255, 255, 255, 0.4); }

.card {
  height: 380px;
  width: 300px;
  margin: 0 10px;
  background: grey;
  border-radius: 2px;
  transition: background 0.8s;
  overflow: hidden;
  background: black;
  box-shadow: 0 60px 50px -60px #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  @media (max-width: 690px) {
    .card {
      margin: 10px 0px; } }

.card0 {
  background: url("../img/team/1_1.png") center center no-repeat;
  background-size: 600px; }
  .card0:hover {
    background: url("../img/team/1_1.png") -180px center no-repeat;
    background-size: 900px; }
    .card0:hover .h2, .card0:hover h2 {
      opacity: 1; }
    .card0:hover .icon {
      opacity: 1; }

.card1 {
  background: url("../img/team/7.jpg") center center no-repeat;
  background-size: 600px; }
  .card1:hover {
    background: url("../img/team/7.jpg") -180px center no-repeat;
    background-size: 900px; }
    .card1:hover .h2, .card1:hover h2 {
      opacity: 1; }
    .card1:hover .icon {
      opacity: 1; }

.card2 {
  background: url("../img/team/11.jpg") center center no-repeat;
  background-size: 600px; }
  .card2:hover {
    background: url("../img/team/11.jpg") -180px center no-repeat;
    background-size: 900px; }
    .card2:hover .h2, .card2:hover h2 {
      opacity: 1; }
    .card2:hover .icon {
      opacity: 1; }

.mod-brief {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .mod-brief .el-textarea__label {
    margin: 0;
    font-weight: 500;
    width: 100%; }
  .mod-brief .form_block .form_fieldset_structure {
    margin-bottom: 20px; }
  .mod-brief .brief_title {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative; }
    @media (max-width: 991px) {
      .mod-brief .brief_title {
        max-width: 680px; } }
  .mod-brief .h2 {
    font-size: 30px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    left: 0; }
    .mod-brief .h2:after {
      content: attr(data-title);
      position: absolute;
      font-size: 150px;
      font-weight: bold;
      letter-spacing: 2.5px;
      color: rgba(0, 0, 0, 0.03);
      bottom: -170px;
      left: 50%;
      transform: translateX(-50%); }
      @media (max-width: 991px) {
        .mod-brief .h2:after {
          font-size: 80px; } }
  .mod-brief .h6 {
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 0.2px;
    color: #000000;
    text-align: center;
    font-weight: 100;
    transition: all 0.5s ease;
    left: 0; }
  .mod-brief .form {
    max-width: 650px;
    margin: 20px auto;
    position: relative; }
    .mod-brief .form fieldset:not(:first-of-type) {
      display: none; }
    .mod-brief .form_imgCover {
      padding: 20px 0;
      background: rgba(0, 0, 0, 0.03);
      display: block;
      width: 100px;
      height: 100px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center; }
    .mod-brief .form_title {
      color: #111;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      padding-left: 20px;
      margin-bottom: 0px; }
      .mod-brief .form_title span {
        color: #000000;
        text-transform: lowercase; }
    .mod-brief .form_subtitle {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.38px;
      color: #000000;
      padding: 10px 0 0 20px; }
    .mod-brief .form .el-input__label-content,
    .mod-brief .form .el-textarea__label-content {
      position: absolute;
      color: #949494;
      text-align: left; }
    .mod-brief .form .el-textarea {
      height: 100px; }
    .mod-brief .form .el-textarea__field,
    .mod-brief .form .input__field {
      color: black; }
    .mod-brief .form_fieldsetCover {
      position: relative;
      width: 400px;
      margin: 0 auto; }
      @media (max-width: 450px) {
        .mod-brief .form_fieldsetCover {
          width: 350px;
          margin-bottom: 100px; } }
      @media (max-width: 400px) {
        .mod-brief .form_fieldsetCover {
          width: 100%; } }
    .mod-brief .form_fieldset {
      position: relative;
      left: 0; }
    .mod-brief .form_fieldset_i {
      position: relative;
      left: 0; }
      .mod-brief .form_fieldset_i:after {
        content: "";
        background-color: #fff;
        display: block;
        position: absolute;
        bottom: -105px;
        right: -5%;
        width: 105%;
        height: 150px;
        transform: rotate(-11deg); }
    .mod-brief .form_fieldset_sizes label {
      width: 80px;
      height: 80px;
      margin: 0 5px; }
      @media (max-width: 400px) {
        .mod-brief .form_fieldset_sizes label {
          width: 65px;
          height: 65px;
          margin: 0 3px; } }
      .mod-brief .form_fieldset_sizes label .form_imgCover {
        width: 80px;
        height: 80px;
        padding: 20px 0; }
        @media (max-width: 400px) {
          .mod-brief .form_fieldset_sizes label .form_imgCover {
            width: 65px;
            height: 65px;
            padding: 10px 0; } }
    @media (max-width: 400px) {
      .mod-brief .form_fieldset_identity .form_imgCover {
        width: 80px;
        height: 80px;
        padding: 15px 0; } }
    @media (max-width: 400px) {
      .mod-brief .form_fieldset_identity label {
        width: 80px;
        height: 80px;
        margin: 0 5px; } }
    .mod-brief .form_fieldset_structure .form_block {
      justify-content: space-around; }
    .mod-brief .form_fieldset_structure label {
      min-width: 110px;
      padding: 0 10px;
      height: 50px;
      box-shadow: 0 10px 35px -15px rgba(152, 152, 152, 0.5);
      font-weight: 100;
      text-transform: uppercase;
      line-height: 50px;
      background-color: rgba(0, 0, 0, 0.03); }
      @media (max-width: 450px) {
        .mod-brief .form_fieldset_structure label {
          min-width: 100px; } }
      @media (max-width: 450px) {
        .mod-brief .form_fieldset_structure label .form_label {
          font-size: 10px; } }
    .mod-brief .form_fieldset_project .el-input__field {
      color: #000; }
    .mod-brief .form_fieldset_project .el-input__label-content {
      width: 200px; }
    .mod-brief .form_fieldset_project .form_block {
      flex-direction: column; }
    .mod-brief .form_fieldset_project .form_block-2 {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row; }
      .mod-brief .form_fieldset_project .form_block-2 label {
        width: 165px;
        height: 50px;
        box-shadow: 0 10px 35px -15px rgba(152, 152, 152, 0.5);
        font-weight: 100;
        text-transform: uppercase;
        line-height: 50px; }
        @media (max-width: 400px) {
          .mod-brief .form_fieldset_project .form_block-2 label {
            width: 120px; } }
    .mod-brief .form_fieldset_message .label {
      display: block; }
    .mod-brief .form_fieldset_message .label, .mod-brief .form_fieldset_message .icon {
      color: #000; }
    .mod-brief .form_fieldset_contacts .form_block {
      display: block;
      flex-direction: columns; }
    .mod-brief .form_fieldset_contacts .el-input__label {
      position: absolute;
      left: 0;
      color: #949494; }
    .mod-brief .form_fieldset_contacts input {
      width: 100%;
      font-weight: 500;
      color: #000000; }
    .mod-brief .form_fieldset_contacts .graphic {
      stroke: #000; }
    .mod-brief .form_fieldset_result .form_title {
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #a8a8a8; }
    .mod-brief .form_fieldset_result .form_subtitle {
      text-align: center;
      font-size: 14px;
      line-height: 1.64;
      text-align: center;
      color: #000000;
      padding-top: 30px; }
    .mod-brief .form_fieldset_result .action-button img {
      width: 50%; }
    .mod-brief .form_fieldset_sites .el-textarea {
      margin: 10px 5px; }
    .mod-brief .form_fieldset_sites .el-input__field {
      max-height: 70px;
      padding-left: 20px; }
    .mod-brief .form_fieldset_sites .el-input {
      margin: 20px 0; }
    .mod-brief .form_fieldset_sites .graphic {
      bottom: 0; }
    .mod-brief .form_fieldset_message .el-input__label {
      margin: 0; }
    @media (max-width: 535px) {
      .mod-brief .form_fieldset_message .el-file-input {
        width: 90%; } }
    .mod-brief .form_block-file {
      display: flex;
      align-items: flex-start;
      flex-direction: column; }
      .mod-brief .form_block-file .wrap-file {
        width: 100%; }
      .mod-brief .form_block-file .close {
        top: 15px;
        right: 0; }
        @media (max-width: 535px) {
          .mod-brief .form_block-file .close {
            top: 10px; } }
      .mod-brief .form_block-file .labe-file {
        color: #949494;
        font-size: 10px;
        text-transform: uppercase;
        padding-left: 5px;
        margin-top: 10px; }
      .mod-brief .form_block-file .label {
        font-size: 12px;
        text-transform: uppercase;
        max-width: 150px; }
    .mod-brief .form_bg {
      width: 400px;
      height: 410px;
      margin: 0 auto;
      display: block;
      position: relative; }
      @media (max-width: 450px) {
        .mod-brief .form_bg {
          width: 350px; } }
      @media (max-width: 400px) {
        .mod-brief .form_bg {
          width: 95%;
          height: auto; } }
    .mod-brief .form_section {
      position: absolute;
      z-index: 2;
      top: 50%;
      display: flex;
      flex-direction: column;
      left: -65px;
      transform: translateY(-50%);
      background: #fff;
      padding: 25px 20px;
      width: 415px;
      min-height: 210px;
      box-shadow: 0px 8px 10px 4px rgba(0, 0, 0, 0.06);
      border-radius: 5px; }
      @media (max-width: 550px) {
        .mod-brief .form_section {
          left: 0; } }
      @media (max-width: 450px) {
        .mod-brief .form_section {
          left: -15px;
          width: 370px;
          padding: 15px 10px; } }
      @media (max-width: 400px) {
        .mod-brief .form_section {
          width: 100%; } }
      @media (max-width: 360px) {
        .mod-brief .form_section {
          left: 0; } }
    .mod-brief .form_block {
      display: flex;
      align-items: center;
      flex-grow: 1; }
      .mod-brief .form_block .form_group:first-child label {
        margin-left: 5px; }
      .mod-brief .form_block .el-input.el-text-fel.el-input--filled {
        margin-left: 5px;
        width: calc( 100% - 10px); }
      .mod-brief .form_block .el-input.el-text-fel, .mod-brief .form_block .el-textarea.el-text-fel {
        margin-left: 5px;
        width: calc( 100% - 10px); }
    .mod-brief .form_label {
      text-align: center;
      color: #b9b9b9;
      font-size: 12px;
      display: block;
      margin-top: 6px; }
  .mod-brief .el-input__label {
    margin: 0;
    width: 100%; }
  .mod-brief label {
    display: block;
    position: relative;
    cursor: pointer;
    width: 100px;
    margin: 0 10px;
    text-align: center; }
    .mod-brief label:before {
      background-color: white;
      color: white;
      content: "";
      display: block;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 28px;
      transition-duration: 0.4s;
      transform: scale(0);
      font-size: 10px;
      line-height: 20px; }
    .mod-brief label img {
      transition-duration: 0.2s;
      transform-origin: 50% 50%;
      opacity: 0.3;
      position: relative;
      z-index: 2; }

input:checked + label {
  border-color: #ddd; }

input:checked + label:before {
  content: "";
  background-image: url(../img/svg/check.svg);
  transform: scale(1);
  background-size: cover; }

input:checked + label img {
  transform: scale(0.9);
  opacity: 1; }

input:checked + label .form_imgCover {
  background: #ffff32; }

input:checked + label .form_label {
  color: #000000; }

input:checked + label.form_label {
  background: #ffff32;
  font-weight: 600;
  color: #000000; }

input[type='checkbox'], input[type='radio'] {
  opacity: 0 !important; }

.progressbar {
  position: relative;
  left: 0; }
  .progressbar:after {
    content: '';
    width: calc(100% - 130px);
    height: 2px;
    background: #efefef;
    position: absolute;
    left: 65px;
    top: 27px;
    pointer-events: none;
    z-index: -2; }
  .progressbar_item {
    position: absolute;
    top: 23px;
    font-size: 12px;
    color: #000000;
    font-weight: 100; }
  .progressbar_result {
    right: 0;
    color: #d8d8d8; }
  .progressbar_start {
    left: 0; }
  .progressbar_block {
    overflow: hidden;
    counter-reset: step;
    text-align: center;
    margin: 30px auto 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 55px; }
    @media (max-width: 750px) {
      .progressbar_block {
        width: 80%; } }
    @media (max-width: 750px) {
      .progressbar_block {
        width: 75%; } }
  .progressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    width: 33%;
    float: left;
    position: relative;
    letter-spacing: 1px; }
    .progressbar li:first-child:after {
      display: none; }
    .progressbar li:before {
      content: counter(step);
      counter-increment: step;
      width: 0;
      height: 20px;
      line-height: 24px;
      display: block;
      font-size: 12px;
      color: #333;
      background: white;
      margin: 0 auto;
      opacity: 0;
      transition: 0.5s ease; }
    .progressbar li:after {
      content: '';
      width: 100%;
      height: 2px;
      background: #efefef;
      position: absolute;
      left: -50%;
      top: 9px;
      transition: 0.5s ease;
      z-index: -1; }
    .progressbar li span {
      width: 10px;
      height: 10px;
      display: block;
      transform: translate(-50%, -50%) rotate(45deg);
      top: 50%;
      position: absolute;
      left: 50%;
      transition: 0.5s ease; }
    .progressbar li p {
      position: absolute;
      display: none;
      color: rebeccapurple;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      color: #000;
      opacity: 0;
      transition: all 0.5s ease;
      padding: 7px 0 0;
      text-align: center;
      font-weight: 900;
      font-family: inherit; }
      @media (max-width: 400px) {
        .progressbar li p {
          font-size: 8px; } }
    .progressbar li.active:before {
      opacity: 1; }
    .progressbar li:first-child:after {
      content: ""; }
    .progressbar li.active span {
      background: #000; }
    .progressbar li.active p {
      display: block;
      opacity: 1; }
    .progressbar li.active:last-child span {
      width: 20px;
      height: 20px;
      background: #ffff32; }
      .progressbar li.active:last-child span:after {
        content: '';
        background: #fff;
        width: 10px;
        height: 10px;
        display: block;
        transform: rotate(45deg);
        top: 50%;
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0); }
    .progressbar li.active:before, .progressbar li.active:after {
      background: #000;
      color: white; }

.progressbar_block li.current span {
  width: 20px;
  height: 20px; }
  .progressbar_block li.current span:after {
    content: '';
    background: #fff;
    width: 10px;
    height: 10px;
    display: block;
    transform: rotate(45deg);
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0); }

.action-button {
  width: 70px;
  height: 70px;
  background: #ffff32;
  border-radius: 100%;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  box-shadow: -3px 17px 14px rgba(177, 177, 177, 0.46);
  position: absolute;
  z-index: 3;
  right: 15px;
  bottom: 55px; }
  @media (max-width: 550px) {
    .action-button {
      right: -25px; } }
  @media (max-width: 400px) {
    .action-button {
      right: 0;
      width: 60px;
      height: 60px;
      bottom: 5px; } }
  .action-button img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.brief_imgCover img {
  height: 100%; }

.m-b-0 {
  margin-bottom: 0 !important; }

.brief {
  max-width: 650px;
  margin: 20px auto;
  position: relative;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .brief_bg {
    left: 0; }
  .brief_block {
    width: 400px;
    height: 410px;
    margin: 0 auto;
    display: block;
    position: relative;
    left: 0; }
    @media (max-width: 450px) {
      .brief_block {
        width: 350px;
        margin-bottom: 100px; } }
    @media (max-width: 400px) {
      .brief_block {
        width: 95%;
        height: auto; } }
    .brief_block_fieldset_i {
      position: relative;
      left: 0; }
      .brief_block_fieldset_i:after {
        content: "";
        background-color: #fff;
        display: block;
        position: absolute;
        bottom: -105px;
        right: -5%;
        width: 105%;
        height: 150px;
        transform: rotate(-11deg); }
    @media (max-width: 400px) {
      .brief_block .action-button {
        bottom: 20px; } }
  .brief_section {
    position: absolute;
    top: 50%;
    left: -65px;
    transform: translateY(-50%);
    background: #fff;
    padding: 25px 20px;
    width: 400px;
    height: 210px;
    box-shadow: 0px 8px 10px 4px rgba(0, 0, 0, 0.06);
    border-radius: 5px; }
    @media (max-width: 550px) {
      .brief_section {
        left: 0; } }
    @media (max-width: 450px) {
      .brief_section {
        left: -15px;
        width: 370px;
        padding: 30px 5px; } }
    @media (max-width: 400px) {
      .brief_section {
        width: 95%; } }
    @media (max-width: 360px) {
      .brief_section {
        left: 0; } }
  .brief_titles {
    color: #111;
    font-size: 11px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 20px; }
  .brief_links {
    display: flex;
    align-items: center;
    margin: 20px 5px; }
    .brief_links a {
      text-decoration: none;
      text-align: center; }
  .brief_group {
    margin-right: 20px;
    text-align: center;
    position: relative; }
    .brief_group.active .brief_imgCover {
      background: #ffff32; }
    .brief_group.active .brief_imgCover:before {
      content: "";
      background-image: url(../img/svg/check.svg);
      transform: scale(1);
      background-size: cover;
      width: 20px;
      display: block;
      height: 20px;
      position: absolute;
      right: -5px;
      top: -5px; }
  .brief_imgCover {
    padding: 25px 0;
    background: rgba(0, 0, 0, 0.03);
    display: block;
    width: 100px;
    height: 100px; }
  .brief_label {
    display: block;
    font-size: 12px;
    margin-top: 6px; }

.prices-page {
  position: relative;
  background: #000;
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .prices-page .page-body .container {
    padding: 0; }
  .prices-page .header {
    background: #000; }
    .prices-page .header:before {
      background: none; }
  .prices-page .logo svg .jet {
    fill: #fff; }
  .prices-page .title-page .title {
    color: #fff; }
  .prices-page .btn-menu:before,
  .prices-page .page-open .btn-menu:after,
  .prices-page .page-open .btn-menu .line {
    background: #fff; }
  .prices-page .title-page .menu-number {
    color: #181818; }
  .prices-page .btn-menu:hover .menu-number {
    color: #282828; }
  .prices-page .social a {
    color: #fff; }
  .prices-page .email {
    color: #fff; }
    .prices-page .email .icon {
      stroke: #fff; }
  .prices-page .el-link-sm:before {
    background: #ffff32; }
  .prices-page .social.hovered li a:hover {
    color: #949494; }
  .prices-page .lines {
    border-right: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
    opacity: 0.1; }
  .prices-page .circle-cursor--outer {
    border: 1px solid #fff; }
  .prices-page .circle-cursor--inner {
    background: #fff; }
  .prices-page .bgText {
    font-size: 300px;
    font-weight: bold;
    letter-spacing: 6px;
    color: #181818;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(30%);
    z-index: -1;
    opacity: 0.5; }
    @media (max-width: 767px) {
      .prices-page .bgText {
        font-size: 200px; } }
  .prices-page .prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0; }
    @media (max-width: 650px) {
      .prices-page .prices {
        flex-direction: column; } }
    .prices-page .prices_lt {
      color: #fff;
      width: 70%;
      padding-right: 10px; }
      @media (max-width: 1200px) {
        .prices-page .prices_lt {
          width: 60%; } }
      @media (max-width: 950px) {
        .prices-page .prices_lt {
          width: 50%; } }
      @media (max-width: 650px) {
        .prices-page .prices_lt {
          width: 90%; } }
      @media (max-width: 535px) {
        .prices-page .prices_lt {
          width: 100%; } }
    .prices-page .prices_rt {
      width: 30%; }
      @media (max-width: 1200px) {
        .prices-page .prices_rt {
          width: 40%; } }
      @media (max-width: 950px) {
        .prices-page .prices_rt {
          width: 50%; } }
      @media (max-width: 650px) {
        .prices-page .prices_rt {
          width: 90%; } }
      @media (max-width: 535px) {
        .prices-page .prices_rt {
          width: 100%; } }
    .prices-page .prices_title {
      padding-left: 15px; }
      @media (max-width: 736px) {
        .prices-page .prices_title {
          padding-left: 0; } }
      .prices-page .prices_title h2 {
        color: #fff; }
        @media (max-width: 736px) {
          .prices-page .prices_title h2 {
            padding: 30px 0;
            padding-left: 0; } }
    .prices-page .prices .text {
      max-width: 650px;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 0.1px;
      color: #fff;
      opacity: 0.8; }
      @media (max-width: 950px) {
        .prices-page .prices .text {
          font-size: 16px;
          line-height: 2; } }
      @media (max-width: 535px) {
        .prices-page .prices .text {
          font-size: 12px; } }
    .prices-page .prices_list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 6px; }
      .prices-page .prices_list li {
        font-size: 14px;
        font-weight: 500;
        line-height: 2.8;
        letter-spacing: 0.4px;
        border-radius: 100px;
        border: solid 1px #979797;
        padding: 0 18px;
        margin: 10px;
        box-sizing: border-box;
        transition: 0.5s ease;
        cursor: pointer; }
        @media (max-width: 950px) {
          .prices-page .prices_list li {
            font-size: 14px;
            margin: 5px; } }
        @media (max-width: 535px) {
          .prices-page .prices_list li {
            font-size: 12px; } }
        .prices-page .prices_list li:hover {
          border: solid 1px #ffff32;
          color: #ffff32;
          transform: scale(0.97); }
        .prices-page .prices_list li.active {
          border: solid 1px #ffff32;
          color: #000000;
          background-color: #ffff32;
          transform: scale(0.97); }
    .prices-page .prices_featureTitle {
      font-size: 22px;
      font-weight: 500;
      line-height: 2.05;
      letter-spacing: 1.22px;
      margin-top: 20px;
      padding-left: 15px; }
    .prices-page .prices_featureSubtitle {
      max-width: 650px;
      opacity: 0.8;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 0.1px;
      margin-top: 20px;
      padding-left: 15px; }
      @media (max-width: 535px) {
        .prices-page .prices_featureSubtitle {
          font-size: 12px;
          padding-left: 0; } }
    .prices-page .prices .form {
      width: 370px;
      box-shadow: 0 10px 35px -15px rgba(255, 255, 50, 0.8);
      background-color: #ffff32;
      padding: 24px 30px;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      margin-top: -30px; }
      @media (max-width: 950px) {
        .prices-page .prices .form {
          width: 350px;
          padding: 10px 20px;
          right: 6%; } }
      @media (max-width: 835px) and (max-height: 411px) {
        .prices-page .prices .form {
          height: 390px;
          overflow-y: auto; } }
      @media (max-width: 812px) and (max-height: 375px) {
        .prices-page .prices .form {
          height: 360px; } }
      @media (max-width: 767px) {
        .prices-page .prices .form {
          right: 2%;
          width: 320px; } }
      @media (max-width: 650px) {
        .prices-page .prices .form {
          margin: 20px auto;
          display: block;
          width: 100%;
          height: auto;
          position: relative;
          right: 0;
          top: 0;
          transform: none;
          z-index: 2; } }
      @media (max-width: 535px) {
        .prices-page .prices .form {
          padding: 20px 2 0px; } }
      .prices-page .prices .form_title, .prices-page .prices .form_titleLoad {
        font-size: 16px; }
      .prices-page .prices .form_price {
        line-height: 30px; }
      .prices-page .prices .form_titleBlock {
        font-size: 26px;
        font-weight: 900;
        line-height: 1.8;
        letter-spacing: 0.2px;
        color: #000; }
      .prices-page .prices .form_service {
        padding: 12px 0; }
        @media (max-width: 950px) {
          .prices-page .prices .form_service {
            padding: 10px 0; } }
        .prices-page .prices .form_service li {
          font-size: 18px;
          line-height: 1.7;
          letter-spacing: 0.3px;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          @media (max-width: 950px) {
            .prices-page .prices .form_service li {
              line-height: 25px; } }
          @media (max-width: 767px) {
            .prices-page .prices .form_service li {
              font-size: 18px; } }
          @media (max-width: 535px) {
            .prices-page .prices .form_service li {
              font-size: 16px; } }
      .prices-page .prices .form_serviceName {
        font-weight: 100; }
        @media (max-width: 950px) {
          .prices-page .prices .form_serviceName {
            font-size: 16px; } }
      .prices-page .prices .form_servicePrice {
        font-weight: 900; }
        @media (max-width: 950px) {
          .prices-page .prices .form_servicePrice {
            font-size: 18px; } }
      .prices-page .prices .form_load {
        font-size: 25px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: 0; }
      .prices-page .prices .form_input {
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;
        display: block;
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        margin: 10px 0;
        text-align: left;
        font-size: 18px;
        padding: 0 40px 0 15px; }
      @media (max-width: 535px) {
        .prices-page .prices .form_input {
          font-size: 16px; } }
      .prices-page .prices .form_input::-webkit-input-placeholder {
        color: #000; }
      .prices-page .prices .form_input::-moz-placeholder {
        color: #000; }
      .prices-page .prices .form_input:-ms-input-placeholder {
        color: #000; }
      .prices-page .prices .form_input:-moz-placeholder {
        color: #000; }
      .prices-page .prices .form_btn {
        font-weight: 600;
        transition: 0.5s ease; }
        .prices-page .prices .form_btn:hover {
          cursor: pointer; }
  .prices-page .knobs, .prices-page .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer; }
  .prices-page .button {
    position: relative;
    top: 50%;
    width: 64px;
    height: 26px;
    margin: 0 auto 0 auto; }
    .prices-page .button.r {
      border-radius: 100px; }
      .prices-page .button.r .layer {
        border-radius: 100px; }
    .prices-page .button.b2 {
      border-radius: 2px; }
  .prices-page .toggle-button-cover {
    padding-top: 35px; }
  .prices-page .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3; }
  .prices-page .knobs {
    z-index: 2; }
  .prices-page .layer {
    width: 100%;
    background-color: #181818;
    transition: 0.3s ease all;
    z-index: 1; }
    .prices-page .layer::before {
      content: "Web Site";
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.4px;
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      color: #ffff32;
      font-size: 16px;
      position: absolute;
      left: -95px;
      min-width: 80px; }
    .prices-page .layer::after {
      content: "Application";
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.4px;
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      color: white;
      font-size: 16px;
      position: absolute;
      right: -115px; }
  .prices-page #button-3 .knobs:before {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 18px;
    height: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #ffff32;
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15); }
  .prices-page #button-3 .checkbox:active + .knobs:before {
    width: 46px;
    border-radius: 100px; }
  .prices-page #button-3 .checkbox:checked:active + .knobs:before {
    margin-left: -28px; }
  .prices-page #button-3 .checkbox:checked + .knobs:before {
    content: '';
    left: 42px;
    background-color: #ffff32; }
  .prices-page #button-3 .checkbox:checked ~ .layer {
    background-color: #181818; }
    .prices-page #button-3 .checkbox:checked ~ .layer::before {
      color: white; }
    .prices-page #button-3 .checkbox:checked ~ .layer:after {
      color: #ffff32; }
  .prices-page .tooltip {
    display: inline-block;
    min-width: 180px;
    text-align: center; }
    .prices-page .tooltip .tooltiptext {
      background-color: white;
      color: #000000;
      box-shadow: 0 10px 35px -15px black;
      box-sizing: border-box;
      width: 120%;
      min-width: 150px;
      text-align: center;
      border-radius: 10px;
      position: absolute;
      top: 110%;
      left: -10%;
      z-index: 99;
      opacity: 0;
      visibility: hidden; }
    .prices-page .tooltip.active .tooltiptext {
      color: black; }
    .prices-page .tooltip:hover .tooltiptext {
      opacity: 1;
      visibility: visible;
      transition: 0.5s ease, opacity  0.3s ease 1s; }

.wrap-input-price {
  position: relative; }
  .wrap-input-price .el-text-fel {
    position: relative; }
    .wrap-input-price .el-text-fel.error {
      outline: 1px solid red; }
  .wrap-input-price b.error {
    position: absolute;
    bottom: -22px;
    font-size: 10px;
    right: 0;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: normal;
    text-align: right;
    color: red; }
  .wrap-input-price .form_btn {
    position: absolute;
    right: 6px;
    top: 3px;
    font-size: 26px; }
    .wrap-input-price .form_btn:hover {
      transform: scale(1.1);
      opacity: 0.8; }

.mod-policy {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif; }
  .mod-policy .bgTitle {
    font-size: 150px;
    font-weight: bold;
    letter-spacing: 2.5px;
    color: rgba(0, 0, 0, 0.03);
    position: absolute; }
  .mod-policy .policy {
    position: relative; }
    .mod-policy .policy_subname {
      padding: 30px 0; }
    .mod-policy .policy_titleBlock {
      position: relative; }
    .mod-policy .policy_mtitle {
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 0.4px;
      text-align: center;
      max-width: 460px;
      width: 100%;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; }
      @media (max-width: 767px) {
        .mod-policy .policy_mtitle {
          margin-top: 20px;
          font-size: 40px; } }
      @media (max-width: 485px) {
        .mod-policy .policy_mtitle {
          font-size: 38px; } }
      .mod-policy .policy_mtitle span {
        font-size: 15px;
        line-height: 1.33;
        letter-spacing: 0.27px;
        display: block;
        font-weight: 100;
        text-align: left;
        padding-left: 10px; }
        @media (max-width: 485px) {
          .mod-policy .policy_mtitle span {
            text-align: center; } }
    .mod-policy .policy_list {
      margin-right: 20px;
      padding-left: 20px;
      list-style-type: decimal;
      max-width: 300px; }
      @media (max-width: 880px) {
        .mod-policy .policy_list {
          max-width: 230px; } }
      @media (max-width: 550px) {
        .mod-policy .policy_list {
          max-width: 100%; } }
      .mod-policy .policy_list li {
        padding-left: 10px;
        opacity: 0.5;
        transition: 0.5s ease; }
        .mod-policy .policy_list li:hover {
          opacity: 1; }
        .mod-policy .policy_list li a {
          text-decoration: none;
          font-size: 16px;
          font-weight: bold;
          line-height: 1.5;
          letter-spacing: 0;
          margin-bottom: 10px;
          display: block;
          letter-spacing: 0;
          color: #111; }
          @media (max-width: 400px) {
            .mod-policy .policy_list li a {
              font-size: 14px; } }
        .mod-policy .policy_list li.active {
          opacity: 1; }
    .mod-policy .policy ul {
      list-style-type: disc; }
    .mod-policy .policy_title {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.53;
      text-align: left;
      padding: 50px 0;
      color: #000000; }
      @media (max-width: 991px) {
        .mod-policy .policy_title {
          font-size: 26px; } }
      @media (max-width: 767px) {
        .mod-policy .policy_title {
          font-size: 18px;
          padding: 80px 0 40px; } }
      @media (max-width: 475px) {
        .mod-policy .policy_title {
          margin-top: 50px; } }
      @media (max-width: 400px) {
        .mod-policy .policy_title {
          margin-top: 0;
          padding: 70px 0 20px;
          font-size: 16px; } }
    .mod-policy .policy_block {
      display: flex;
      justify-content: space-between; }
      @media (max-width: 550px) {
        .mod-policy .policy_block {
          flex-direction: column; } }
    .mod-policy .policy_lt {
      width: 340px; }
      @media (max-width: 550px) {
        .mod-policy .policy_lt {
          width: 100%;
          display: none;
          padding-bottom: 50px; } }
      @media (max-width: 400px) {
        .mod-policy .policy_lt {
          padding-bottom: 20px; } }
    .mod-policy .policy_rt {
      width: 70%; }
      @media (max-width: 550px) {
        .mod-policy .policy_rt {
          width: 100%; } }
    .mod-policy .policy_part {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.85;
      letter-spacing: 0.2px;
      color: #000000;
      margin-bottom: 40px; }
      @media (max-width: 767px) {
        .mod-policy .policy_part {
          font-size: 12px;
          line-height: 1.6;
          margin-bottom: 30px; } }
      .mod-policy .policy_part a {
        font-weight: 900;
        text-decoration: none;
        transition: all 0.5s ease; }
        .mod-policy .policy_part a:hover {
          opacity: 0.5; }
      .mod-policy .policy_part ul li {
        text-indent: 40px; }
    .mod-policy .policy ol {
      list-style-type: decimal;
      padding-left: 20px;
      padding-top: 15px; }
    .mod-policy .policy_name {
      text-decoration: none;
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 16px;
      font-weight: bold;
      line-height: 3.75;
      letter-spacing: 0.5px;
      color: #000000;
      transition: color 0.2s; }
  .mod-policy .rectangle-yellow-wrap {
    opacity: 1; }
  .mod-policy .rectangle-yellow-wrap {
    width: 300px;
    height: 300px;
    left: 0;
    right: 0;
    margin: 0; }
    @media (max-width: 767px) {
      .mod-policy .rectangle-yellow-wrap {
        display: none; } }
    @media (max-width: 485px) {
      .mod-policy .rectangle-yellow-wrap {
        left: 50%;
        transform: translate(-50%); } }
  .mod-policy .group-lines .line-4 {
    opacity: 1; }
    @media (max-width: 768px) {
      .mod-policy .group-lines .line-4 {
        width: 50px;
        height: 100px;
        left: 250px;
        top: 30px; } }
  .mod-policy .item-expertise {
    margin-left: auto;
    position: relative;
    display: block;
    min-height: 300px; }
    @media (max-width: 767px) {
      .mod-policy .item-expertise {
        display: none; } }
    @media (max-width: 485px) {
      .mod-policy .item-expertise {
        margin: 0 auto; } }

.mod-team {
  background-color: #000000; }
  @media (max-width: 1229px) {
    .mod-team {
      padding: 80px 0 40px; } }
  .mod-team .photo-user-container {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    left: 0;
    top: 0; }
    @media (max-width: 767px) {
      .mod-team .photo-user-container {
        pointer-events: all; } }
    .mod-team .photo-user-container .photo-user-wrap {
      width: 1440px;
      max-height: 940px;
      height: 840px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      opacity: 0;
      margin-left: -80px; }
      @media (max-height: 768px) {
        .mod-team .photo-user-container .photo-user-wrap {
          height: 760px; } }
      @media (max-height: 660px) {
        .mod-team .photo-user-container .photo-user-wrap {
          height: 660px; } }
      @media (min-width: 1460px) and (min-height: 768px) {
        .mod-team .photo-user-container .photo-user-wrap {
          height: 940px;
          margin-left: -220px; } }
      @media (max-width: 860px) {
        .mod-team .photo-user-container .photo-user-wrap {
          margin-left: 0; } }
      @media (max-width: 767px) {
        .mod-team .photo-user-container .photo-user-wrap {
          margin-left: 15px;
          height: 70vh;
          top: -20px;
          transform: translate(-50%, 0); } }
      .mod-team .photo-user-container .photo-user-wrap img {
        height: 100%; }
        @media (max-width: 767px) {
          .mod-team .photo-user-container .photo-user-wrap img {
            display: inline-block; } }
  .mod-team .slick-list, .mod-team .slick-track, .mod-team .slick-slide, .mod-team .slick-slide > div {
    height: 100% !important; }
  .mod-team .slick-slide > div {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 5px; }
  .mod-team .text-block-item {
    position: relative;
    z-index: 1; }
    @media (max-width: 767px) {
      .mod-team .text-block-item {
        position: absolute;
        bottom: 90px;
        left: 0px; } }
  .mod-team .text-block {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0; }
    @media (max-width: 1300px) {
      .mod-team .text-block {
        left: -50px; } }
    @media (max-width: 1100px) {
      .mod-team .text-block {
        left: 0; } }
    @media (max-width: 767px) {
      .mod-team .text-block {
        max-width: 100%;
        min-height: 35vh; } }
    @media (max-width: 420px) {
      .mod-team .text-block {
        max-width: 100%;
        min-height: 25vh; } }
    .mod-team .text-block .slick-list {
      overflow: visible; }
    .mod-team .text-block .user-name {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 100px;
      font-weight: bold;
      letter-spacing: 1.7px;
      color: #ffffff;
      margin-bottom: 15px;
      line-height: 1;
      margin-left: -6px;
      opacity: 0; }
      @media (max-height: 768px) {
        .mod-team .text-block .user-name {
          font-size: 80px;
          margin-bottom: 15px; } }
      @media (max-height: 660px) {
        .mod-team .text-block .user-name {
          margin-bottom: 5px; } }
      @media (max-width: 1180px) {
        .mod-team .text-block .user-name {
          font-size: 80px; } }
      @media (max-width: 991px) {
        .mod-team .text-block .user-name {
          font-size: 60px; } }
      @media (max-width: 767px) {
        .mod-team .text-block .user-name {
          font-size: 40px;
          padding-left: 17px;
          padding-right: 17px; } }
    .mod-team .text-block .position {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 28px;
      line-height: 44px;
      color: #ffffff;
      margin-bottom: 30px;
      text-transform: none;
      opacity: 0; }
      @media (max-height: 768px) {
        .mod-team .text-block .position {
          font-size: 20px;
          line-height: 1.2;
          margin-bottom: 25px; } }
      @media (max-height: 660px) {
        .mod-team .text-block .position {
          margin-bottom: 20px; } }
      @media (max-width: 1180px) {
        .mod-team .text-block .position {
          font-size: 22px;
          line-height: 1.4; } }
      @media (max-width: 991px) {
        .mod-team .text-block .position {
          font-size: 20px;
          line-height: 1.4; } }
      @media (max-width: 767px) {
        .mod-team .text-block .position {
          font-size: 16px;
          padding-left: 12px;
          padding-right: 12px; } }
      .mod-team .text-block .position b {
        font-weight: 500; }
    .mod-team .text-block .user-text {
      font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 0.2px;
      color: #ffffff;
      opacity: 0; }
      @media (max-height: 766px) {
        .mod-team .text-block .user-text {
          line-height: 1.6; } }
      @media (max-width: 767px) {
        .mod-team .text-block .user-text {
          font-size: 12px;
          padding-left: 12px;
          padding-right: 12px; } }
      .mod-team .text-block .user-text b {
        font-weight: bold; }

@media (max-width: 767px) {
  body {
    overflow: hidden; } }

@media (max-width: 767px) {
  body.disable-container-center, body.case-detail, body.prices-page {
    overflow: scroll; } }

.team-page {
  overflow: hidden; }
  .team-page .el-bg-text {
    color: rgba(0, 0, 0, 0.06);
    z-index: 0;
    top: 10%;
    left: auto;
    bottom: auto;
    right: 22%;
    width: auto; }
  .team-page .team-right-menu {
    display: none !important; }
  .team-page .number-wrap {
    position: fixed;
    right: 50px;
    top: 50%;
    color: #181818;
    display: inline-block;
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-size: 40px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: -2px;
    transform: translateY(-50%); }
    @media (max-width: 1229px) {
      .team-page .number-wrap {
        right: auto;
        left: 50px; } }
    @media (max-width: 991px) {
      .team-page .number-wrap {
        transform: translateY(0);
        transform: translateX(-50%);
        left: 50%;
        top: auto;
        bottom: 20px; } }
    @media (max-width: 767px) {
      .team-page .number-wrap {
        transform: translateX(0);
        left: 30px;
        font-size: 30px;
        bottom: 26px; } }
    .team-page .number-wrap .current-number {
      color: white;
      display: inline-block;
      min-width: 54px;
      text-align: center; }
    .team-page .number-wrap .all-number {
      font-size: 30px; }
      @media (max-width: 767px) {
        .team-page .number-wrap .all-number {
          font-size: 20px; } }

.page-wrapper.page-open.blogPage.article .mod-case-detail {
  padding-top: 0px !important; }

.blog-pg.mod-case-detail {
  padding: 0 0 !important; }
  .blog-pg.mod-case-detail .case-detail-first {
    position: relative;
    height: 345px;
    padding-top: 0;
    padding-bottom: 30px;
    display: flex;
    align-items: flex-end; }
    .blog-pg.mod-case-detail .case-detail-first h1 {
      text-align: left; }

.bl-tabs {
  display: flex;
  padding-top: 40px; }
  .bl-tabs--tab {
    color: #fff;
    margin-right: 46px;
    font-size: 16px;
    line-height: 15px;
    letter-spacing: .32px;
    padding-bottom: 11px;
    position: relative; }
    .bl-tabs--tab:hover:before {
      transition: width .3s;
      width: 95%; }
    .bl-tabs--tab:before {
      position: absolute;
      content: '';
      transition: width .3s;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: #FFFF33; }

.bl-tabs--tab.active {
  color: #fff; }
  .bl-tabs--tab.active:before {
    transition: width .3s;
    width: 95%; }

.blog-tabs-content-wr {
  padding: 0 160px; }

.blog-tabs-content-wr--item {
  display: none; }

.blog-tabs-content-wr--item.active {
  display: block; }

.blog-tabs-content-wr--item .row .col-md-4 {
  border-top: 1px solid #e1e1e1; }

.blog-tabs-content-wr--item .row .col-md-4:nth-child(1), .blog-tabs-content-wr--item .row .col-md-4:nth-child(2), .blog-tabs-content-wr--item .row .col-md-4:nth-child(3) {
  border-top: 0; }

.article-pre-wr {
  position: relative; }

.article-pre {
  margin: 80px 0;
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .article-pre:before {
    content: '';
    position: absolute;
    background: #fff;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
    opacity: 0;
    transition: opacity .3s;
    z-index: -1; }
  .article-pre:hover:before {
    content: '';
    opacity: 1; }
  .article-pre--label {
    color: #000000;
    font-size: 16px;
    line-height: 25px;
    opacity: 0.300000011920929;
    padding-bottom: 15px;
    width: 100%; }
  .article-pre--title {
    font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
    font-weight: bold;
    font-size: 35px;
    line-height: 56px;
    padding-bottom: 70px;
    display: block; }
    .article-pre--title:hover {
      text-decoration: none; }
  .article-pre--text {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 40px; }
  .article-pre--read {
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: right;
    position: relative;
    padding-bottom: 13px;
    display: block; }
    .article-pre--read:hover {
      text-decoration: none; }
    .article-pre--read:hover:after {
      transition: width .3s;
      width: 100%; }
    .article-pre--read:after {
      transition: width .3s;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: 24px;
      height: 3px;
      background: #FFFF33; }

.blogPage path.jet {
  fill: #fff; }

.blogPage path.jet.black {
  fill: #000; }

.page-wrapper.page-open.blogPage.article .js-anim-btn-menu .line.black {
  background: #000; }

.page-wrapper.page-open.blogPage.article .js-anim-btn-menu .title.black {
  color: #000; }

.blogPage .js-anim-email.black {
  color: #000; }

.blogPage .js-anim-email {
  color: #fff; }

.blog-pg .case-detail-first h1 {
  margin-bottom: 0;
  font-size: 60px; }

.page-wrapper.page-open.blogPage.article h1 {
  font-size: 60px;
  line-height: 70px;
  text-align: left;
  margin-bottom: 102px; }

.page-wrapper.page-open.blogPage.article .case-detail-first {
  height: 700px;
  padding-top: 303px;
  position: relative; }

.page-wrapper.page-open.blogPage.article .mod-case-detail {
  padding-top: 0;
  padding-bottom: 0px;
  background: #fff;
  width: 100vw;
  max-width: 100vw;
  margin: auto;
  transition: 1s;
  transform: scale(1) translateY(0px); }

.page-wrapper.page-open.blogPage.article .mod-case-detail.active {
  transform: scale(0.84) translateY(200px); }

.article-author .top {
  display: flex; }
  .article-author .top .el {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 16px;
    opacity: 0.6000000238418579;
    margin-right: 31px;
    font-weight: 500; }

.article-author .bottom {
  display: flex;
  margin-top: 40px; }
  .article-author .bottom .item {
    display: flex;
    align-items: center;
    margin-right: 30px; }
    .article-author .bottom .item img {
      height: 50px;
      width: 50px;
      border-radius: 50px;
      margin-right: 20px; }
    .article-author .bottom .item .left .name {
      color: #FFFFFF;
      font-size: 18px;
      line-height: 16px;
      margin-bottom: 10px;
      font-weight: 500; }
    .article-author .bottom .item .left .status {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 15px;
      opacity: 0.6000000238418579;
      font-weight: normal; }

.page-wrapper.page-open.blogPage.article .article-content {
  padding: 80px 0 40px; }
  .page-wrapper.page-open.blogPage.article .article-content h1 {
    margin: 60px 0 30px; }
  .page-wrapper.page-open.blogPage.article .article-content h2 {
    font-size: 32px;
    line-height: 48px;
    margin: 60px 0 30px; }
  .page-wrapper.page-open.blogPage.article .article-content h3 {
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    margin-bottom: 30px; }
  .page-wrapper.page-open.blogPage.article .article-content h4 {
    font-size: 24px; }
  .page-wrapper.page-open.blogPage.article .article-content p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 30px; }
  .page-wrapper.page-open.blogPage.article .article-content a {
    color: #3390ff; }
  .page-wrapper.page-open.blogPage.article .article-content img {
    margin-bottom: 30px; }
  .page-wrapper.page-open.blogPage.article .article-content em {
    position: relative;
    padding-left: 15px;
    display: block;
    font-size: 18px;
    line-height: 28px;
    font-style: normal;
    margin: 60px 0 60px; }
    .page-wrapper.page-open.blogPage.article .article-content em:before {
      position: absolute;
      content: '';
      width: 5px;
      height: 100%;
      background: #FFFF33;
      left: 0; }
  .page-wrapper.page-open.blogPage.article .article-content nav {
    margin: 80px 0 60px;
    display: flex;
    justify-content: space-between; }
    .page-wrapper.page-open.blogPage.article .article-content nav a {
      padding: 10px 30px;
      color: #000000;
      background: #F7F7F7;
      font-size: 18px;
      line-height: 22px;
      text-align: center;
      transition: .5s; }
      .page-wrapper.page-open.blogPage.article .article-content nav a:hover {
        text-decoration: none; }
      .page-wrapper.page-open.blogPage.article .article-content nav a:active {
        background: #FFFF33;
        box-shadow: inset 0 0 100px #ffff33; }
  .page-wrapper.page-open.blogPage.article .article-content ul {
    padding-left: 40px; }
    .page-wrapper.page-open.blogPage.article .article-content ul li {
      list-style-type: decimal;
      line-height: 28px; }
  .page-wrapper.page-open.blogPage.article .article-content .call-to-action {
    display: flex;
    align-items: center;
    padding: 20px;
    border-left: 5px solid #ffff33; }
    .page-wrapper.page-open.blogPage.article .article-content .call-to-action h4 {
      margin-bottom: 20px; }
    .page-wrapper.page-open.blogPage.article .article-content .call-to-action p {
      font-size: 16px;
      padding-right: 50px; }

.article-content-wr {
  position: relative; }

.article-menu.fix {
  position: fixed;
  top: 70px; }

.article-menu {
  position: absolute;
  right: 210px;
  top: 770px;
  z-index: 9; }
  .article-menu .el {
    color: #949494;
    font-size: 16px;
    line-height: 15px;
    padding: 10px 0;
    text-align: right;
    font-weight: 500;
    display: block;
    transition: .5s;
    border-right: 0px solid #FFFF33; }
    .article-menu .el:hover {
      text-decoration: none;
      color: #111;
      padding-right: 10px;
      border-right: 2px solid #FFFF33; }
  .article-menu .el.active {
    color: #111;
    padding-right: 10px;
    border-right: 2px solid #FFFF33; }

.article-footer {
  padding: 10px 0 80px;
  transition: 1s;
  background-color: #F7F7F7;
  position: relative;
  z-index: 1; }
  .article-footer .container {
    max-width: 830px; }
  .article-footer .title {
    font-size: 25px;
    line-height: 40px;
    font-weight: 500;
    margin-bottom: 30px; }
  .article-footer .articles {
    display: flex;
    justify-content: space-between; }
    .article-footer .articles .articles-article {
      width: 308px; }
      .article-footer .articles .articles-article .title {
        display: block;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px; }
        .article-footer .articles .articles-article .title:hover {
          text-decoration-color: #318efd; }
      .article-footer .articles .articles-article .text {
        font-size: 12px;
        line-height: 22px;
        font-weight: normal;
        margin-bottom: 30px; }
      .article-footer .articles .articles-article .bott {
        display: flex;
        justify-content: space-between; }
        .article-footer .articles .articles-article .bott .date {
          color: #000000;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 12px;
          opacity: 0.5;
          font-weight: normal; }
        .article-footer .articles .articles-article .bott .read {
          color: #000;
          font-size: 14px;
          letter-spacing: 0.5px;
          line-height: 17px;
          text-align: right;
          position: relative;
          padding-bottom: 13px;
          display: block;
          font-weight: bold; }
          .article-footer .articles .articles-article .bott .read:hover {
            text-decoration: none; }
          .article-footer .articles .articles-article .bott .read:hover:after {
            transition: width .3s;
            width: 100%; }
          .article-footer .articles .articles-article .bott .read:after {
            transition: width .3s;
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            width: 24px;
            height: 3px;
            background: #FFFF33; }

.article-wrr {
  position: relative;
  z-index: 1; }

.case-detail-first .bg-img {
  z-index: 0; }

.lines {
  z-index: 0; }

.page-wrapper.page-open.blogPage.article {
  font-family: "GothamPro", Arial, "Helvetica Neue", Helvetica, sans-serif;
  background-color: #F7F7F7;
  overflow: hidden; }
  .page-wrapper.page-open.blogPage.article .social {
    display: none; }
  .page-wrapper.page-open.blogPage.article .social.active {
    display: flex; }
  .page-wrapper.page-open.blogPage.article .js-anim-btn-menu .line {
    background: #fff; }
  .page-wrapper.page-open.blogPage.article .js-anim-btn-menu .title {
    color: #fff; }

.blog-sl {
  overflow: hidden;
  height: 100vh; }
  .blog-sl .case-detail-first {
    padding-top: 264px; }
    .blog-sl .case-detail-first h1 {
      text-align: left;
      font-size: 60px;
      line-height: 70px;
      margin-bottom: 39px; }

body.team-page .blog-sl .lines {
  border-color: #000; }
  body.team-page .blog-sl .lines:before {
    border-color: #000; }

.article-tag {
  color: #FFF;
  width: 100%;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 28px;
  opacity: 0.699999988079071;
  margin-bottom: 10px;
  text-transform: uppercase; }

.blog-slider {
  position: relative;
  z-index: 4;
  max-width: 671px; }

.blog-slider-slide {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }
  .blog-slider-slide h1 {
    width: 100%; }
  .blog-slider-slide .text {
    color: #FFFFFF;
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    padding-left: 148px;
    margin-bottom: 41px; }
  .blog-slider-slide .read-more {
    color: #FFF;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 17px;
    text-align: right;
    position: relative;
    padding-bottom: 13px;
    display: block; }
    .blog-slider-slide .read-more:hover {
      text-decoration: none; }
    .blog-slider-slide .read-more:hover:after {
      transition: width .3s;
      width: 100%; }
    .blog-slider-slide .read-more:after {
      transition: width .3s;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      width: 24px;
      height: 3px;
      background: #FFFF33; }

.page-wrapper .blog-slider button {
  bottom: -190px;
  top: initial;
  border: 1.5px solid #FFFFFF;
  width: 53px;
  height: 53px;
  background: none; }
  .page-wrapper .blog-slider button:hover {
    background-color: #FFFF33;
    border: 1.5px solid #FFFF33; }
  .page-wrapper .blog-slider button:before {
    content: 'Tips for becoming real UX Expert. Let’s do it!';
    position: absolute;
    color: #FFFFFF;
    font-size: 18px;
    line-height: 28px;
    width: 202px;
    text-align: left; }
  .page-wrapper .blog-slider button:hover:before {
    content: '';
    position: absolute;
    height: 1px;
    width: 118px;
    background: #fff; }

.page-wrapper .blog-slider button.slick-prev {
  left: 145px; }
  .page-wrapper .blog-slider button.slick-prev:before {
    right: -225px;
    top: 0; }
  .page-wrapper .blog-slider button.slick-prev:hover:before {
    right: -140px;
    top: initial; }

.page-wrapper .blog-slider button.slick-next {
  background: url("../img/content/right-arrow.png") center no-repeat; }
  .page-wrapper .blog-slider button.slick-next:before {
    left: -205px;
    top: 0; }
  .page-wrapper .blog-slider button.slick-next:hover:before {
    left: -140px;
    top: initial; }

@media (max-width: 1900px) {
  .article-menu {
    display: none; } }

@media (max-width: 1500px) {
  .article-menu.fix {
    top: 200px; }
  .article-content {
    padding: 80px 100px 40px 0; } }

@media (max-width: 1300px) {
  .article-pre {
    margin: 60px 0;
    padding: 0 5px; }
  .article-pre--title {
    margin-bottom: 40px;
    padding-bottom: 0px; }
  .article-menu {
    display: none; } }

@media (max-width: 1230px) {
  .blogPage path.jet {
    fill: #000; }
  .blogPage .js-anim-email {
    color: #000; } }

@media (max-width: 1080px) {
  .blog-tabs-content-wr {
    padding: 0 10px; }
  .blog-pg .lines {
    width: calc((100% - 10px)*.3333333); }
  .justBlog #header .lines {
    width: 100%; }
  .justBlog #header .lines:before {
    width: calc((100% - 10px)*.3333333); } }

@media (max-width: 1000px) {
  .blog-pg .lines {
    border-color: #fff; } }

@media (max-width: 780px) {
  .article-pre {
    margin: 50px 0; }
  .article-pre--title {
    font-size: 30px;
    line-height: 48px; }
  .article-pre--text {
    padding-bottom: 25px; }
  .blog-tabs-content-wr--item.active .row {
    display: flex;
    flex-wrap: wrap; }
    .blog-tabs-content-wr--item.active .row .col-md-4:nth-child(1n) {
      border-right: 1px solid #e1e1e1; }
    .blog-tabs-content-wr--item.active .row .col-md-4:nth-child(2n) {
      border-right: 0px solid #e1e1e1; }
  .blog-tabs-content-wr--item .row .col-md-4 {
    border-top: 0px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1; }
  .page-wrapper.page-open.blogPage.article h1 {
    font-size: 50px; }
  .page-wrapper.page-open.blogPage.article .case-detail-first {
    height: auto;
    padding: 200px 0 60px; }
  .page-wrapper.page-open.blogPage.article .article-content p {
    margin-bottom: 20px; }
  .page-wrapper.page-open.blogPage.article .article-content {
    padding: 30px 0 30px; } }

@media (max-width: 750px) {
  .page-wrapper.page-open.blogPage.article .article-content .call-to-action {
    border-left: 0; }
  .page-wrapper a.article-pre--title {
    line-height: 48px;
    width: 100%;
    font-size: 28px; }
  .blog-tabs-content-wr--item.active .row .col-md-4:nth-child(1n) {
    border-right: 0px solid #e1e1e1; }
  .blog-pg.mod-case-detail .case-detail-first {
    height: auto;
    padding-top: 160px;
    padding-bottom: 25px; }
  .blog-pg.mod-case-detail .case-detail-first h1 {
    text-align: left;
    margin-bottom: -110px; }
  .bl-tabs {
    padding-top: 40px;
    text-align: right;
    flex-direction: column;
    align-items: flex-end; }
  .bl-tabs--tab {
    width: fit-content;
    margin-right: 0;
    margin-bottom: 7px; }
  .bl-tabs--tab:before {
    transition: width 1s; }
  .bl-tabs--tab.active:before {
    width: 120%;
    transition: width .3s;
    right: 0;
    left: inherit; }
  .page-wrapper.page-open.blogPage.article h1 {
    font-size: 40px;
    margin-bottom: 50px; }
  .page-wrapper.page-open.blogPage.article .case-detail-first {
    padding: 130px 0 40px; }
  .article-author .top .el {
    font-size: 16px; }
  .article-author .bottom .item img {
    margin-right: 15px; }
  .article-author .bottom .item {
    margin-right: 20px; }
  .article-author .bottom .item .left .status {
    font-size: 14px; }
  .article-author .bottom .item img {
    height: 40px;
    width: 40px; }
  .page-wrapper.page-open.blogPage.article .article-content h1, .page-wrapper.page-open.blogPage.article .article-content h2 {
    margin: 30px 0 15px; }
  .page-wrapper.page-open.blogPage.article .article-content a {
    font-size: 16px; }
  .page-wrapper.page-open.blogPage.article .article-content h2 {
    font-size: 28px; }
  .page-wrapper.page-open.blogPage.article .article-content h3 {
    font-size: 22px; }
  .page-wrapper.page-open.blogPage.article .article-content h4 {
    font-size: 18px; }
  .page-wrapper.page-open.blogPage.article .article-content em {
    margin: 30px 0 30px; }
  .page-wrapper.page-open.blogPage.article .article-content ul {
    padding-left: 30px; }
  .page-wrapper.page-open.blogPage.article .article-content nav {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0; }
  .page-wrapper.page-open.blogPage.article .article-content nav a {
    width: 47%;
    margin-bottom: 15px;
    font-size: 14px; }
  .page-wrapper.page-open.blogPage.article .article-content .call-to-action {
    padding: 0;
    flex-direction: column;
    text-align: center; }
  .page-wrapper.page-open.blogPage.article .article-content .call-to-action p {
    padding-right: 0; }
  .article-footer .articles {
    flex-direction: column; }
  .article-footer .articles .articles-article {
    width: 100%;
    margin-bottom: 30px; }
  .page-wrapper.page-open.blogPage.article .mod-case-detail.active {
    transform: scale(0.84) translateY(250px); }
  .article-author .bottom .item:nth-child(2n) {
    margin-right: 0; } }

@media (max-width: 350px) {
  .page-wrapper.page-open.blogPage.article h1 {
    font-size: 34px;
    line-height: 52px; }
  .article-author .bottom .item img {
    height: 30px;
    width: 30px; }
  .page-wrapper.page-open.blogPage.article .article-content h3 {
    font-size: 18px; }
  .article-footer {
    padding: 10px 0 60px; }
  .page-wrapper.page-open.blogPage.article .mod-case-detail.active {
    transform: scale(0.84) translateY(300px); }
  .article-author .top .el {
    margin-right: 15px; } }

.test-mod {
  flex-direction: column; }
