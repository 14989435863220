.mod-brief {
  font-family: $got;

  .el-textarea__label {
    margin: 0;
    font-weight: 500;
    width: 100%; }

  .form_block .form_fieldset_structure {
    margin-bottom: 20px; }

  .brief_title {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    @media (max-width: $screen-sm-max) {
      max-width: 680px; } }

  .h2 {
    font-size: 30px;
    margin-bottom: 20px;
    display: block;
    text-align: center;
    left: 0;
    &:after {
      content: attr(data-title);
      position: absolute;
      font-size: 150px;
      font-weight: bold;
      letter-spacing: 2.5px;
      color: rgba(0, 0, 0, 0.03);
      bottom: -170px;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: $screen-sm-max) {
        font-size: 80px; } } }

  .h6 {
    font-size: 14px;
    line-height: 1.8;
    letter-spacing: 0.2px;
    color: $black;
    text-align: center;
    font-weight: 100;
    transition: all 0.5s ease;
    left: 0; }

  .form {
    max-width: 650px;
    margin: 20px auto;
    position: relative;

    fieldset:not(:first-of-type) {
      display: none; }

    &_imgCover {
      padding: 20px 0;
      background: rgba(0, 0, 0, 0.03);
      display: block;
      width: 100px;
      height: 100px;
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: center; }

    &_title {
      color: #111;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      padding-left: 20px;
      margin-bottom: 0px;
      span {
        color: $black;
        text-transform: lowercase; } }

    &_subtitle {
      font-size: 12px;
      line-height: 1.5;
      letter-spacing: 0.38px;
      color: $black;
      padding: 10px 0 0 20px; }

    .el-input__label-content,
    .el-textarea__label-content {
      position: absolute;
      color: #949494;
      text-align: left; }

    .el-textarea {
      height: 100px; }

    .el-textarea__field,
    .input__field {
      color: black;
 }      // padding: 10px

    &_fieldsetCover {
      position: relative;
      width: 400px;
      margin: 0 auto;
      @media(max-width:450px) {
        width: 350px;
        margin-bottom: 100px; }
      @media(max-width:400px) {
        width: 100%; } }

    &_fieldset {
      position: relative;
      left: 0; }

    &_fieldset_i {
      position: relative;
      left: 0;
      &:after {
        content: "";
        background-color: #fff;
        display: block;
        position: absolute;
        bottom: -105px;
        right: -5%;
        width: 105%;
        height: 150px;
        transform: rotate(-11deg); } }

    &_fieldset_sizes {
      label {
        width: 80px;
        height: 80px;
        margin: 0 5px;
        @media(max-width:400px) {
          width: 65px;
          height: 65px;
          margin: 0 3px; }
        .form_imgCover {
          width: 80px;
          height: 80px;
          padding: 20px 0;
          @media(max-width:400px) {
            width: 65px;
            height: 65px;
            padding: 10px 0; } } } }

    &_fieldset_identity {
      .form_imgCover {
        @media(max-width:400px) {
          width: 80px;
          height: 80px;
          padding: 15px 0; } }

      label {
        @media(max-width:400px) {
          width: 80px;
          height: 80px;
          margin: 0 5px; } } }

    &_fieldset_structure {
      .form_block {
        justify-content: space-around; }

      label {
        min-width: 110px;
        padding: 0 10px;
        height: 50px;
        box-shadow: 0 10px 35px -15px rgba(152, 152, 152, 0.5);
        font-weight: 100;
        text-transform: uppercase;
        line-height: 50px;
        background-color: rgba(0, 0, 0, 0.03);
        @media(max-width:450px) {
          min-width: 100px; }

        .form_label {
          @media(max-width:450px) {
            font-size: 10px; } } } }

    &_fieldset_project {
      .el-input__field {
        color: #000;
 }        // padding-left: 10px

      .el-input__label-content {
        width: 200px; }

      .form_block {
        flex-direction: column; }
      .form_block-2 {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        label {
          width: 165px;
          height: 50px;
          box-shadow: 0 10px 35px -15px rgba(152, 152, 152, 0.5);
          font-weight: 100;
          text-transform: uppercase;
          line-height: 50px;
          @media(max-width:400px) {
            width: 120px; } } } }

    &_fieldset_message {
      .label {
        display: block; }

      .label, .icon {
        color: #000; } }

    &_fieldset_contacts {
      .form_block {
        display: block;
        flex-direction: columns; }
      .el-input__label {
        position: absolute;
        left: 0;
        color: #949494; }
      input {
        width: 100%;
        // height: 60px
        // border: none
        // font-size: 16px
        font-weight: 500;
        color: $black;
 }        // padding-left: 15px
      .graphic {
        stroke: #000; } }

    &_fieldset_result {
      .form_title {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        color: #a8a8a8; }
      .form_subtitle {
        text-align: center;
        font-size: 14px;
        line-height: 1.64;
        text-align: center;
        color: $black;
        padding-top: 30px; }
      .action-button {
        img {
          width: 50%; } } }

    &_fieldset_sites {
      .el-textarea {
        margin: 10px 5px; }

      .el-input__field {
        max-height: 70px;
        padding-left: 20px; }
      .el-input {
        margin: 20px 0; }
      .graphic {
        bottom: 0; } }

    &_fieldset_message {
      .el-input__label {
        margin: 0; }
      .el-file-input {
        @media(max-width:535px) {
          width: 90%; } } }

    &_block-file {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .wrap-file {
        width: 100%; }
      .close {
        top: 15px;
        right: 0;
        @media(max-width:535px) {
          top: 10px; } }

      .labe-file {
        color: #949494;
        font-size: 10px;
        text-transform: uppercase;
        padding-left: 5px;
        margin-top: 10px; }
      .label {
        font-size: 12px;
        text-transform: uppercase;
        max-width: 150px; } }

    &_bg {
      width: 400px;
      height: 410px;
      margin: 0 auto;
      display: block;
      position: relative;
      @media(max-width:450px) {
        width: 350px; }
      @media(max-width:400px) {
        width: 95%;
        height: auto; } }

    &_section {
      position: absolute;
      z-index: 2;
      top: 50%;
      display: flex;
      flex-direction: column;
      left: -65px;
      transform: translateY(-50%);
      background: #fff;
      padding: 25px 20px;
      width: 415px;
      min-height: 210px;
      box-shadow: 0px 8px 10px 4px rgba(0, 0, 0, 0.06);
      border-radius: 5px;
      @media(max-width:550px) {
        left: 0; }
      @media(max-width:450px) {
        left: -15px;
        width: 370px;
        padding: 15px 10px; }
      @media(max-width:400px) {
        width: 100%; }
      @media(max-width:360px) {
        left: 0; } }

    &_block {
      display: flex;
      align-items: center;
      flex-grow: 1;
      // margin-left: -4px
      .form_group:first-child label {
        margin-left: 5px; }
      .el-input.el-text-fel.el-input--filled {
        margin-left: 5px;
        width: calc( 100% - 10px ); }
      .el-input.el-text-fel, .el-textarea.el-text-fel {
        margin-left: 5px;
        width: calc( 100% - 10px ); } }
    &_label {
      text-align: center;
      color: #b9b9b9;
      font-size: 12px;
      display: block;
      margin-top: 6px; } }
  .el-input__label {
    margin: 0;
    width: 100%; }
  label {
    display: block;
    position: relative;
    cursor: pointer;
    width: 100px;
    margin: 0 10px;
    text-align: center;
    &:before {
      background-color: white;
      color: white;
      content: "";
      display: block;
      border-radius: 50%;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 28px;
      transition-duration: 0.4s;
      transform: scale(0);
      font-size: 10px;
      line-height: 20px; }

    img {
      transition-duration: 0.2s;
      transform-origin: 50% 50%;
      opacity: 0.3;
      position: relative;
      z-index: 2; } } }

input {
  &:checked + label {
    border-color: #ddd; }

  &:checked + label:before {
    content: "";
    background-image: url(../img/svg/check.svg);
    transform: scale(1);
    background-size: cover; }

  &:checked + label img {
    transform: scale(0.9);
    opacity: 1; }

  &:checked + label .form_imgCover {
    background: $sunshine-yellow; }

  &:checked + label .form_label {
    color: $black; }

  &:checked + label.form_label {
    background: $sunshine-yellow;
    font-weight: 600;
    color: $black; } }

input[type='checkbox'], input[type='radio'] {
  opacity: 0 !important; }

.progressbar {
  position: relative;
  left: 0;
  &:after {
    content: '';
    width: calc(100% - 130px);
    height: 2px;
    background: #efefef;
    position: absolute;
    left: 65px;
    top: 27px;
    pointer-events: none;
    z-index: -2; }

  &_item {
    position: absolute;
    top: 23px;
    font-size: 12px;
    color: $black;
    font-weight: 100; }

  &_result {
    right: 0;
    color: #d8d8d8; }

  &_start {
    left: 0; }

  &_block {
    overflow: hidden;
    counter-reset: step;
    text-align: center;
    margin: 30px auto 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 55px;
    @media(max-width:750px) {
      width: 80%; }
    @media(max-width:750px) {
      width: 75%; } }

  li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    width: 33%;
    float: left;
    position: relative;
    letter-spacing: 1px;
    // &.active
    //   display: none
    &:first-child {
      &:after {
        display: none; } }
    &:before {
      content: counter(step);
      counter-increment: step;
      width: 0;
      height: 20px;
      line-height: 24px;
      display: block;
      font-size: 12px;
      color: #333;
      background: white;
      margin: 0 auto;
      opacity: 0;
      transition: 0.5s ease; }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      background: #efefef;
      position: absolute;
      left: -50%;
      top: 9px;
      transition: 0.5s ease;
      z-index: -1; }

    span {
      width: 10px;
      height: 10px;
      display: block;
      transform: translate(-50%, -50%) rotate(45deg);
      top: 50%;
      position: absolute;
      left: 50%;
      transition: 0.5s ease; }

    p {
      position: absolute;
      display: none;
      color: rebeccapurple;
      left: 50%;
      transform: translateX(-50%);
      font-size: 10px;
      color: #000;
      opacity: 0;
      transition: all 0.5s ease;
      padding: 7px 0 0;
      text-align: center;
      font-weight: 900;
      font-family: inherit;
      @media(max-width:400px) {
        font-size: 8px; } }

    &.active {
      &:before {
        opacity: 1; } }

    &:first-child:after {
      content: ""; }

    &.active {
      span {
        background: #000; }
      p {
        display: block;
        opacity: 1; } }

    &.active:last-child {
      span {
        width: 20px;
        height: 20px;
        background: $sunshine-yellow;
        &:after {
          content: '';
          background: #fff;
          width: 10px;
          height: 10px;
          display: block;
          transform: rotate(45deg);
          top: 50%;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%) rotate(0); } } }
    &.active:before,  &.active:after {
      background: #000;
      color: white; } } }


.progressbar_block li.current {
  span {
    width: 20px;
    height: 20px;
    &:after {
      content: '';
      background: #fff;
      width: 10px;
      height: 10px;
      display: block;
      transform: rotate(45deg);
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%) rotate(0); } } }

.action-button {
  width: 70px;
  height: 70px;
  background: $sunshine-yellow;
  border-radius: 100%;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  box-shadow: -3px 17px 14px rgba(177, 177, 177, 0.46);
  position: absolute;
  z-index: 3;
  right: 15px;
  bottom: 55px;
  @media(max-width:550px) {
    right: -25px; }
  @media(max-width:400px) {
    right: 0;
    width: 60px;
    height: 60px;
    bottom: 5px; }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); } }


.brief_imgCover img {
  height: 100%; }


.m-b-0 {
  margin-bottom: 0!important; }
