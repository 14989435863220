.prices-page {
  position: relative;
  background: #000;
  font-family: $got;
  .page-body .container {
    padding: 0; }
  .header {
    background: #000;
    &:before {
      background: none; } }

  .logo {
    svg {
      .jet {
        fill: #fff; } } }

  .title-page {
    .title {
      color: #fff; } }

  .btn-menu:before,
  .page-open .btn-menu:after,
  .page-open .btn-menu .line {
      background: #fff; }

  .title-page {
    .menu-number {
      color:  #181818; } }

  .btn-menu {
    &:hover {
      .menu-number {
        color: #282828; } } }

  .social {
    a {
      color: #fff; } }

  .email {
    color: #fff;
    .icon {
      stroke: #fff; } }

  .el-link-sm {
    &:before {
      background: $sunshine-yellow; } }

  .social.hovered li a:hover {
      color: #949494; }

  .lines {
    border-right: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
    opacity: 0.1; }

  .circle-cursor--outer {
    border: 1px solid #fff; }

  .circle-cursor--inner {
    background: #fff; }

  .bgText {
    font-size: 300px;
    font-weight: bold;
    letter-spacing: 6px;
    color: #181818;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(30%);
    z-index: -1;
    opacity: 0.5;
    @media(max-width: 767px) {
      font-size: 200px; } }

  .prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    left: 0;
    @media(max-width: 650px) {
      flex-direction: column; }

    &_lt {
      color: #fff;
      width: 70%;
      padding-right: 10px;
      @media(max-width: 1200px) {
        width: 60%; }
      @media(max-width: 950px) {
        width: 50%; }
      @media(max-width: 650px) {
        width: 90%; }
      @media(max-width: 535px) {
        width: 100%; } }

    &_rt {
      width: 30%;
      @media(max-width: 1200px) {
        width: 40%; }
      @media(max-width: 950px) {
        width: 50%; }
      @media(max-width: 650px) {
        width: 90%; }
      @media(max-width: 535px) {
        width: 100%; } }

    &_title {
      padding-left: 15px;
      @media(max-width: 736px) {
        padding-left: 0; }
      h2 {
        color: #fff;
        @media(max-width: 736px) {
          padding: 30px 0;
          padding-left: 0; } } }

    .text {
      max-width: 650px;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 0.1px;
      color: #fff;
      opacity: 0.8;
      @media(max-width: 950px) {
        font-size: 16px;
        line-height: 2; }
      @media(max-width: 535px) {
        font-size: 12px; } }

    &_list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 6px;

      li {
        font-size: 14px;
        font-weight: 500;
        line-height: 2.8;
        letter-spacing: 0.4px;
        border-radius: 100px;
        border: solid 1px #979797;
        padding: 0 18px;
        margin: 10px;
        box-sizing: border-box;
        // opacity: 0.8
        // padding-left: 15px
        transition: 0.5s ease;
        cursor: pointer;
        @media(max-width: 950px) {
          font-size: 14px;
          margin: 5px; }
        @media(max-width: 535px) {
          font-size: 12px; }
        &:hover {
          border: solid 1px $sunshine-yellow;
          color: $sunshine-yellow;
          transform: scale(0.97); }
        &.active {
          border: solid 1px $sunshine-yellow;
          color: $black;
          background-color: $sunshine-yellow;

          transform: scale(0.97); } } }

    &_featureTitle {
      font-size: 22px;
      font-weight: 500;
      line-height: 2.05;
      letter-spacing: 1.22px;
      margin-top: 20px;
      padding-left: 15px; }

    &_featureSubtitle {
      max-width: 650px;
      opacity: 0.8;
      font-size: 14px;
      line-height: 1.8;
      letter-spacing: 0.1px;
      margin-top: 20px;
      padding-left: 15px;
      @media(max-width: 535px) {
        font-size: 12px;
        padding-left: 0; } }

    .form {
      width: 370px;
      box-shadow: 0 10px 35px -15px rgba(255, 255, 50, 0.8);
      background-color: $sunshine-yellow;
      padding: 24px 30px;
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      margin-top: -30px;
      @media(max-width: 950px) {
        width: 350px;
        padding: 10px 20px;
        right: 6%; }
      @media(max-width: 835px) and (max-height: 411px) {
        height: 390px;
        overflow-y: auto; }
      @media(max-width: 812px) and (max-height: 375px) {
        height: 360px; }
      @media(max-width: 767px) {
        right: 2%;
        width: 320px; }
      @media(max-width: 650px) {
        margin: 20px auto;
        display: block;
        width: 100%;
        height: auto;
        position: relative;
        right: 0;
        top: 0;
        transform: none;
        z-index: 2; }
      @media(max-width: 535px) {
        padding: 20px 2 0px; }
      &_title, &_titleLoad {
        font-size: 16px; }
      &_price {
        line-height: 30px; }
      &_titleBlock {
        font-size: 26px;
        font-weight: 900;
        line-height: 1.8;
        letter-spacing: 0.2px;
        color: #000; }

      &_service {
        padding: 12px 0;
        @media(max-width: 950px) {
          padding: 10px 0; }
        li {
          font-size: 18px;
          line-height: 1.7;
          letter-spacing: 0.3px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media(max-width: 950px) {
            line-height: 25px; }
          @media(max-width: 767px) {
            font-size: 18px; }
          @media(max-width: 535px) {
            font-size: 16px; } } }

      &_serviceName {
        font-weight: 100;
        @media(max-width: 950px) {
          font-size: 16px; } }

      &_servicePrice {
        font-weight: 900;
        @media(max-width: 950px) {
          font-size: 18px; } }

      &_load {
        font-size: 25px;
        font-weight: 500;
        line-height: 2;
        letter-spacing: 0; }
      &_btn {}
      &_input {
        border-radius: 2px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #fff;
        display: block;
        width: 100%;
        height: 40px;
        outline: none;
        border: none;
        margin: 10px 0;
        text-align: left;
        font-size: 18px;
        padding: 0 40px 0 15px; }

      &_input {
        @media(max-width: 535px) {
          font-size: 16px; }
        &::-webkit-input-placeholder {
          color: #000; }

        &::-moz-placeholder {
          color: #000; }

        &:-ms-input-placeholder {
          color: #000; }

        &:-moz-placeholder {
          color: #000; } }

      &_btn {
        font-weight: 600;
        transition: 0.5s ease;

        &:hover {
          // background: #000
          // color: #fff
          cursor: pointer; } } } }



  .knobs, .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    cursor: pointer; }

  .button {
    position: relative;
    top: 50%;
    width: 64px;
    height: 26px;
    margin: 0 auto 0 auto;
    // overflow: hidden

    &.r {
      border-radius: 100px;

      .layer {
        border-radius: 100px; } }

    &.b2 {
      border-radius: 2px; } }

  .toggle-button-cover {
    padding-top: 35px; }

  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3; }

  .knobs {
    z-index: 2; }

  .layer {
    width: 100%;
    background-color: #181818;
    transition: 0.3s ease all;
    z-index: 1;
    &::before {
      content: "Web Site";
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.4px;
      font-family: $got;
      color: $sunshine-yellow; //white
      font-size: 16px;
      position: absolute;
      left: -95px;
      min-width: 80px; }
    &::after {
      content: "Application";
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.4px;
      font-family: $got;
      color: white;
      font-size: 16px;
      position: absolute;
      right: -115px; } }

  #button-3 {
    .knobs:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 18px;
      height: 10px;
      color: #fff;
      font-size: 10px;
      font-weight: bold;
      text-align: center;
      line-height: 1;
      padding: 9px 4px;
      background-color: $sunshine-yellow;
      border-radius: 50%;
      transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15); }

    .checkbox {
      &:active + .knobs:before {
        width: 46px;
        border-radius: 100px; }

      &:checked {
        &:active + .knobs:before {
          margin-left: -28px; }

        + .knobs:before {
          content: '';
          left: 42px;
          background-color: $sunshine-yellow; }

        ~ .layer {
          background-color: #181818;
          &::before {
            color: white; }
          &:after {
            color: $sunshine-yellow; } } } } }
  .tooltip {
    display: inline-block;
    min-width: 180px;
    text-align: center;

    .tooltiptext {
      background-color: white;
      color: $black;
      box-shadow: 0 10px 35px -15px rgba(0, 0, 0, 1);
      box-sizing: border-box;
      width: 120%;
      min-width: 150px;
      text-align: center;
      border-radius: 10px;
      position: absolute;
      top: 110%;
      left: -10%;
      z-index: 99;
      opacity: 0;
      visibility: hidden; }


    &.active .tooltiptext {
      color: black; }

    &:hover .tooltiptext {
      opacity: 1;
      visibility: visible;
      transition: 0.5s ease, opacity  0.3s ease 1s; } } }


.wrap-input-price {
  position: relative;
  .el-text-fel {
    position: relative;
    &.error {
      outline: 1px solid red; } }

  b.error {
    position: absolute;
    bottom: -22px;
    font-size: 10px;
    right: 0;
    font-family: $got;
    font-weight: normal;
    text-align: right;
    color: red; }
  .form_btn {
    position: absolute;
    right: 6px;
    top: 3px;
    font-size: 26px;
    &:hover {
      transform: scale(1.1);
      opacity: 0.8; } } }



