body.general-menu-opened {
  overflow: hidden;
  .general-menu {
    transform: scaleX(1); } }

.general-menu {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  background: transparent;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);
  transform-origin: 0% 50% 0px;
  transform: scaleX(0);
  &:hover {
    cursor: pointer; } }

.general-menu-wrap {
  height: 100%;
  width: 100%;
  background-color: $black;
  position: relative;
  overflow: hidden;
  @media (max-width: $screen-md-max) {
    width: 100%; } }

.general-menu-content {
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: all 600ms cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    cursor: default; }
  &:before {
    content: 'JetUp';
    pointer-events: none;
    font-family: $got;
    font-size: 200px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 4px;
    text-align: right;
    color: #181818;
    position: absolute;
    width: 960px;
    left: 0;
    right: 0;
    top: 180px;
    margin: 0 auto;
    z-index: 0;
    @media (max-width: $screen-xs-max) {
      width: 768px;
      font-size: 100px;
      text-align: left;
      left: 130px; }

    @media (max-width: 480px) {
      left: 25px;
      font-size: 88px;
      bottom: 90px;
      top: auto; } }

  .social {
    position: absolute;
    z-index: 101;
    right: auto;
    left: 50px;
    bottom: 50px;
    min-width: 240px;
    top: auto;
    // transform: translateX(-50%)
    // display: none
    @media (max-width: $screen-md-max) {
      display: block; }
    @media (max-width: $screen-xs-max) {
      right: auto;
      left: 50%;
      display: block;
      bottom: 30px;
      transform: translateX(-50%); }
    li:not(:last-child) {
      @media (max-width: $screen-xs-max) {
        margin-right: 15px; } }
    li a {
      color: white; }
    &.hovered {
      li a {
        color: $warm-grey;
        &:hover {
          color: white; } } } }
  .close {
    top: 50%;
    margin-top: -10px;
    @media (max-width: $screen-md-max) {
      top: 55px;
      right: 50px;
      left: auto;
      transform: translateY(0); }
    @media (max-width: $screen-xs-max) {
      top: 32px;
      right: 20px; }

    .icon {
      color: white;
      transition: color 0.2s; }
    &:hover {
      .icon {
        color: $sunshine-yellow; } } }
  .lines {
    position: absolute;
    z-index: 0;
    opacity: 0.1;
    border-color: #f2f2f2; }

  .sound {
    position: absolute;
    right: 40px;
    bottom: 40px;
    left: auto;
    padding: 10px;
    &:before,
    &:after {
      display: none; }
    .line {
      background: white; } }

  .copyright {
    position: absolute;
    color: white;
    opacity: 0.5;
    transition: opacity 0.2s;
    &:hover {
      opacity: 1; } }
  .el-link-sm:before {
    background-color: $sunshine-yellow; }
  .email {
    position: absolute;
    color: white;
    @media (max-width: $screen-md-max) {
      right: 105px; }
    @media (max-width: $screen-xs-max) {
      right: 75px;
      top: 28px; }

    &:before {
      background-color: $sunshine-yellow; }
    .icon {
      stroke: white;
      stroke-width: 0.3px;
      @media (max-width: $screen-xs-max) {
        margin: -3px -2px 0; } } }
  .lang {
    @extend %clear-fix;
    display: inline-block;
    position: fixed;
    z-index: 1;
    font-size: 0;
    top: 50px;
    right: 210px;
    @media (max-width: 1229px) {
      right: 260px; }
    @media (max-width: $screen-xs-max) {
      top: 28px;
      left: 20px;
      right: auto; }
    li {
      float: left;
      &:not(:last-child) {
        margin-right: 10px; }
      &.is-active {
        a {
          opacity: 1; } }
      a {
        color: white;
        opacity: 0.8;
        transition: all 0.2s;
        font-family: $got;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        &:hover,
        &:focus {
          opacity: 1;
          text-decoration: none;
          color: $sunshine-yellow; } } } }

  .el-right-menu__list {
    position: absolute;
    width: 960px;
    left: 0;
    right: 0;
    margin: 100px auto 0;
    top: 0;
    @media (max-width: 1060px) {
      width: calc(100% - 130px);
      margin: 100px 80px 0 50px; }
    @media (max-width: $screen-xs-max) {
      margin: 70px 20px 0 20px; }


    &.hovered {
      .el-right-menu__link {
        color: $warm-grey;
        &:hover {
          color: white; } } }

    .el-right-menu__line {
      right: auto;
      left: 0;
      height: 60px;
      width: 5px;
      background: $sunshine-yellow;
      opacity: 0;
      visibility: hidden;
      @media (max-width: $screen-xs-max) {
        height: 40px; } }

    .el-right-menu__link {
      text-align: left;
      padding: 0 60px;
      font-size: 30px;
      line-height: 60px;
      color: white;
      display: inline-block;
      @media (max-width: $screen-xs-max) {
        font-size: 18px;
        line-height: 40px;
        padding: 0 20px 0 40px; } }

    .el-right-menu__item--current .el-right-menu__link {
      transform: translateX(0);
      pointer-events: all; }
    .el-right-menu__item {
      &:nth-child(1).el-right-menu__item--current ~ .el-right-menu__line {
        transform: translateY(0px);
        opacity: 1;
        visibility: visible; }
      &:nth-child(2).el-right-menu__item--current ~ .el-right-menu__line {
        transform: translateY(60px);
        opacity: 1;
        visibility: visible;
        @media (max-width: $screen-xs-max) {
          transform: translateY(40px); } }
      &:nth-child(3).el-right-menu__item--current ~ .el-right-menu__line {
        transform: translateY(120px);
        opacity: 1;
        visibility: visible;
        @media (max-width: $screen-xs-max) {
          transform: translateY(80px); } }
      &:nth-child(4).el-right-menu__item--current ~ .el-right-menu__line {
        transform: translateY(180px);
        opacity: 1;
        visibility: visible;
        @media (max-width: $screen-xs-max) {
          transform: translateY(120px); } }
      &:nth-child(5).el-right-menu__item--current ~ .el-right-menu__line {
        transform: translateY(240px);
        opacity: 1;
        visibility: visible;
        @media (max-width: $screen-xs-max) {
          transform: translateY(160px); } }
      &:nth-child(6).el-right-menu__item--current ~ .el-right-menu__line {
        transform: translateY(300px);
        opacity: 1;
        visibility: visible;
        @media (max-width: $screen-xs-max) {
          transform: translateY(200px); } } } } }
