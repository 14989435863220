.page-body {
  overflow: hidden; }

.mod-title-services {
  margin-bottom: 90px; }

.mod-services {
  .text-style-paragraph.mod-col-left {
    @media (max-width: 900px) {
      font-size: 12px;
      line-height: 1.5; } } }


.services-section {
  @extend %clear-fix;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 120px;
    @media (max-width: $screen-xs-max) {
      margin-bottom: 90px; } }

  &.mod-right {
    .services-section-text {
      float: right;
      @media (max-width: 600px) {
        float: none; } }
    .services-section-image {
      float: left;
      @media (max-width: 600px) {
        float: none; } } }

  .services-section-text {
    float: left;
    width: calc(100% - 430px);
    @media (max-width: 900px) {
      width: calc(100% - 330px); }
    @media (max-width: 600px) {
      float: none;
      width: 100%;
      max-width: 400px; }
    .el-bg-text {
      @media (max-width: 600px) {
        bottom: -10px; } } }

  .content-section {
    .el-link {
      margin-left: 141px;
      @media (max-width: $screen-sm-max) {
        margin-left: 45px; }
      @media (max-width: $screen-xs-max) {
        margin-left: 10px; } } }



  .text-style-paragraph.mod-col-left {
    float: none;
    margin-bottom: 10px; }

  .services-section-image {
    float: right;
    width: 400px;
    position: relative;
    height: 400px;
    margin-top: 10px;
    object-fit: contain;
    @media (max-width: 900px) {
      width: 300px;
      height: 300px; }
    @media (max-width: $screen-xs-max) {
      margin-top: 0; }
    @media (max-width: 600px) {
      float: none;
      height: auto;
      width: 100%;
      margin-bottom: 35px; }
    .img-wrap {
      @media (max-width: 600px) {
        width: 100%;
        height: 220px; }
      img {
        @media (max-width: 600px) {
          object-fit: cover;
          width: 100%;
          height: 220px; } } }
    .line-wrap {
      position: absolute;
      left: 30px;
      top: -60px;
      z-index: 1;
      display: inline-block;
      pointer-events: none;
      width: 60px;
      height: 120px;
      .block-revealer__content {
        width: 100%;
        height: 100%; }
      .line-cont {
        display: inline-block;
        width: 100%;
        height: 100%;
        background-color: $sunshine-yellow;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06); } } } }
