
// # TODO: el-video-youtube
// # -----------------------------------------------------------------------------------
.full {
  .video-player-youtube {
    width: 100vw;
    height: 100vh;
    .video-youtube-wrap {
      top: 50%;
      transform: translateY(-50%); } } }

.video-player-youtube {
  position: relative;
  display: inline-block;
  font-size: 0;
  background-color: black;
  width: 100%;
  transition: all 0.2s;

  &.play {
    .icon-play {
      display: none; }
    .icon-pause {
      display: inline-block; } }

  &.start {
    .video-youtube-wrap {
      .video-placeholder {
        opacity: 1; } } }


  .video-controls {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    z-index: 1;
    position: fixed;

    height: 60px;
    z-index: 1;
    color: $black;
    box-sizing: border-box;
    @extend %clear-fix;
    padding-right: 10px;
    padding-top: 20px;
    padding-bottom: 19px;
    @media (max-width: $screen-xs-max) {
      height: 40px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px; } }


  .video-youtube-wrap {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    .video-placeholder {
      opacity: 0; } }

  .video-placeholder {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // @media (max-width: $screen-xs-max)
 }    //   pointer-events: all !important

  .progress-bar {
    position: absolute;
    left: 0;
    bottom: 60px;
    outline: none;
    padding: 0;
    width: 100%;
    height: 4px;
    background-color: rgba(black, 0.5);
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, $sunshine-yellow), color-stop(100%, $sunshine-yellow));
    background-image: -webkit-linear-gradient($sunshine-yellow, $sunshine-yellow);
    background-image: -moz-linear-gradient($sunshine-yellow, $sunshine-yellow);
    background-image: -o-linear-gradient($sunshine-yellow, $sunshine-yellow);
    background-image: linear-gradient($sunshine-yellow, $sunshine-yellow);
    background-size: 0% 100%;
    background-repeat: no-repeat;
    border-radius: 0;
    cursor: pointer;
    -webkit-appearance: none;
    transition: all 0.1s;
    @media (max-width: $screen-xs-max) {
      bottom: 40px; }
    &:hover {
      height: 8px; }
    &::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
      -webkit-appearance: none; }
    &::-moz-range-track {
      box-shadow: none;
      border: none;
      background: transparent; }
    &::-moz-focus-outer {
      border: 0; }
    &::-webkit-slider-thumb {
      width: 0px;
      height: 0px;
      border: 0;
      background: #fff;
      border-radius: 0;
      box-shadow: none;
      -webkit-appearance: none;
      @media (max-width: $screen-xs-max) {
        width: 16px;
        height: 16px;
        border-radius: 100%; } }
    &::-moz-range-thumb {
      width: 0px;
      height: 0px;
      border: 0;
      background: #fff;
      border-radius: 0;
      box-shadow: none;
      @media (max-width: $screen-xs-max) {
        width: 16px;
        height: 16px;
        border-radius: 100%; } } }


  .video-scrubber, .video-progress {
    cursor: pointer;
    transition: all 0.1s; }

  .video-scrubber {
    position: absolute;
    bottom: 60px;
    left: 0;
    width: 100%;
    cursor: pointer;
    height: 2px;
    background-color: rgba(#000,0.1);
    @media (max-width: $screen-sm-max) {
      background-color: rgba(#fff,0.3); }

    @media (max-width: $screen-xs-max) {
      height: 2px; }
    &:hover {
      height: 8px;
      .video-progress:after {
        top: -5px; } }
    @media (max-width: $screen-xs-max) {
      bottom: 40px; } }


  .video-progress {
    width: 0;
    height: 100%;
    background-color: $black;
    cursor: pointer;
    @media (max-width: $screen-sm-max) {
      background-color: white; } }


  .video-time {
    display: inline-block;
    color: $black;
    font-family: $got;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-transform: uppercase;
    float: right;
    @media (max-width: $screen-sm-max) {
      color: white; }

    span {
      display: inline-block;
      padding: 0 4px;
      min-width: 34px; } }

  .video-element {
    max-width: 100%;
    width: 100%; }

  .icon-mute {
    transform: translateY(-3px);
    padding: 5px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: inline-block;
    font-size: 0;
    color: transparent;
    background: url(../img/svg/sound.svg) no-repeat center/16px;
    cursor: pointer;
    @media (max-width: $screen-sm-max) {
      background: url(../img/svg/sound-w.svg) no-repeat center/16px; }

    &.muted {
      background: url(../img/svg/volume-off.svg) no-repeat center/16px;
      @media (max-width: $screen-sm-max) {
        background: url(../img/svg/volume-off-w.svg) no-repeat center/16px; } } }
  .icon-play {
    transform: translateY(-3px);
    margin: 0 15px;
    padding: 5px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: inline-block;
    background: url(../img/svg/play-btn.svg) no-repeat center/24px;
    cursor: pointer;
    @media (max-width: $screen-sm-max) {
      background: url(../img/svg/play-btn-w.svg) no-repeat center/24px; } }

  .icon-pause {
    transform: translateY(-3px);
    margin: 0 15px;
    padding: 5px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    display: none;
    background: url(../img/svg/pause-btn.svg) no-repeat center/12px;
    cursor: pointer;
    @media (max-width: $screen-sm-max) {
      background: url(../img/svg/pause-btn-w.svg) no-repeat center/12px; } }

  .volume {
    transform: translateY(-3px);
    position: relative;
    display: inline-block;
    margin: 0 15px;
    width: 100px;
    height: 2px;
    cursor: pointer;
    vertical-align: middle;
    background-color: rgba(#000,0.1);
    @media (max-width: $screen-sm-max) {
      background-color: rgba(#fff,0.3); }
    @media (max-width: $screen-xs-max) {
      width: 60px; }
    .volume-bar {
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      display: block;
      cursor: pointer;
      width: 52%;
      height: 100%;
      background-color: $black;
      @media (max-width: $screen-sm-max) {
        background-color: white; }
      &:after {
        display: inline-block;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: $black;
        cursor: pointer;
        right: -5px;
        top: -5px;
        position: absolute;
        z-index: 10;
        @media (max-width: $screen-sm-max) {
          background-color: white; } } } } }


