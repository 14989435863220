// header
//-----------------------------------------------------------------------------------
.header {
  @media (max-width: $screen-md-max) {
    position: fixed;
    background: white;
    left: 0;
    top: 0;
    width: 100%;
    height: 85px;
    z-index: 4;
    &:before {
      display: none;
      height: 10px;
      content: '';
      position: absolute;
      top: 84px;
      left: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 1) , rgba(255, 255, 255, 0)); } }
  @media (max-width: $screen-xs-max) {
    height: 64px;
    &:before {
      display: block;
      height: 10px;
      top: 63px; } }

  .email {
    @media (max-width: $screen-xs-max) {
      display: none; } }

  .lines {
    display: none;
    position: absolute;
    @media (max-width: $screen-md-max) {
      display: block; }
    @media (max-width: $screen-xs-max) {
      display: none; } }
  .lang {
    @extend %clear-fix;
    display: inline-block;
    position: fixed;
    z-index: 1;
    font-size: 0;
    top: 50px;
    right: 210px;
    @media (max-width: 1229px) {
      right: 260px; }
    @media (max-width: $screen-xs-max) {
      top: 27px;
      right: 70px; }
    li {
      float: left;
      &:not(:last-child) {
        margin-right: 10px; }
      &.is-active {
        a {
          color: $black; } }
      a {
        color: #949494;
        transition: all 0.2s;
        font-family: $got;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        text-transform: uppercase;
        &:hover,
        &:focus {
          text-decoration: none;
          color: $black; } } } } }

.logo {
  display: inline-block;
  position: fixed;
  top: 33px;
  left: 50px;
  width: 90px;
  height: 45px;
  z-index: 3;
  @media (max-width: $screen-xs-max) {
    width: 70px;
    height: 35px;
    top: 15px;
    left: 15px; }
  &:hover {
    text-decoration: none; }
  svg {
    @media (max-width: $screen-xs-max) {
      width: 70px;
      height: 35px; }
    .bg {
      fill: $sunshine-yellow; }
    .jet {
      fill: $black; }
    .up {
      fill: $black; } } }

.email {
  position: fixed;
  top: 50px;
  right: 50px;
  font-size: 0;
  z-index: 3;
  @media (max-width: $screen-md-max) {
    right: 105px; }
  span {
    display: inline-block;
    font-size: 12px; }
  .icon {
    display: inline-block;
    font-size: 16px;
    margin: -7px 1px 0 0;
    padding: 0 1px;
    stroke: #000;
    stroke-width: 0.1px; } }
