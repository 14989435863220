// elements
//-----------------------------------------------------------------------------------

body {
  h1, h2, h3, h4, h5, h6, p, p span, h1 div, h2 div, h3 div, h4 div, h5 div, h6 div, a, a span, b, button, blockquote, .number, .text-number, .el-bg-text, .block-revealer__content, .block-revealer__content span, .type, .title, .modal-title, img, .brief_titles, .brief_label, .form_title, .form_title span, .form_label, .form_subtitle, .labe-file, .progressbar_item, .contacts-item, .form_titleLoad, .prices_featureTitle, .prices-page .prices_list li {
    &::selection {
	    background: #dbdbdb; }

    &::-moz-selection {
	    background: #dbdbdb; } } }

::selection {
  background: #dbdbdb; }

::-moz-selection {
  background: #dbdbdb; }

.page-body {
  padding: 110px 0 118px;
  display: flex;
  justify-content: center;
  @media (max-width: $screen-md-max) {
    padding: 110px 0 40px; }
  @media (max-width: $screen-xs-max) {
    display: block;
    padding: 72px 0 34px; }
  .container {
    max-width: 990px;
    width: 100%;
    @media (max-width: 1060px) {
      max-width: 100%;
      padding: 0 50px; }
    @media (max-width: $screen-xs-max) {
      padding: 0 15px; } } }

.container-center {
  align-self: center; }

.lines {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  left: 0;
  display: block;
  width: 960px;
  height: 100%;
  margin: 0 auto;
  pointer-events: none;
  border-right: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
  @media (max-width: 1060px) {
    width: calc(100% - 100px);
    margin: 0 50px; }
  @media (max-width: $screen-xs-max) {
    display: none; }


  &:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    display: block;
    width: 320px;
    height: 100%;
    margin: 0 auto;
    content: "";
    pointer-events: none;
    border-right: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
    @media (max-width: 1060px) {
      width: calc(100% / 3); } } }

.mob-br {
  @media (max-width: $screen-xs-max) {
    display: none; } }

.el-link {
  position: relative;
  display: inline-block;
  transition: transform 0.5s, color 0.5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  transform: translate3d(-0.5em, 0, 0);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: $got;
  font-size: 14px;
  line-height: 4.29;
  letter-spacing: 0.5px;
  text-align: left;
  color: $black;
  &::before {
    position: absolute;
    z-index: 0;
    bottom: 10px;
    left: 0;
    width: 100%;
    height: 3px;
    content: "";
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transform: scaleX(0.4);
    transform-origin: 100% 100%;
    background: $sunshine-yellow; }
  &:hover,
  &:focus {
    transform: translate3d(0, 0, 0);
    color: #111;
    text-decoration: none; }
  &:hover:before {
    transition-timing-function: ease;
    transform: scaleX(1); } }

.el-link-sm {
  position: relative;
  display: inline-block;
  transition: transform 0.5s, color 0.5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  text-decoration: none;
  color: $black;
  font-family: $got;
  font-size: 12px;
  font-weight: 500;
  &::before {
    position: absolute;
    z-index: 0;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    content: "";
    transition: transform 0.5s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transform: scaleX(0);
    transform-origin: 100% 50%;
    //background: $sunshine-yellow
    background: $warm-grey; }
  &:hover,
  &:focus {
    text-decoration: none;
    color: $black; }
  &:hover:before {
    transition-timing-function: ease;
    transform: scaleX(1);
    transform-origin: 0 50%; } }

.page-open {
  .btn-menu {
    &:before, &:after {
      transform: scaleX(0);
      transform-origin: 0% 50%;
      transition: transform 0.2s;
      @media (max-width: $screen-md-max) {
        transform: scaleX(1); } }
    &:hover {
      &:before, &:after {
        transform: scaleX(1); }
      .menu-number {
        color: $sunshine-yellow; } }
    &:before, &:after, .line {
      background: $black;
      @media (max-width: $screen-md-max) {
        background: $black; } }
    .line {
      left: -25px;
      width: 85px;
      @media (max-width: $screen-md-max) {
        left: auto;
        width: 25px; } }
    .title-page {
      visibility: visible; }

    .title {
      opacity: 1;
      visibility: visible; } } }

.btn-menu {
  position: fixed;
  top: 50%;
  left: 25px;
  display: inline-block;
  text-decoration: none;
  width: 25px;
  height: 13px;
  margin-top: -10px;
  z-index: 3;
  &:hover {
    cursor: pointer;
    .line {
      @media (min-width: $screen-lg) {
        left: -25px;
        width: 85px; } }
    &:before, &:after, .line {
      background: $warm-grey; }
    .title-page {
      @media (min-width: $screen-lg) {
        visibility: visible; } }
    .title {
      @media (min-width: $screen-lg) {
        transform: translateX(15px);
        opacity: 1;
        visibility: visible; } } }
  @media (max-width: $screen-md-max) {
    top: 58px;
    right: 50px;
    left: auto;
    transform: translateY(0); }
  @media (max-width: $screen-xs-max) {
    top: 35px;
    right: 20px; }

  &:before,
  &:after {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 25px;
    height: 1px;
    content: "";
    background: $black;
    @media (max-width: $screen-md-max) {
      right: 0;
      left: auto; } }
  &:before {
    top: 0; }
  &:after {
    bottom: 0; }
  .line {
    position: absolute;
    top: 6px;
    left: 0;
    display: inline-block;
    width: 25px;
    height: 1px;
    margin-top: 0px;
    background: $black;
    transition: all 0.2s;
    @media (max-width: $screen-md-max) {
      right: 0;
      left: auto;
      top: 6px;
      margin-top: 0; } } }

.title-page {
  display: none;
  @media (min-width: $screen-lg) {
    position: absolute;
    display: inline-block;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    visibility: hidden;
    z-index: -1; }


  .title {
    font-family: $got;
    font-size: 16px;
    font-weight: 500;
    color: $black;
    position: absolute;
    display: inline-block;
    top: 20px;
    transition: transform 0.3s, opacity 0.2s;
    opacity: 0;
    visibility: hidden; }

  .menu-number {
    display: inline-block;
    pointer-events: none;
    font-family: $got;
    font-size: 70px;
    font-weight: bold;
    line-height: 1.14;
    letter-spacing: -2px;
    color: $text-bg;
    padding-left: 15px;
    padding-top: 10px;
    transition: color 0.2s; } }

.el-btn {
  display: inline-block;
  text-transform: uppercase;
  font-family: $got;
  font-size: 16px;
  letter-spacing: 1.5px;
  font-weight: bold;
  width: 280px;
  text-align: center;
  background-color: $sunshine-yellow;
  color: $black;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 10px 35px -15px rgba($sunshine-yellow, 0.8);
  transition: all .2s;
  text-decoration: none;
  @media (max-width: $screen-sm-max) {
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    max-width: 220px; }
  @media (max-width: 500px) {
    ma {} }
  &:hover {
    text-decoration: none; }
  &.mod-hover {
    &:hover {
      text-decoration: none;
      transform: translateY(5px);
      box-shadow: 0 6px 20px -10px rgba($sunshine-yellow, 0.8); } }
  &.mod-who-we-are {
    background-color: rgba(247, 247, 247, 1);
    box-shadow: 0 10px 35px -15px rgba(247, 247, 247, 0.8);
    &:hover {
      box-shadow: 0 6px 20px -10px rgba(247, 247, 247, 0.8);
      text-decoration: none;
      transform: translateY(5px); } } }

.close {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 21px;
  height: 21px;
  display: inline-block;
  z-index: 1;
  @media (max-width: $screen-xs-max) {
    transform: translateY(0);
    top: 22px;
    right: 20px; }
  &:hover {
    cursor: pointer; }
  .icon {
    font-size: 21px;
    color: $black;
    pointer-events: none; } }

.el-bg-text {
  font-family: $got;
  font-size: 150px;
  font-weight: bold;
  letter-spacing: 2.5px;
  text-align: left;
  color: $text-bg;
  position: absolute;
  z-index: -1;
  bottom: -140px;
  left: 85px;
  line-height: 1;
  white-space: nowrap;
  pointer-events: none;
  width: 100%;
  @media (max-width: $screen-sm-max) {
    font-size: 100px; }
  @media (max-width: $screen-xs-max) {
    bottom: -90px;
    left: 60px;
    font-size: 70px; } }


.mod-col-fluid {
  float: left;
  width: 100%;
  padding-left: 141px; }


.el-input {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  vertical-align: top;
  height: 60px;
  &:not(:last-child) {
    margin-bottom: 12px; }
  @media (max-width: $screen-sm-max) {
    margin: 0;
    width: 100%; }
  &.error {
    .graphic {
      stroke: red; }
    .error {
      position: absolute;
      bottom: 4px;
      font-size: 10px;
      right: 0;
      font-family: $got;
      font-weight: normal;
      text-align: right;
      color: red; } }
  .graphic {
    position: absolute;
    top: 0;
    left: 0;
    fill: none;
    transition: transform 0.7s, stroke 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    pointer-events: none;
    stroke: rgba(255,255,255,0.2); } }

.el-input__field {
  position: relative;
  display: block;
  float: right;
  width: 100%;
  margin-bottom: 10px;
  padding: 26px 0 8px;
  border: none;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  font-family: $got;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-align: left;
  color: white;
  &:focus {
    outline: none; } }

.el-input__label {
  position: absolute;
  top: 20px;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  user-select: none;
  transition: transform 0.2s 0.15s, color 1s;
  transition-timing-function: ease-out;
  transform-origin: 0 0;
  text-align: left;
  pointer-events: none;
  -khtml-user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  font-family: $got;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  color: white; }

.el-input__label-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0; }

.el-input__field:focus + .el-input__label, .el-input--filled .el-input__label {
  transform: translate3d(0, -20px, 0) scale3d(0.75, 0.75, 1);
  color: $warm-grey; }

.el-input__field:focus ~ .graphic, .el-input--filled .graphic {
  transform: translate3d(-66.6%, 0, 0);
  stroke: white; }


.el-textarea {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  width: 100%;
  vertical-align: top;
  height: 204px;
  &:not(:last-child) {
    margin-bottom: 12px; }
  @media (max-width: $screen-sm-max) {
    margin: 0;
    width: 100%;
    height: 130px; }
  @media (max-width: $screen-xs-max) {
    margin-top: 12px; }

  &.error {
    .graphic {
      stroke: red; }
    .error {
      position: absolute;
      bottom: 4px;
      font-size: 10px;
      right: 0;
      font-family: $got;
      font-weight: normal;
      text-align: right;
      color: red; } }

  .graphic {
    position: absolute;
    bottom: 0;
    left: 0;
    fill: none;
    transition: transform 0.7s, stroke 0.7s;
    transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
    pointer-events: none;
    stroke: rgba(255,255,255,0.2); } }

.el-textarea__field {
  position: relative;
  display: block;
  float: right;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 22px;
  padding: 0 0 8px;
  border: none;
  border-radius: 0;
  background: transparent;
  -webkit-appearance: none;
  font-family: $got;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  color: white;
  resize: none;
  height: calc(100% - 40px);
  overflow: hidden;
  &:focus {
    outline: none; } }

.el-textarea__label {
  position: absolute;
  top: 20px;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  user-select: none;
  transition: transform 0.2s 0.15s, color 1s;
  transition-timing-function: ease-out;
  transform-origin: 0 0;
  text-align: left;
  pointer-events: none;
  -khtml-user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-touch-callout: none;
  font-family: $got;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  color: white; }

.el-textarea__label-content {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0; }

.el-textarea__field:focus + .el-textarea__label, .el-textarea--filled .el-textarea__label {
  transform: translate3d(0, -20px, 0) scale3d(0.75, 0.75, 1);
  color: $warm-grey; }

.el-textarea__field:focus ~ .graphic, .el-textarea--filled .graphic {
  transform: translate3d(-66.6%, 0, 0);
  stroke: white; }

.el-file-input {
  display: inline-block;
  text-align: left;
  background: transparent;
  padding: 15px;
  width: 100%;
  position: relative;
  @extend %clear-fix;
  @media (max-width: $screen-xs-max) {
    padding: 10px; }
  &.error {
    .error {
      position: absolute;
      bottom: -5px;
      font-size: 10px;
      right: 10px;
      font-family: $got;
      font-weight: normal;
      text-align: right;
      color: red; } }

  & > [type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    cursor: pointer; }

  .close {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s; }

  .button {
    float: left;
    display: inline-block; }

  &:hover > .button {
    color: white; }
  .icon {
    color: white;
    font-size: 30px;
    transition: color 0.2s; }
  & > .label {
    float: left;
    margin-left: 18px;
    font-family: $got;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 2px;
    text-align: left;
    color: white;
    width: calc(100% - 50px);
    text-overflow: ellipsis;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 8px;
    @media (max-width: $screen-xs-max) {
      display: none; } }

  &.mod-chosen {
    .close {
      opacity: 1;
      visibility: visible; }

    .icon {
      color: $sunshine-yellow; }
    & > .label {
      font-size: 14px; } } }

.mod-home-first, .mod-expertise {
  .mouse {
    position: fixed;
    z-index: 1;
    pointer-events: none;
    @media (max-width: 768px) {
      display: none; }
    svg {
      .line {
        stroke: #000000; }

      path {
        fill: #000000; } } } }

.mouse {
  display: inline-block;
  width: 20px;
  height: 30px;
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  margin: 0 auto;
  @media (max-width: 1200px) {
    bottom: 30px; }
  @media (max-width: 768px) {
    pointer-events: none; }
  &:hover {
    text-decoration: none;
    svg {
      .line {
        stroke: $sunshine-yellow; }
      path {
        fill: $sunshine-yellow; } } }

  svg {
    .line {
      stroke: #FFFFFF;
      stroke-width: 0.4;
      animation: mouse 2s infinite;
      transform: translateY(0); }

    path {
      fill: #FFFFFF;
      transition: all 0.2s; } } }

@keyframes mouse {
  0% {
    transform: translateY(0); }

  50% {
    transform: translateY(5px); }

  75% {
    transform: translateY(0px); } }


.progress-button.state-loading .progress-inner {
  animation: progress 3s forwards; }

@keyframes progress {
  0% {
    width: 0; }

  100% {
    width: 100%; } }

.el-bg-text-h1-hide {
  position: absolute;
  left: 0;
  top: 0;
  color: rgba(0,0,0,0);
  pointer-events: none; }


