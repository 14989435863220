body {
  &.disable-container-center,
  &.case-detail {
    &.general-menu-opened {
      .social {
        &.hovered {
          li a {
            color: $warm-grey !important;
            &:hover {
              color: white !important; } } }
        li a {
          color: white !important;
          &:before {
            background-color: $sunshine-yellow !important; } } } }
    .page-body {
      display: block; }
    .copyright {
      opacity: 0 !important;
      transition: opacity 0.2s;
      @media (max-width: $screen-md-max) {
        display: none; } }

    &.end-contend {
      .copyright {
        opacity: 1 !important; }
      &.start-scroll {
        .copyright {
          opacity: 1 !important;
          transition: opacity 0.2s; }
        .footer .sound:before {
          display: inline-block; }
        .footer .sound:after,
        .footer .social {
          opacity: 1;
          visibility: visible;
          transition: opacity 0.2s; } } }

    &.start-scroll {
      .copyright {
        opacity: 0;
        transition: opacity 0.2s; }
      .footer .sound:before {
        display: none; }
      .footer .sound:after,
      .footer .social {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.2s;
        @media (max-width: $screen-md-max) {
          opacity: 1;
          visibility: visible; } } } }

  &.case-detail {
    @media (max-width: $screen-md-max) {
      .header {
        position: relative;
        background: none;
        height: auto;
        &:before {
          display: none; } } }
    .header .el-link-sm {
      &:before {
        background-color: $sunshine-yellow; } }
    .logo svg .jet {
      fill: white; }

    .header .email {
      color: white;
      &:before {
        background-color: $sunshine-yellow; }
      .icon {
        stroke: white;
        stroke-width: 0.5px; } }
    .social {
      &.hovered {
        li a {
          color: $warm-grey;
          &:hover {
            color: white; } } }
      li a {
        color: white;
        &:before {
          background-color: $sunshine-yellow; } } }
    .sound {
      &:after {
        color: $warm-grey; }
      .line {
        background: white; }
      &:hover:after {
        color: white; }
      &:before {
        background-color: $sunshine-yellow; } }

    .btn-menu {
      &:before, &:after, .line {
        background: white; } }

    .title-page .title {
      color: white; }

    &.menu-black {
      .btn-menu {
        &:before, &:after, .line {
          background: $black; }
        &:hover {
          &:before, &:after, .line {
            background: $warm-grey; } } }

      .title-page .title {
        color: $black; } }

    &.entered-viewport {
      @media (max-width: $screen-md-max) {
        .header {
          position: fixed;
          background: white;
          height: 64px;
          &:before {
            display: block;
            height: 10px;
            top: 63px; } } }
      .header .lang {
        li {
          &.is-active {
            a {
              color: $black; } }
          a {
            color: #949494;
            &:hover,
            &:focus {
              color: $black; } } } }
      .logo svg .jet {
        fill: $black; }
      .header .email {
        color: $black;
        &:before {
          background-color: $warm-grey; }
        .icon {
          stroke: $black;
          stroke-width: 0px; } }
      .social {
        &.hovered {
          li a {
            color: $warm-grey;
            &:hover {
              color: $black; } } }
        li a {
          color: $black;
          &:before {
            background-color: $warm-grey; } } }
      .btn-menu {
        &:before, &:after, .line {
          background: $black; }
        &:hover {
          &:before, &:after, .line {
            background: $warm-grey; } } }

      .title-page .title {
        color: $black; }

      .sound {
        &:hover:after {
          color: $black; }

        &:before {
          background-color: $warm-grey; }
        .line {
          background: $black; } } } }

  &.team-page {
    background-color: $black;
    .page-wrapper {
      @media (max-width: 768px) {
        min-height: 100vh; } }

    .team-right-menu {
      font-size: 0;
      transform: translateY(-50%);
      @media (max-width: $screen-xs-max) {
        transform: translateY(0);
        top: auto;
        right: auto;
        left: 50%;
        bottom: 25px;
        position: absolute;
        transform: translateX(-50%);
        min-width: 111px; }

      li {
        text-align: center;
        @media (max-width: $screen-xs-max) {
          float: left;
          padding: 5px; }
        &:not(:last-child) {
          margin-bottom: 9px; }
        &:hover {
          cursor: pointer;
          a {
            background-color: $sunshine-yellow; } }

        &.slick-active {
          margin-bottom: 10px;
          @media (max-width: $screen-xs-max) {
            margin-bottom: 0; }
          a {
            width: 34px;
            height: 34px;
            border: 1px solid $sunshine-yellow;
            box-sizing: border-box;
            @media (max-width: $screen-xs-max) {
              background: white;
              border-color: white;
              width: 10px;
              height: 10px;
              border-radius: 100%; }
            img {
              opacity: 1;
              @media (max-width: $screen-xs-max) {
                display: none; } } } }
        a {
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #191919;
          transition: background 0.2s;
          @media (max-width: $screen-xs-max) {
            border-radius: 100%; }

          img {
            opacity: 0;
            -o-object-fit: cover;
            object-fit: cover;
            height: 100%;
            width: 100%;
            -webkit-box-flex: 0;
            -ms-flex-positive: 0;
            flex-grow: 0; } } } }

    .lines {
      position: absolute;
      z-index: 1;
      opacity: 0.1;
      border-color: #f2f2f2; }

    @media (max-width: $screen-md-max) {
      .header {
        position: relative;
        background: none;
        height: auto;
        &:before {
          display: none; } } }

    .logo svg .jet {
      fill: white; }
    .header .el-link-sm {
      &:before {
        background-color: $sunshine-yellow; } }
    .header .email {
      color: white;
      &:before {
        background-color: $sunshine-yellow; }
      .icon {
        stroke: white;
        stroke-width: 0.5px; } }

    .social {
      &.hovered {
        li a {
          color: $warm-grey;
          &:hover {
            color: white; } } }
      li a {
        color: white;
        &:before {
          background-color: $sunshine-yellow; } } }

    .btn-menu {
      &:before, &:after, .line {
        background: white; } }

    .title-page {
      .title {
        color: white; }
      .menu-number {
        color: #181818; } }
    .page-open .btn-menu:hover .menu-number {
      color: #282828; }

    .mouse {
      pointer-events: none;
      z-index: 1;
      position: fixed;
      @media (max-width: $screen-sm-max) {
        display: none; } } } }

.no-scroll {
  overflow: hidden;
  @media (max-width: $screen-xs-max) {
    height: 100%; } }
