.revealerElement {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: 0 50% 0; }

.block-revealer {
  display: inline-block; }

.block-revealer__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0; }

.btn-wrap {
  display: inline-block; }

.js-animate {
  .js-anim-sound,
  .js-anim-copyright,
  .js-anim-social {
    opacity: 0; } }

.js-anim-email,
.js-anim-btn-menu,
.js-anim-r-menu,
.js-anim-h3,
.js-anim-title-section,
.js-anim-logo,
.js-anim-h1,
.js-anim-btn,
.js-anim-modal-btn,
.js-anim-title-modal,
.js-anim-modal-items,
.js-anim-modal-copyr,
.skill-expertise,
.text-expertise,
.line-1,
.line-2,
.line-3,
.line-4,
.rectangle-yellow-wrap,
.js-anim-title,
.js-el-right-menu__list,
.js-anim-text,
.img-wrap,
.line-wrap,
.js-anim-link,
.js-anim-title-services,
.js-anim-text-services,
.js-anim-title-cases,
.js-type,
.js-anim-title-about,
.text-number,
.js-anim-title-case,
.js-anim-text-case,
.img-case,
.js-anim-title-case-detail,
.js-anim-link-case-detail,
.js-anim-text-case-detail,
.js-anim-text-home,
.js-anim-title-clients,
.js-anim-text-clients,
.list-clients li,
.list-clients,
.mouse,
.js-anim-btn-who,
.js-about-title-showreel,
.video-showreel,
.js-about-title-awards,
.js-about-title-medal,
.js-about-text-medal,
.js-about-list-medal,
.js-about-text-awards,
.js-testimonials-slider,
.js-about-title-reviews,
.js-anim-mouse,
.js-anim-h2,
.js-slider-team-photo,
.js-anim-photo-user,
.play-button,
.lang {

  opacity: 0; }

.js-anim-items, .js-number, .js-number-1, .js-number-2, .js-about-list-awards {
  opacity: 0;
  position: relative;
  left: -15px; }



.video-start {
  .js-anim-logo, .js-anim-email, .js-anim-btn-menu, #awwwards, .js-anim-social, .cookie, .js-anim-mouse, .js-anim-r-menu, .js-play-button, .home-item, .home-text-bg {
    transition: opacity 0.3s ease; } }
