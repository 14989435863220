
.phone-slider-wr {
  position: relative;
  width: 100%;
  overflow: hidden; }

.phone-slider-wr .border-ph {
  z-index: 8;
  left: 0;
  right: 0;
  top: 20px;
  bottom: 0;
  margin: 0 auto;
  position: absolute;
  width: 305px;
  height: 635px;
  background: url('../../../../assets/img/content/cases/test/Iphone-Mockup_.png') no-repeat center;
  background-size: contain;
  pointer-events: none;
  @media (max-width: 459px) {
    width: 80% !important; } }


.phone-slider {
  .slick-slide {
    margin: 20px 0;
    cursor: pointer;
    &>div {
      padding: 0 8px 4px;
      box-sizing: border-box; }
    .phone-slide {
      height: 100%;
      border-radius: 25px;
      overflow: hidden;
      transform: scale(0.9, 0.9) translate(0px, 0px);
      box-shadow: 0px 0px 20px #00000021;
      transition: all 0.2s;
      @media (max-width: 459px) {
        transform: scale(0.75, 0.75) translate(0px, 2px); } } }

  .slick-slide.slick-current.slick-active.slick-center {
    margin: 20px 0;
    .phone-slide {
      border-radius: 35px;
      transform: scale(1, 1) translate(0px, 3px);
      @media (max-width: 1300px) {
        border-radius: 30px; }
      @media (max-width: 1100px) {
        border-radius: 20px; }
      @media (max-width: $screen-sm-max) {
        border-radius: 30px; }
      @media (max-width: $screen-xs-max) {
        border-radius: 20px; }
      @media (max-width: 459px) {
        border-radius: 42px;
        transform: scale(0.79, 0.79) translate(0px, 7px); } } }

  .slick-slide img {
    width: 100%; } }

